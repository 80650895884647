import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Field } from "react-final-form";
import Form from "../../../../components/Form";

import { getReportByProduct } from "../../../../redux/reports";

import {
  ServiceReactSelect,
  DateRangePicker,
} from "../../../../components/Form/Inputs";

import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue,
} from "../../../../components/Form/validation";
import { withRouter } from "react-router-dom";
import queryString from "querystring";

const texts = {
  btnText: "Звіт по послузі",
  service: "Послуга",
  date: "Період від - до",
};

const constants = {
  format: "YYYY-MM-DD",
};

class ReportByServiceForm extends Form {
  title = "";
  onchange = null;

  onValidate = makeFormValidator({
    service: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
    date: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
  });

  onSubmit = (props) => {
    const { date, service } = props;
    const { history, getReportByProduct } = this.props;
    let finalData = {
      EntityId: service.value,
    };

    date.forEach((item, index) => {
      if (index === 0) {
        finalData.StartDate = item.local().format(constants.format);
      } else {
        finalData.EndDate = item.local().format(constants.format);
      }
    });
    const newQuery = queryString.stringify(finalData);
    history.push(`/reports/service?${newQuery}`);
    getReportByProduct(finalData, history);
  };

  renderFields = () => {
    return (
      <div className="c-form__item">
        <Field
          className="c-form__field"
          name="service"
          component={ServiceReactSelect}
          label={texts.service}
          selectValueSize={25}
          required
        />
        <Field
          className="c-form__field"
          name="date"
          component={DateRangePicker}
          maxDate={moment().add(1, "days").format()}
          label={texts.date}
          required
        />

        <div className="c-form__submit">
          <button className="c-button" type="submit">
            {texts.btnText}
          </button>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = {
  getReportByProduct,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ReportByServiceForm));
