import jsPDF from "jspdf";
import "jspdf-autotable";
import {RobotoRegular} from "../../helpers/constants";
export const exportPDF = ({
  tableData,
  header,
  documentTitle = "",
  format = "landscape",
  contentBefore = "",
}) => {
    const unit = "px";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = format; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);


    doc.addFileToVFS("Roboto-Regular-normal.ttf", RobotoRegular);
    doc.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal");
    doc.setFont("Roboto-Regular");
    const title = documentTitle;
    const headers = header;
    const data = tableData;
    const calculateMargin = () => {
        const query = window.location.search.slice(1).split("&").length;
        if (query === 1) return 110;
        if (query === 2) return 120;
        if (query === 3) return 130;
        if (query === 4) return 140;
        if (query === 5) return 150;
        if (query === 6) return 160;
        if (query === 7) return 170;
        if (query === 8) return 180;
        if (query > 8) return 190;
        return 100;
    };

    let content = {
        head: headers,
        body: data,
        styles: {
            font: "Roboto-Regular",
            fontStyle: "normal",
            cellWidth: 'wrap'
        },
        columnStyles: {
            0: {
                cellWidth: 50,
            },
            1:{
                cellWidth: 50,
            },
            2: {
                cellWidth: 50,
            },
            3: {
                cellPadding: 20,
                pageBreak: 'always',
                margin: { top: 80 },
            },
            4:{
                cellPadding: 20,
                columnWidth: 'auto'
            },
            5:{
                cellPadding: 20,
                columnWidth: 'auto'
            },

            6:{
                cellPadding: 20,
                columnWidth: 'auto'
            },
            cellWidth: "auto",
            columnWidth: "auto",
        },

    };
    doc.setTextColor("#262626");
    doc.setFontSize(12);
    doc.setLineHeightFactor(1.2);
    doc.text(10, 10, `${title}${contentBefore}`,);
    doc.autoTable(content);
    doc.save("report.pdf");
};
