const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'date',
      Header: 'Дата',
      minWidth: 200,
      filterable: false,
      sortable: false,
      formatter: 'date'
    },
    {
      accessor: 'employeeFullName',
      Header: 'Робітник',
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'amount',
      Header: 'Кількість',
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'cost',
      Header: 'Сума',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'price',
      Header: 'Ціна',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'clientFullName',
      Header: 'Клієнт',
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
  ],
};

export default columns;