import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import Form from '../../../components/Form';
import {addPayDesk, editPayDesk, resetPayDesk} from '../../../redux/paydesks';
import { TextField } from '../../../components/Form/Inputs';
import {
    composeValidators,
    makeFormValidator,
    validationTexts,
} from "../../../components/Form/validation";
import { withRouter } from 'react-router-dom';
import AllTerminalSelect from "../../../components/GeneralFields/Select/AllTerminalSelect";
import {ClearIcon} from "../../../icons";

const texts = {
  name: 'Назва ФОП',
  fiscalNumber: 'Фіскальний номер',
  terminalId: 'ID термінала',
  terminalName: 'Імя термінала',
  registrarFiscalNumber: 'Фіскальний номер пРРО',
  btnText: "Створити",
  editText: "Зберегти"
}

class PayDeskForm extends Form {
    state = {
        shopId: '',
        terminalId: '',
        terminalName: ''
    }
  title = this.props.values.id ? "Редагувати ФОП" : "Додати новий ФОП"

    componentDidMount() {
        this.setState({
            shopId: this.props.values.shopId,
            terminalId: this.props.values.terminalId,
            terminalName: this.props.values.terminalName
        })
    }

    onValidate = makeFormValidator({
    name: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: value => value ? value.length < 3 : false,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
});

    clearState = (e) => {
        e.preventDefault();
        this.props.resetPayDesk(this.props.values.id, this.props.values)
    }

  onSubmit = props =>  {
      const { name, fiscalNumber, registrarFiscalNumber} = props;
      const { terminalName } = props;
      const { terminalName: termName,terminalId: termId, shopId: shpID } = terminalName || {}


      const createPaydeskData = {
          name,
          fiscalNumber,
          registrarFiscalNumber,
          shopId:shpID ? shpID : 0,
          terminalId:termId ? termId : 0,
          terminalName:termName ? termName : '',
      }


      this.props.values.id ?
          this.props.editPayDesk(this.props.values.id, createPaydeskData, this.props.pageNumber)
          : this.props.addPayDesk(createPaydeskData, this.props.pageNumber)

  }

  renderFields = () => {
    const { id, } = this.props.values;
    return (
      <div className="c-form__item">
        <Field
          className="c-form__field"
          name="name"
          component={TextField}
          label={texts.name}
          required
        />
        <Field
            className="c-form__field"
            name="terminalName"
            component={AllTerminalSelect}
            label={texts.terminalId}
        />
          {this.props.values.shopId === '' ?
              false
          : <button className="clearIconPaydesk" onClick={(e) => this.clearState(e)}>  <ClearIcon /> </button>}
          <Field
              className="c-form__field"
              name="fiscalNumber"
              component={TextField}
              label={texts.fiscalNumber}
          />
          <Field
              className="c-form__field"
              name="registrarFiscalNumber"
              component={TextField}
              label={texts.registrarFiscalNumber}
          />
        <div className="c-form__submit">
          <button className="c-button" type="submit">{id ? texts.editText : texts.btnText}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  values: state.paydesks.initialValues,
  pageNumber: state.pagination.pageNumber,
  notification: state.notification,
});

const mapDispatchToProps = {
  addPayDesk,
  editPayDesk,
  resetPayDesk,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PayDeskForm));
