import { takeEvery, put, call } from 'redux-saga/effects';


import { SET_NOTIFICATION } from '../notification';
import { CLEAR_PAGE_COUNT, SET_PAGE_COUNT } from '../pagination';
import { fetchReportByProduct, fetchReportByWorker, fetchConsolidateReport, fetchDownloadPdf, fetchDownloadConsolidatePdf, fetchReportByClient } from '../../http/reports';

import { saveAs } from "file-saver";

const REPORT_LOADING = 'report/REPORT_LOADING';
const REPORT_LOAD_FAILED = 'report/REPORT_LOAD_FAILED';
const REPORT_WORKER_LOAD = 'report/REPORT_WORKER_LOAD';
const REPORT_CONSOLIDATE_LOAD = 'report/REPORT_CONSOLIDATE_LOAD';
const REPORT_MATERIAL_LOAD_SUCCESS = 'report/REPORT_MATERIAL_LOAD_SUCCESS';
const REPORT_WORKER_LOAD_SUCCESS = 'report/REPORT_WORKER_LOAD_SUCCESS';
const CONSOLIDATE_REPORT_LOAD_SUCCESS = 'report/CONSOLIDATE_REPORT_LOAD_SUCCESS';
const REPORT_DOWNLOAD_PDF_BY_ENTITY = 'report/REPORT_DOWNLOAD_PDF_BY_ENTITY';
const REPORT_DOWNLOAD_PDF_CONSOLIDATE = 'report/REPORT_DOWNLOAD_PDF_CONSOLIDATE';
const REPORT_SET_CHOSE_MATERIAL_DATA = 'report/REPORT_SET_CHOSE_DATA';
const REPORT_SET_CHOSE_EMPLOYEE_DATA = 'report/REPORT_SET_CHOSE_EMPLOYEE_DATA';
const REPORT_SET_CHOSE_CONSOLIDATE_DATA = 'report/REPORT_SET_CHOSE_CONSOLIDATE_DATA';
const REPORT_PRODUCT_LOAD = 'report/REPORT_PRODUCT_LOAD';
const REPORT_SERVICE_LOAD_SUCCESS = 'report/REPORT_SERVICE_LOAD_SUCCESS';
const REPORT_SET_CHOSE_SERVICE_DATA = 'report/REPORT_SET_CHOSE_SERVICE_DATA';
const REPORT_CLIENT_LOAD = 'report/REPORT_CLIENT_LOAD';
const REPORT_CLIENT_LOAD_SUCCESS = 'report/REPORT_CLIENT_LOAD_SUCCESS';
const REPORT_SET_CHOSE_CLIENT_DATA = 'report/REPORT_SET_CHOSE_CLIENT_DATA';

const texts = {
  errorDataLength: 'За вказаними параметрами не знайдено жодної інформації'
}

const initialState = {
  materialData: [],
  workerData: [],
  serviceData: [],
  clientData: [],
  consolidateData: [],
  workerShowData: [],
  clientShowData: [],
  detailsEmployee: {
    costOfMaterial: null,
    costOfService: null,
    commonCost: null
  },
  detailsConsolidateReport: "",
  detailsMaterial: {},
  detailsService: {},
  detailsClient: {},

  choseConsolidateData: [],
  choseServiceData: [],
  choseMaterialData: [{
    EntityId: null
  }],
  choseEmployeeData: [],
  choseClientData: [],

  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REPORT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case REPORT_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case REPORT_MATERIAL_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        materialData: action.data.items,
        detailsMaterial: {
          totalAmount: action.data.totalAmount,
          totalCost: action.data.totalCost,
          totalPrice: action.data.totalPrice,
        }
      };
    }
    case REPORT_SERVICE_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        serviceData: action.data.items,
        detailsService: {
          totalAmount: action.data.totalAmount,
          totalCost: action.data.totalCost,
          totalPrice: action.data.totalPrice,
        }
      };
    }
    case REPORT_WORKER_LOAD_SUCCESS: {
      const currentData = action.data.items
      const start = (action.pageNumber - 1) * 10
      return {
        ...state,
        loading: false,
        workerData: action.data.items,
        workerShowData: currentData.splice(start, 10),
        detailsEmployee: {
          costOfMaterial: action.data.totalCostOfMaterials,
          costOfService: action.data.totalCostOfServices,
          commonCost: Number.parseFloat(Number(action.data.totalCostOfMaterials) + Number(action.data.totalCostOfServices)).toFixed(2)
        }
      };
    }
    case REPORT_CLIENT_LOAD_SUCCESS: {
      const currentData = action.data.items;
      const start = (action.pageNumber - 1) * 10;
      return {
        ...state,
        loading: false,
        clientData: currentData,
        clientShowData: currentData.splice(start, 10),
        detailsClient: {
          totalCostOfMaterials: action.data.totalCostOfMaterials,
          totalCostOfServices: action.data.totalCostOfServices,
          commonCost: Number.parseFloat(Number(action.data.totalCostOfMaterials) + Number(action.data.totalCostOfServices)).toFixed(2)
        }
      };
    }
    case CONSOLIDATE_REPORT_LOAD_SUCCESS: {
      const start = (action.pageNumber - 1) * 10
      return {
        ...state,
        loading: false,
        consolidateData: action.data.items.splice(start, 10),
        detailsConsolidateReport: {
          costOfMaterial: action.data.totalCostOfMaterials,
          costOfService: action.data.totalCostOfServices,
          commonCost: Number.parseFloat(Number(action.data.totalCostOfMaterials) + Number(action.data.totalCostOfServices)).toFixed(2)
        }
      };
    }
    case REPORT_SET_CHOSE_MATERIAL_DATA: {
      return {
        ...state,
        loading: false,
        choseMaterialData: action.data,
      };
    }
    case REPORT_SET_CHOSE_SERVICE_DATA: {
      return {
        ...state,
        loading: false,
        choseServiceData: action.data,
      };
    }
    case REPORT_SET_CHOSE_EMPLOYEE_DATA: {
      return {
        ...state,
        loading: false,
        choseEmployeeData: action.data,
      };
    }
    case REPORT_SET_CHOSE_CLIENT_DATA: {
      return {
        ...state,
        loading: false,
        choseClientData: action.data,
      };
    }
    case REPORT_SET_CHOSE_CONSOLIDATE_DATA: {
      return {
        ...state,
        loading: false,
        choseConsolidateData: action.data,
      };
    }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getReportByProduct = (data, history) => ({ type: REPORT_PRODUCT_LOAD, data, history });
export const getReportByWorker = (data, history) => ({ type: REPORT_WORKER_LOAD, data, history });
export const getReportByClient = (data, history) => ({ type: REPORT_CLIENT_LOAD, data, history });
export const getConsolidatedReport = (data, history) => ({ type: REPORT_CONSOLIDATE_LOAD, data, history });
export const downloadReportPdf = data => ({type: REPORT_DOWNLOAD_PDF_BY_ENTITY, data});
export const downloadConsolidateReportPdf = data => ({type: REPORT_DOWNLOAD_PDF_CONSOLIDATE, data})

//<<<WORKERS>>>
function* getDataProduct({data, history}) {
  yield put({ type: REPORT_LOADING })
  try {
    const response = yield call(fetchReportByProduct, data);
    if (response.status === 200) {
      // delete data.pageNumber;
      // const newQuery = queryString.stringify(data);
      if(data.isMaterial) {
        if(!response.data.items.length){
          yield put({ type: SET_NOTIFICATION, notification: { text: texts.errorDataLength, error: true } });
          yield put({ type: REPORT_MATERIAL_LOAD_SUCCESS, data: { items: []} });
          yield put({ type: REPORT_SET_CHOSE_MATERIAL_DATA, data });
        } else {
          // history.push(`/reports/material?${newQuery}`);
          yield put({ type: REPORT_MATERIAL_LOAD_SUCCESS, data: response.data });
          yield put({ type: REPORT_SET_CHOSE_MATERIAL_DATA, data });
        }
      } else {
        if(!response.data.items.length){
          yield put({ type: SET_NOTIFICATION, notification: { text: texts.errorDataLength, error: true } });
          yield put({ type: REPORT_SERVICE_LOAD_SUCCESS, data: { items: []} });
          yield put({ type: REPORT_SET_CHOSE_SERVICE_DATA, data });
        } else {
          // history.push(`/reports/service?${newQuery}`);
          yield put({ type: REPORT_SERVICE_LOAD_SUCCESS, data: response.data });
          yield put({ type: REPORT_SET_CHOSE_SERVICE_DATA, data });
        }
      }
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } });
    }
    if (response.status >= 400) {
      yield put({ type: REPORT_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: REPORT_LOAD_FAILED })
    console.log(error)
  }
};

function* getDataWorker({data, history}) {
  yield put({ type: REPORT_LOADING })
  try {
    const response = yield call(fetchReportByWorker, data);
    if (response.status === 200) {
      if(!response.data.items.length){
        yield put({ type: SET_NOTIFICATION, notification: { text: texts.errorDataLength, error: true } });
        yield put({ type: REPORT_WORKER_LOAD_SUCCESS, data: { items: [], totalCostOfMaterials: 0, totalCostOfServices: 0 },});
        yield put({ type: REPORT_SET_CHOSE_EMPLOYEE_DATA, data });
        yield put({type: CLEAR_PAGE_COUNT})
      } else {
        const pageNumber = data.pageNumber
        // delete data.pageNumber;
        // const newQuery = queryString.stringify(data);
        // history.push(`/reports/worker?${newQuery}`);
        yield put({ type: SET_PAGE_COUNT, count: response.data.items.length});
        yield put({ type: REPORT_WORKER_LOAD_SUCCESS, data: response.data, pageNumber });
        yield put({ type: REPORT_SET_CHOSE_EMPLOYEE_DATA, data });
      }
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } });
    }
    if (response.status >= 400) {
      yield put({ type: REPORT_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: REPORT_LOAD_FAILED });
    console.log(error)
  }
};

function* getDataClient({data, history}) {
  yield put({ type: REPORT_LOADING })
  try {
    const response = yield call(fetchReportByClient, data);
    if (response.status === 200) {
      const pageNumber = data.pageNumber
      // delete data.pageNumber;
      if(!response.data.items.length){
        yield put({ type: SET_NOTIFICATION, notification: { text: texts.errorDataLength, error: true } });
        yield put({ type: REPORT_CLIENT_LOAD_SUCCESS, data: {items: []}, pageNumber});
        yield put({ type: REPORT_SET_CHOSE_CLIENT_DATA, data });
        yield put({type: CLEAR_PAGE_COUNT})
      } else {
        // const newQuery = queryString.stringify(data);
        // history.push(`/reports/client?${newQuery}`);
        yield put({ type: SET_PAGE_COUNT, count: response.data.items.length});
        yield put({ type: REPORT_CLIENT_LOAD_SUCCESS, data: response.data, pageNumber});
        yield put({ type: REPORT_SET_CHOSE_CLIENT_DATA, data });
      }
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } });
    }
    if (response.status >= 400) {
      yield put({ type: REPORT_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: REPORT_LOAD_FAILED });
    console.log(error)
  }
};

function* getDataConsolidated({data}) {
  yield put({ type: REPORT_LOADING })
  try {
    const response = yield call(fetchConsolidateReport, data);
    if (response.status === 200) {
      if(!response.data.items.length){
        yield put({ type: SET_NOTIFICATION, notification: { text: texts.errorDataLength, error: true } });
        yield put({ type: CONSOLIDATE_REPORT_LOAD_SUCCESS, data: {items: []},});
        yield put({ type: REPORT_SET_CHOSE_CONSOLIDATE_DATA, data})
        yield put({type: CLEAR_PAGE_COUNT})
      } else {
        const pageNumber = data.pageNumber
        // delete data.pageNumber;
        // const newQuery = queryString.stringify(data)
        // history.push(`/reports/consolidated?${newQuery}`)
        yield put({ type: SET_PAGE_COUNT, count: response.data.items.length });
        yield put({ type: CONSOLIDATE_REPORT_LOAD_SUCCESS, data: response.data, pageNumber});
        yield put({ type: REPORT_SET_CHOSE_CONSOLIDATE_DATA, data})
      }
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: REPORT_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: REPORT_LOAD_FAILED })
    console.log(error)
  }
};

function* downloadPDF({data}) {
  yield put({ type: REPORT_LOADING })
  try {
    const response = yield call(fetchDownloadPdf, data);
    setTimeout(() => {
      handleExport(response)
    }, 3000)
    if (response.status === 200) {
      response.data && setTimeout(() => {
        handleExport(response)
      }, 3000)
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: REPORT_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: REPORT_LOAD_FAILED })
    console.log(error)
  }
};

function* downloadConsolidatePDF({data}) {
  yield put({ type: REPORT_LOADING })
  try {
    const response = yield call(fetchDownloadConsolidatePdf, data);
    response.data && setTimeout(() => {
      handleExport(response)
    }, 3000)
    if (response.status === 200) {
      response.data && setTimeout(() => {
        handleExport(response)
      }, 3000)
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: REPORT_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: REPORT_LOAD_FAILED })
    console.log(error)
  }
};

const fixBinary = (bin) =>  {
  const length = bin.length;
  const buf = new ArrayBuffer(length);
  const arr = new Uint8Array(buf);
  for (let i = 0; i < length; i++) {
    arr[i] = bin.charCodeAt(i);
  }
  return buf;
}

const handleExport = (file) => {
  const binary = fixBinary(file);
  const blob = new Blob([binary]);
  saveAs(blob, "report");
};

//<<<WATCHERS>>>
export function* watchReports() {
  yield takeEvery(REPORT_PRODUCT_LOAD, getDataProduct);
  yield takeEvery(REPORT_WORKER_LOAD, getDataWorker);
  yield takeEvery(REPORT_CLIENT_LOAD, getDataClient);
  yield takeEvery(REPORT_CONSOLIDATE_LOAD, getDataConsolidated);
  yield takeEvery(REPORT_DOWNLOAD_PDF_BY_ENTITY, downloadPDF);
  yield takeEvery(REPORT_DOWNLOAD_PDF_CONSOLIDATE, downloadConsolidatePDF);
}
