import React from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import SidebarList from './List';

const Sidebar = ({ role, list }) => {
  return (
    <div className="sidebar">
      <div className="sidebar__inner">
        <SidebarList list={list} role={role} />
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  role: state.login.user.role
})

export default connect(mapStateToProps)(Sidebar);