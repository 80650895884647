import React from "react";

const texts = {
  goodStatus: "оплачено",
  wrongStatus: "боржник",
};

export const clientPaymentFormatter = (value) => (
  <div className="discount-formatter">
    <span className={value ? "normal" : "wrong"}>
      {value ? texts.goodStatus : texts.wrongStatus}
    </span>
  </div>
);
