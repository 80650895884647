import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import toNumber from "lodash/toNumber";
import findKey from "lodash/findKey";
import isDate from "lodash/isDate";
import mapValues from "lodash/mapValues";
import map from "lodash/map";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import each from "lodash/each";
import * as EmailValidator from 'email-validator';
import validator from 'validator' 

export const validationTexts = {
  required: "Обов'язкове поле",
  minLength: value => `Мінімальна кількість символів - ${value}`,
  maxLength: value => `Максимальна кількість символів - ${value}`,
  minValue: value => `Мінімальна значення - ${value}`,
  notEmail: () => 'Не вірний формат',
  notPhone: () => 'Некоректно введений номер телефону',
  match: "Паролі не співпадають",
  wrongFormat: "Неправильний формат"
}

export { isDate };
export const isNotDate = value => !value.isValid()

export { isEmpty };
export const isNotEmpty = value => !isEmpty(value);

export const isEmptyValue = value => {
  let checkValue = value;
  if (Number.isInteger(value)) {
    checkValue = value.toString();
  } else {
    if (Number(value) !== 0 && !Number.isNaN(Number(value))) {
      checkValue = value.toString();
    }
  }
  return isEmpty(checkValue);
}

export const isNotPhoneNumber = value => {
  const array = value.split("");
  const filter = array.filter(item => item !== '-')
 return !validator.isMobilePhone(filter.join(""))
}

export const isNullValue = value => value === null;
export const isEmail = value => EmailValidator.validate(value);

export const isNotEmail = value => !isEmail(value);

export const sizeIsLessThan = minValue => value => {
  return size(value) < minValue;
};

export const sizeIsMoreThan = maxValue => value => {
  return size(value) > maxValue;
};

export const numberIsLessThan = minValue => value => {
  return toNumber(value) < minValue;
};

export const numberIsMoreThan = maxValue => value => {
  return toNumber(value) > maxValue;
};

export const isInteger = value => Number.isInteger(Number(value));
export const isNotInteger = value => !isInteger(value);

export const isNumber = value => !Number.isNaN(Number.parseFloat(value));
export const isNotNumber = value => Number.isNaN(Number.parseFloat(value));

export const isOneOf = enumeration => value => {
  return enumeration.indexOf(value) > -1;
};
export const isNotOneOf = enumeration => value => {
  return enumeration.indexOf(value) === -1;
};

export const isMatch = field => (value, data) => {
  return data[field] === value;
};

export const isNotMatch = ({ value, data, field }) => data[field] !== value;

export const isRegExpMatch = regExp => value => {
  return value && regExp.test(value);
};

export const isRegExpNotMatch = regExp => value => {
  return value && !regExp.test(value);
};

export const composeValidators = rules => {
  return (value, data) => {
    return findKey(rules, rule => {
      return rule(value, data);
    });
  };
};

export const makeFormValidator = rules => {
  return values => {
    const keys = mapValues(rules, (rule, key) => {
      const value = values ? values[key] : undefined;
      return rule(value, values);
    });
    const errors = omitBy(keys, isUndefined);
    return isEmpty(errors) ? undefined : errors;
  };
};

export const makeArrayValidator = rules => {
  const itemValidator = makeFormValidator(rules);
  return values => {
    const errors = [];

    each(map(values, itemValidator), (item, key) => {
      if (!isEmpty(item)) {
        errors[key] = item;
      }
    });

    return isEmpty(errors) ? undefined : errors;
  };
};