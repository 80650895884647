import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";
import Form from "../../../components/Form";

import {
  getDataByIdForCollection,
  createNewCollection,
} from "../../../redux/collections";
import {
  NumberMaskField,
  CashierReactSelect,
} from "../../../components/Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue,
} from "../../../components/Form/validation";
import { withRouter } from "react-router-dom";

const texts = {
  sum: "Вкажіть суму інкасації",
  responsible: "Виберіть відповідального за видачу",
  btnText: "Зберегти",
};

class CollectionForm extends Form {
  title = "";
  onchange = null;

  componentDidMount = () =>
    this.props.currentId &&
    this.props.getDataByIdForCollection(this.props.currentId);

  onValidate = makeFormValidator({
    sum: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: (value) => (value ? value <= 0 : false),
    }),
    cashierId: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
  });

  onSubmit = (props) => {
    const { currentData, createNewCollection, currentUser, pageNumber } =
      this.props;
    const finalData = {
      ...props,
      cashierId: props.cashierId.value,
      paydeskId: currentData.paydesk.id,
      recipientId: currentUser,
    };
    createNewCollection(finalData, pageNumber);
  };

  renderFields = () => {
    return (
      <div className="c-form__item">
        <Field
          className="c-form__field"
          name="sum"
          component={NumberMaskField}
          label={texts.sum}
          required
        />
        <Field
          className="c-form__field"
          name="cashierId"
          component={CashierReactSelect}
          selectValueSize={20}
          label={texts.responsible}
        />
        <div className="c-form__submit">
          <button className="c-button" type="submit">
            {texts.btnText}
          </button>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  currentId: state.collections.currentId,
  currentData: state.collections.currentPaydesk,
  currentUser: state.login.user.id,
  pageNumber: state.pagination.pageNumber,
  notification: state.notification,
});

const mapDispatchToProps = {
  getDataByIdForCollection,
  createNewCollection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CollectionForm));
