import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";

import Form from "../Form";
import { getPaymentMethods, replenishBalance } from "../../redux/clients";
import { TextField, EntrepreneurReactSelect, RadioField, NumberMaskField } from "../Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue,
} from "../Form/validation";

const paymentMethodsType = {
  cash: 1,
  cashless: 2,
  balance: 3,
  recalculation: 4
}

const texts = {
  amount: "Сума",
  btnText: "Зберегти",
  title: "Додати кошти до балансу",
  received: "Отримано",
  FOP: "ФОП",
  replenishBalance: "Виберіть спосіб оплати",
  conversionNumber: "Номер рахунку",
};

class ReplenishBalance extends Form {
  title = texts.title;

  state = {
    value: null,
    currentType: null
  };

  onChange = (e) => {
    const currentPaymentMethod = this.props.data.paymentMethods.find(
      (item) => item.id === e.target.value
    );
    this.setState({
      value: e.target.value,
      currentType: currentPaymentMethod.paymentMethodType,
    });
  };

  componentDidMount = () => this.props.getPaymentMethods();

  componentDidUpdate = (prevProps) => {
    if(prevProps.data.paymentMethods.length !== this.props.data.paymentMethods.length ) {
      const {id, paymentMethodType} = this.props.data.paymentMethods[0]
      this.setState(prevState => {
        return {
          ...prevState,
          value:  id,
          currentType: paymentMethodType
        }
      })
    } else if (prevProps.data.paymentMethods && prevProps.data.paymentMethods.length && !this.state.value) {
      const {id, paymentMethodType} = prevProps.data.paymentMethods[0]
      this.setState(prevState => {
        return {
          ...prevState,
          value:  id,
          currentType: paymentMethodType
        }
      })
    }
  }

  onValidate = makeFormValidator({
    amount: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue()]: (value) => (value ? value < 1 : false),
    }),
    paydeskId: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
    transferNumber: composeValidators({
      [validationTexts.required]: (value) => {
        if(this.state.currentType === paymentMethodsType.recalculation) {
          return value === undefined || value.toString().trim().length === 0
        } else {
          return false
        }
      }
    }),
  });

  onSubmit = (props) => {
    const { currentId, data: { paydesks, paymentMethods }, replenishBalance, currentCashier, pageNumber, queriesData } = this.props;
    const { value, currentType } = this.state

    const finalData = {
      ...props,
      paydeskId: paydesks.find((item) => item.id === props.paydeskId.value).id,
      paymentMethodId: paymentMethods.find(item => item.id === value).id,
      employeeId: currentCashier,
      clientId: currentId,
      transferNumber: currentType === paymentMethodsType.recalculation ? props.transferNumber : null
    };
    replenishBalance(finalData, pageNumber, queriesData);
  };

  renderFields = () => (
    <>
      <div className="c-form__item">
        <div className="c-form__item-group">
          <Field
            className="c-form__field"
            name="amount"
            component={NumberMaskField}
            label={texts.amount}
            required
          />
          <Field
            className="c-form__field"
            name="paydeskId"
            component={EntrepreneurReactSelect}
            label={texts.FOP}
            required
          />
        </div>
        <Field
          className="c-form__field"
          name="paymentMethod"
          options={this.props.data.paymentMethods.length ? this.props.data.paymentMethods.filter( item => item.paymentMethodType !== paymentMethodsType.balance) : []}
          component={RadioField}
          onChange={this.onChange}
          value={this.state.value}
          label={texts.replenishBalance}
          type="radio"
          required
        />
        {this.state.currentType === paymentMethodsType.recalculation && (
          <Field
            className="c-form__field"
            name="transferNumber"
            component={TextField}
            label={texts.conversionNumber}
            required
            spanClassName="transfer-number"
          />
        )}
        <div className="c-form__submit">
          <button className="c-button" type="submit">
            {texts.btnText}
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  data: state.clients.methodsData,
  currentId: state.clients.currentId,
  currentCashier: state.login.user.id,
  pageNumber: state.pagination.pageNumber,
  queriesData: state.clients.queriesData,
  notification: state.notification
});

const mapDispatchToProps = {
  getPaymentMethods,
  replenishBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplenishBalance);
