import React from 'react';
import BlockIcon from '../../assets/images/icons/block.png';
import { roleNames } from '../../roles';

export const deleteComingFormatter = (id, value, blockItem, pageNumber, queriesData, userRole) => (
  !value && userRole !== roleNames.casier && 
    <div className="action-formatter" onClick={() => blockItem(id, pageNumber, queriesData)}><img src={BlockIcon} alt="block"/></div>
)
   

