import { connect } from "react-redux";
import { generateSelectValue } from "../../../../helpers";
import { getParameter} from "../../../../redux/materials";
import ReactSelect from "../../../Form/Inputs/ReactSelect";


class AllParameterSelectForServices extends ReactSelect {
    label = "";
    name = "parameter"
    maxHeight = 250;


    componentDidMount = () =>  {
        !this.props.data?.length && this.props.getParameter();
    }

    getInitialValue = () => {
        const currentOption =
            this.props.data &&
            this.props.data.find((item) => {
                return item.code === this.props.initialValues.unitTypeId ? item.name : null
            });
        const result = currentOption
            ? {
                id: currentOption.code,
                uniqueId: currentOption.code,
                label: generateSelectValue({
                    value: currentOption.name,
                }),
            }
            : '';
        return result
    };

    renderOptions = () => {
        if(!this.props.data) return []
        return this.props?.data?.map(({id,code, name}) => ({
            value: name,
            unitTypeId: code,
            label: generateSelectValue({
                value: name,
                size: this.props.selectValueSize,
            }),
        }));
    }
}

const mapStateToProps = (state) => ({
    data: state.materials.parametersData,
    initialValues: state.services.initialValues,
});

const mapDispatchToProps = {
    getParameter
};

export default connect(mapStateToProps, mapDispatchToProps)(AllParameterSelectForServices);
