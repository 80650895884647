import SelectReact from '..';
import { connect } from 'react-redux';
import { getAllCashiers } from '../../../../redux/employee';
import { generateSelectValue } from '../../../../helpers';

class CashierSelectForOrder extends SelectReact {

  state={}
  label = ''
  name = this.props.name ? this.props.name : 'cashierId'
  maxHeight = 150

  componentDidMount = () => !this.props.data.length && this.props.getAllCashiers()

  handleChange = data => this.props.onChange(data)

  getInitialValue = value =>  {
    const currentOption = this.props.data.find(item => item.id === this.props.value)
    return currentOption ? {value: currentOption.value, label: generateSelectValue({value: currentOption.fullName}) } : null
  }

  renderOptions = () => this.props.data.map(({ id, fullName }) => ({ value: id, label: generateSelectValue({ value: fullName })  }))
}

const mapStateToProps = state => ({
  data: state.employees.allCashiers
})

const mapDispatchToProps = {
  getAllCashiers
}

export default  connect(mapStateToProps, mapDispatchToProps)(CashierSelectForOrder) ;