export const roleNames = {
  superAdmin: 'СУПЕР-АДМІН',
  accountant: 'БУХГАЛТЕР',
  casier: 'КАСИР',
}

// export function getRole(roles) {
//   if (roles === null) {
//     return '';
//   }
//   if (roles.indexOf('GLOBAL-ADMIN') !== -1) {
//     return roleNames.superAdmin;
//   }
//   if (roles.indexOf('ADMINISTRATOR') !== -1) {
//     return roleNames.admin;
//   }
//   if (roles.indexOf('CARRIER') !== -1) {
//     return roleNames.carrier;
//   }
//   if (roles.indexOf('OPERATOR') !== -1) {
//     return roleNames.operator;
//   }
//   return roleNames.admin;
// };