import SelectReact from "..";
import { connect } from "react-redux";
import { getClients } from "../../../../../redux/clients";
import { generateSelectValue } from "../../../../../helpers";

class ClientReactSelect extends SelectReact {
  label = "";
  name = "employeeId";
  maxHeight = 150;
  spanClassName = "employee-select";

  componentDidMount = () => !this.props.data.length && this.props.getClients();

  getInitialValue = (value) => {
    const currentOption = this.props.data.find((item) => item.id === value);
    return currentOption
      ? {
          value: currentOption.value,
          label: generateSelectValue({
            value: currentOption.fullName,
            size: this.props.selectValueSize,
          }),
        }
      : null;
  };

  renderOptions = () =>
    this.props.data.map(({ id, fullName }) => ({
      value: id,
      label: generateSelectValue({
        value: fullName,
        size: this.props.selectValueSize,
      }),
    }));
}

const mapStateToProps = (state) => ({
  data: state.clients.allData,
});

const mapDispatchToProps = {
  getClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientReactSelect);
