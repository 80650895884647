const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'orderNumber',
      Header: 'ID',
      minWidth: 80,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'paydeskName',
      Header: 'Назва ФОП',
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'operationDate',
      Header: 'Дата оплати',
      minWidth: 120,
      sortable: false,
      filterable: false,
      formatter: 'date',
    },
    {
      accessor: 'cashierName',
      Header: 'Касир',
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'clientName',
      Header: 'Клієнт',
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'paymentMethodName',
      Header: 'Спосіб оплати',
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'transaction',
      Header: 'Транзакція',
      minWidth: 100,
      filterable: false,
      sortable: false,
      formatter: 'transactionType'
    },
    {
      accessor: 'sum',
      Header: 'Оплачено',
      minWidth: 50,
      filterable: false,
      sortable: false,
      formatter: 'currency'
    },
    {
      accessor: 'transferNumber',
      Header: 'Рахунок',
      minWidth: 250,
      filterable: false,
      sortable: false,
    },
  ],
};

export default columns;