import React, { Component } from 'react';
import * as jwt_decode from 'jwt-decode';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBrowserHistory } from "history";

import AppRouter from './router';
import { setUserData } from './redux/login';
import { initialServiceValues, initialMaterialValues  } from './redux/createOrder';
import Authenticate from './components/Authenticate';
import OidcSettings from './components/Authenticate/oidcsettings';
import { getClaim } from './helpers/jwt';
import { setItem, deleteItem } from './helpers/storage';
import { roleNames } from './roles';

const customHistory = createBrowserHistory();

class App extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.userName !== this.props.userName && this.props.userName === "") {
      this.userLogout()
    }
  }

  userLoaded = user => {
    if (user) {
      const { access_token } = user;
      const { initialServiceValues, setUserData, initialMaterialValues } = this.props;
      const decoded = jwt_decode(access_token);
      const name = getClaim(decoded, 'name');
      const role = getClaim(decoded, 'role');
      setUserData({ role, name, id: decoded.sub });
      role !== roleNames.superAdmin &&  initialServiceValues({ employeeId: decoded.sub });
      role !== roleNames.superAdmin && initialMaterialValues({ employeeId: decoded.sub });
      setItem('token', user.access_token);
    };
  };

  userUnLoaded() {
   deleteItem('token');
   deleteItem('user');
  };

  userLogout = () => {
    if (this._auth !== null) {
      this._auth.signout();
    }
  };

  render() {
    const { role } = this.props;
    return (
      <Authenticate
        OidcSettings={OidcSettings}
        userLoaded={this.userLoaded}
        userUnLoaded={this.userUnLoaded}
        ref={ref => this._auth = ref}
      >
        <div className="main-wrapper">
          <Router history={customHistory}>
            <AppRouter role={role} logout={this.userLogout} />
          </Router>
        </div>
      </Authenticate>
    );
  }
}

const mapStateToProps = state => ({
  role: state.login.user.role,
  userName: state.login.user.name,
});

const mapDispatchToProps = {
  setUserData,
  initialServiceValues,
  initialMaterialValues
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

