import { useEffect, useRef } from "react";
// import { notification as antdNotification } from "antd";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { clearNotification } from "../../redux/notification";

const texts = {
  deafultMasseage: "Щось пішло не так",
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function notificationSatetHandler(type, message, callback) {
  setTimeout(() => {
    callback();
  }, 6000);
}

const Notification = (props) => {
  const {
    notification,
    clearNotification,
    notification: { text },
  } = props;
  const prevNotification = usePrevious(notification);
  const message = text === null ? texts.deafultMasseage : text.messages || text;
  useEffect(() => {
    if (!isEqual(prevNotification, notification) && message.length > 0) {
      const type = notification.error ? "error" : "success";
      notificationSatetHandler(type, message, clearNotification);
    }
  });
  return null;
};

const mapStateToProps = ({ notification }) => ({
  notification,
});

const mapDispatchToProps = {
  clearNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
