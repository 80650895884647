import columns from '../../columns';
import GeneralPage from '../../../GeneralPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPageNumber } from '../../../../redux/pagination';
import { getAllPaidHistory } from '../../../../redux/clients'

class ClientOrderDetails extends GeneralPage {

  columns = columns.orderDetails

  componentDidMount = () => {
    const {currentOrderId, getData} = this.props
    getData(currentOrderId)
  }
  componentWillUnmount = () => false
}

const mapStateToProps = (state) => ({
  loading: state.clients.loading,
  data: state.clients.ordersDetails,
  orderData: state.createOrder.orderData,
  currentOrderId: state.clients.currentId.id,
});


const mapDispatchToProps = {
  getData: getAllPaidHistory,
  setPageNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientOrderDetails))
