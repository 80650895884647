import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import Form from '../../../../components/Form';
import { getConsolidatedReport } from '../../../../redux/reports';
import {
  DateRangePicker,
} from '../../../../components/Form/Inputs';
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue
} from "../../../../components/Form/validation";
import queryString from "query-string";

const texts = {
  btnText: "Зведений звіт",
  date: "Період від - до",
}

const constants = {
  format: "YYYY-MM-DD"
}

class ConsolidateReportForm extends Form {
  title = ''
  onchange = null

  onValidate = makeFormValidator({
    date: composeValidators({
      [validationTexts.required]: isEmptyValue
    })
  });

  onSubmit = props => {
    const { date } = props
    const { history, getConsolidatedReport} = this.props
    let finalData = {}

    date.forEach((item, index) => {
      if (index === 0) {
        finalData.StartDate = item.local().format(constants.format)
      } else {
        finalData.EndDate = item.local().format(constants.format)
      }
    })
    const newQuery = queryString.stringify(finalData)
    history.push(`/reports/consolidated?${newQuery}`)
    getConsolidatedReport(finalData, history)
  }

  renderFields = () => {
    return (
      <div className="c-form__item">
        <Field
          className="c-form__field"
          name="date"
          maxDate={moment().add(1, 'days').format()}
          component={DateRangePicker}
          label={texts.date}
          required
        />
        <div className="c-form__submit">
          <button className="c-button" type="submit">{texts.btnText}</button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  getConsolidatedReport,
};

export default connect(null, mapDispatchToProps)(withRouter(ConsolidateReportForm));
