import { takeEvery, put, call } from 'redux-saga/effects';
import { SET_NOTIFICATION } from '../notification';
import { SET_PAGE_COUNT } from '../pagination';

import {
  addNewPayDesk,
  getAllPayDesk,
  deletePayDesk,
  restorePayDesk,
  editPayDeskById,
  fetchPayDesks,
  fetchAllTerminal
} from '../../http/paydesks';

const PAY_DESKS_LOAD = 'pay_desks/PAY_DESKS_LOAD';
const PAY_DESKS_LOADING = 'pay_desks/PAY_DESKS_LOADING';
const PAY_DESKS_LOAD_SUCCESS = 'pay_desks/PAY_DESKS_LOAD_SUCCESS';
const CREATE_NEW_PAY_DESK = 'pay_desks/CREATE_NEW_PAY_DESK';
const PAY_DESKS_LOAD_FAILED = 'pay_desks/PAY_DESKS_LOAD_FAILED';
const PAY_DESKS_MODAL_OPEN = 'pay_desks/PAY_DESKS_MODAL_OPEN';
const PAY_DESKS_MODAL_CLOSE = 'pay_desks/PAY_DESKS_MODAL_CLOSE';
const PAY_DESK_ADD_SUCCESS = 'pay_desks/PAY_DESK_ADD_SUCCESS';
const ADD_NEW_PAY_DESK = 'pay_desks/ADD_NEW_PAY_DESK';
const PAY_DESKS_SET_INITIAL = 'pay_desks/PAY_DESKS_SET_INITIAL';
const RESET_PAY_DESK_VALUE = 'pay_desks/RESET_TEST'
const BLOCK_PAY_DESK = 'pay_desk/BLOCK_PAY_DESK';
const RESTORE_PAY_DESK = 'pay_desk/RESTORE_PAY_DESK';
const PAY_DESK_EDIT = 'pay_desk/PAY_DESK_EDIT';
const PAY_DESK_EDIT_SUCCESS = 'pay_desk/PAY_DESK_EDIT_SUCcESS';
const PAY_DESKS_GRID_LOAD = 'pay_desk/PAY_DESKS_GRID_LOAD';
const PAY_DESKS_GRID_LOAD_SUCCESS = 'pay_desk/PAY_DESKS_GRID_LOAD_SUCCESS';

const TERMINAL_LOADING = 'pay_desk/PARAMETER_LOADING'
const TERMINAL_LOAD_FAILED = 'pay_desk/PARAMETER_LOAD_FAILED'
const TERMINAL_LOAD_SUCCESS = 'pay_desk/PARAMETER_LOAD_SUCCESS'
const TERMINAL_ITEMS_LOAD_SUCCESS = 'pay_desk/PARAMETER_ITEMS_LOAD_SUCCESS'
const TERMINAL_ITEMS_LOAD = 'pay_desk/PARAMETER_ITEMS_LOAD'

const initialState = {
  initialValues: {
    name: '',
    terminalName:'',
    registrarFiscalNumber:'',
    fiscalNumber:'',
    terminalId:'',
    shopId:''
  },
  visible: false,
  loading: false,
  data: [],
  gridData: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAY_DESKS_GRID_LOAD_SUCCESS: {
      return {
        ...state,
        gridData: action.data.items,
        loading: false
      }
    }
    case PAY_DESKS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PAY_DESKS_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case PAY_DESKS_MODAL_OPEN: {
      return {
        ...state,
        visible: true,
      };
    }
    case PAY_DESKS_MODAL_CLOSE: {
      return {
        ...state,
        currentId: '',
        visible: false,
      };
    }
    case PAY_DESKS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data
      };
    }
    case CREATE_NEW_PAY_DESK: {
      return {
        ...state,
        loading: false,
        visible: true,
        initialValues: {
          name: '',
          terminalName:'',
          registrarFiscalNumber:'',
          fiscalNumber:'',
          terminalId:'',
          shopId:''
        },
      };
    }
    case PAY_DESK_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        visible: false,
        currentId: null
      };
    }
    case PAY_DESKS_SET_INITIAL: {
      return {
        ...state,
        visible: true,
        loading: false,
        initialValues: {
            id: action.data.id,
            name: action.data.name,
            terminalName: action.data.terminalName,
            registrarFiscalNumber: action.data.registrarFiscalNumber,
            fiscalNumber: action.data.fiscalNumber,
            terminalId: action.data.terminalId,
            shopId: action.data.shopId
        }
      };
    }
    case RESET_PAY_DESK_VALUE: {
      return {
        ...state,
        visible: true,
        loading: false,
        initialValues: {
          id: state.initialValues.id,
          name: state.initialValues.name,
          terminalName: "",
          registrarFiscalNumber: state.initialValues.registrarFiscalNumber,
          fiscalNumber: state.initialValues.fiscalNumber,
          terminalId: "",
          shopId: ""
        }
      };
    }
    case PAY_DESK_EDIT_SUCCESS: {
      return {
        ...state,
        visible: false,
        loading: false,
        initialValues: {}
      }
    }
    case TERMINAL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case TERMINAL_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case TERMINAL_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.items
      };
    }
    case TERMINAL_ITEMS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        terminalData: action.data
      }
    }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getPayDesksGrid = queries => ({ type: PAY_DESKS_GRID_LOAD, queries });
export const getAllPayDesks = () => ({ type: PAY_DESKS_LOAD });
export const getTerminalID =() =>({type: TERMINAL_ITEMS_LOAD })
export const createNewPayDesk = () => ({ type: CREATE_NEW_PAY_DESK });
export const addPayDesk = (data, pageNumber) => ({ type: ADD_NEW_PAY_DESK, data, pageNumber });
export const setInitialData = data => ({ type: PAY_DESKS_SET_INITIAL, data });
export const openModal = () => ({ type: PAY_DESKS_MODAL_OPEN });
export const closeModal = () => ({ type: PAY_DESKS_MODAL_CLOSE });
export const blockPayDesk = (id, pageNumber)=> ({ type: BLOCK_PAY_DESK, id, pageNumber });
export const unlockPayDesk = (id, pageNumber) => ({ type: RESTORE_PAY_DESK, id, pageNumber });
export const editPayDesk = (id, data, pageNumber) => ({ type: PAY_DESK_EDIT, id, data, pageNumber });
export const resetPayDesk = (data) => ({type: RESET_PAY_DESK_VALUE, data})
//<<<WORKERS>>>
function* getAllData({queries = {}}) {
  yield put({ type: PAY_DESKS_LOADING })
  try {
    const response = yield call(getAllPayDesk, queries);
    if (response.status === 200) {
      yield put({ type: PAY_DESKS_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.length });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
  } catch (error) {
    yield put({ type: PAY_DESKS_LOAD_FAILED })
    console.log(error)
  }
};

function* getGriData({queries = {}}) {
  yield put({ type: PAY_DESKS_LOADING })
  try {
    const response = yield call(fetchPayDesks, queries);
    if (response.status === 200) {
      yield put({ type: PAY_DESKS_GRID_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
  } catch (error) {
    yield put({ type: PAY_DESKS_LOAD_FAILED })
    console.log(error)
  }
};


export function* getAllTerminalData() {
  yield put({ type: TERMINAL_LOADING })
  try {
    const response = yield call(fetchAllTerminal);

    if (response.status === 200) {
      yield put({ type: TERMINAL_ITEMS_LOAD_SUCCESS, data: response.data,  })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: TERMINAL_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: TERMINAL_LOAD_FAILED })
    console.log(error)
  }
};

function* addData({ data, pageNumber }) {
  yield put({ type: PAY_DESKS_LOADING })
  try {
    const response = yield call(addNewPayDesk, data);
    if (response.status === 200) {
      yield put({ type: PAY_DESK_ADD_SUCCESS })
      yield put({ type: PAY_DESKS_GRID_LOAD, queries: { pageNumber } })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
      yield put({ type: PAY_DESKS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: PAY_DESKS_LOAD_FAILED })
    console.log(error)
  }
};

function* blockData({ id, pageNumber }) {
  yield put({ type: PAY_DESKS_LOADING })
  try {
    const response = yield call(deletePayDesk, id);
    if (response.status === 200) {
      yield put({ type: PAY_DESKS_GRID_LOAD, queries: { pageNumber }})
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: PAY_DESKS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: PAY_DESKS_LOAD_FAILED })
    console.log(error)
  }
};

function* restoreData({ id, pageNumber }) {
  yield put({ type: PAY_DESKS_LOADING })
  try {
    const response = yield call(restorePayDesk, id);
    if (response.status === 200) {
      yield put({ type: PAY_DESKS_GRID_LOAD, queries: { pageNumber } })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: PAY_DESKS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: PAY_DESKS_LOAD_FAILED })
    console.log(error)
  }
};

function* editData({ id, data, pageNumber }) {
  try {
    yield put({ type: PAY_DESKS_LOADING })
    const response = yield call(editPayDeskById, id, data);
    if (response.status === 200) {
      yield put({ type: PAY_DESKS_GRID_LOAD, queries: { pageNumber } });
      yield put({ type: PAY_DESK_EDIT_SUCCESS})
    }
    if (response.status >= 400) {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
      yield put({ type: PAY_DESKS_LOAD_FAILED })
    }
  } catch (error) {
    console.log(error)
  }
};

//<<<WATCHERS>>>
export function* watchPaydesks() {
  yield takeEvery(PAY_DESKS_LOAD, getAllData);
  yield takeEvery(PAY_DESKS_GRID_LOAD, getGriData);
  yield takeEvery(TERMINAL_ITEMS_LOAD, getAllTerminalData);
  yield takeEvery(ADD_NEW_PAY_DESK, addData);
  yield takeEvery(BLOCK_PAY_DESK, blockData);
  yield takeEvery(RESTORE_PAY_DESK, restoreData);
  yield takeEvery(PAY_DESK_EDIT, editData);

}
