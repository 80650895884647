import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";
import Form from "../../../components/Form";
import { FieldArray } from "react-final-form-arrays";
import {
  TextField,
  MaterialReactSelect,
  NumberMaskField,
} from "../../../components/Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
} from "../../../components/Form/validation";
import {
  createComing, deleteComingsFromModal,
  editComing,
  setCurrentMaterialData,
  setInitialData,
} from "../../../redux/comings";
import { exportPDF } from "../../../components/ExportToPdf";

const dataHeaders = [
  ["МАТЕРІАЛ", "ПАРАМЕТЕР", "КІЛЬКІСТЬ", "ВХІДНА ЦІНА", "ВИХІДНА ЦІНА"],
];

const texts = {
  number: "Номер",
  provider: "Постачальник",
  materialName: "Матеріал",
  materialNumber: "Кількість",
  materialParameter: "Параметер",
  materialInputPrice: "Вхідна ціна",
  materialOutputPrice: "Вихідна ціна",
  btnAdd: "Додати",
  btnCancel: "Cкасувати",
  btnSubmit: "Зберегти",
  btnPrint: "Друк",
};

const Emoji = (props) => (
  <option
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
    value={props.label}
  >
    {props.symbol}
  </option>
);

class ComingForm extends Form {
  title = this.props.values.id
    ? "Редагувати прихід матеріалів"
    : "Додати прихід матеріалів";
  onchange = null;

  onValidate = makeFormValidator({
    number: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: (value) =>
        value ? value.length < 3 : false,
    }),
    provider: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: (value) =>
        value ? value.length < 3 : false,
    }),
  });

  handleChangeMaterial = ({ value }, index) => {
    if (!value) return false;
    const { materialsData, setCurrentMaterialData } = this.props;
    const currentData = materialsData.find((item) => item.id === value);
    currentData &&
      setCurrentMaterialData(
        {
          id: currentData.id,
          parameter: currentData.parameter,
          inputPrice: currentData.inputPrice,
          outputPrice: currentData.outputPrice,
          name: currentData.name,
        },
        index
      );
  };

  handleChangePrice = (name, data, index) => {
    const { value } = data.target;
    const { setCurrentMaterialData } = this.props;
    if (!value) {
      setCurrentMaterialData({ [name]: 0 }, index);
      return false;
    }
    setCurrentMaterialData({ [name]: value }, index);
  };

  handleChange = (data) => {
    const { value, name } = data.target;
    const { setInitialData } = this.props;
    setInitialData({ [name]: value });
  };

  handleDataExportPDF = async () => {
    const { number, provider, materials } = this.props.values;
    const finalData = materials.map((el) => {
      return [el.name, el.parameter, el.amount, el.inputPrice, el.outputPrice];
    });
    exportPDF({
      tableData: [...finalData],
      header: dataHeaders,
      format: "landscape",
      contentBefore: `Номер - ${number}\nПостачальник - ${provider}`,
    });
  };

  showPrintButtonIfFieldIsNotEmpty = () => {
    return this.props.values.number !== "" &&
      this.props.values.provider !== "" &&
      this.props.values.materials[0].name !== "" &&
      this.props.values.materials[0].inputPrice !== "" &&
      this.props.values.materials[0].outputPrice !== "" ? (
      <span
        style={{
          padding: "9px 40px",
          marginLeft: "5px",
          backgroundColor: "#00cba6",
          color: "#ffffff",
          borderRadius: "30px",
          display: "inline-block",
          cursor: "pointer",
        }}
        onClick={() => this.handleDataExportPDF()}
      >
        {texts.btnPrint}
      </span>
    ) : null;
  };

  onSubmit = (props) => {
    const {
      createComing,
      editComing,
      activeMaterials,
      pageNumber,
      queriesData,
    } = this.props;
    const { id } = props;
    let updateMaterials = [];
    props.materials.map((item) => {
      const currentMaterial = activeMaterials.find(
        (i) => i.id === (typeof item.id === "string" ? item.id : item.id.value)
      );
      const materialItem = {
        ...item,
        id: currentMaterial?.id,
        name: currentMaterial?.name,
      };
      return updateMaterials.push(materialItem);
    });

    const result = {}
    updateMaterials.forEach(function (a) {
      if (!result[a.name] ) {

        result[a.name] = { ...a, amount: parseFloat(a.amount)  };
      } else {
        result[a.name].inputPrice += a.inputPrice
        result[a.name].outputPrice += a.outputPrice
        result[a.name].amount += parseFloat(a.amount)
      }
    });
    const updatedMaterials = Object.values(result)

    const finalData = id
      ? { ...props, materials: updatedMaterials, id }
      : { ...props, materials: updatedMaterials };
    id
      ? editComing(id, finalData, pageNumber, queriesData)
      : createComing(finalData, pageNumber, queriesData);
  };

  required = (value) => {
    if (!value) {
      return "Обов'язкове поле";
    } else if (value <= 0) {
      return "Значення має бути більше - 0";
    } else {
      return undefined;
    }
  };
  requiredArray = (value) =>
    value && value.length > 0 ? undefined : "Обов'язкове поле";

  renderFields = (props) => {
    const {
      form: {
        mutators: { push },
      },
    } = props;
    return (
      <div className="c-form__item">
        <div className="c-form__item-group">
          <Field
            className="c-form__field"
            name="number"
            component={TextField}
            label={texts.number}
            onChange={this.handleChange}
            required
          />
          <Field
            className="c-form__field"
            name="provider"
            component={TextField}
            label={texts.provider}
            onChange={this.handleChange}
            required
          />
        </div>
        <FieldArray name="materials" validate={this.requiredArray}>
          {({ fields }) =>
            fields.map((name, index) => (
              <>
                <div className={'small-select'}>
                  <Field
                    validate={this.required}
                    className="c-form__field small"
                    name={`${name}.id`}
                    label={texts.materialName}
                    component={MaterialReactSelect}
                    selectValueSize={40}
                    onChange={(data) => this.handleChangeMaterial(data, index)}
                    required
                  />
                </div>

                <div key={index} className="c-form__item-group comings-group">
                  <Field
                    className="c-form__field"
                    name={`${name}.parameter`}
                    label={texts.materialParameter}
                    component={TextField}
                    disabled
                  />
                  <Field
                    validate={this.required}
                    className="c-form__field"
                    name={`${name}.amount`}
                    label={texts.materialNumber}
                    component={NumberMaskField}
                    onBlur={(e) => this.handleChangePrice("amount", e, index)}
                    required
                  />
                  <Field
                    validate={this.required}
                    className="c-form__field"
                    name={`${name}.inputPrice`}
                    label={texts.materialInputPrice}
                    onBlur={(e) => this.handleChangePrice("inputPrice", e, index)}
                    component={NumberMaskField}
                  />
                  <Field
                    validate={this.required}
                    className="c-form__field"
                    name={`${name}.outputPrice`}
                    label={texts.materialOutputPrice}
                    onBlur={(e) =>
                      this.handleChangePrice("outputPrice", e, index)
                    }
                    component={NumberMaskField}
                    required
                  />
                  <div
                    className="c-form__close-item"
                    onClick={() => {
                      fields.remove(index)
                      this.props.deleteComingsFromModal(index)
                    }
                    }
                  >
                    <Emoji label="donut" symbol="❌" />
                  </div>
                </div>
              </>

            ))
          }
        </FieldArray>
        <div className="c-form__add-item">
          <button type="button" onClick={() => push("materials", undefined)}>
            {texts.btnAdd}
          </button>
        </div>
        <div className="c-form__submit">
          <button className="c-button" type="submit">
            {texts.btnSubmit}
          </button>
          {this.showPrintButtonIfFieldIsNotEmpty()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  materialsData: state.createOrder.orderData.materials,
  values: state.comings.initialValues,
  activeMaterials: state.createOrder.orderData.materials,
  pageNumber: state.pagination.pageNumber,
  queriesData: state.comings.queriesData,
});

const mapDispatchToProps = {
  createComing,
  editComing,
  setCurrentMaterialData,
  deleteComingsFromModal,
  setInitialData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingForm);
