import React from 'react';

export const productFormatter = (materialId, servicesId, orderData) => {
  let currentItem;
  if(materialId) {
    currentItem = orderData.materials.find(item => item.id === materialId)
  } else {
    currentItem = orderData.services.find(item => item.id === servicesId)
  }
  return <div>{currentItem && currentItem.name}</div>
} 
