import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";
import Form from "../../../components/Form";

import {
  createClient,
  editClient,
  getAllClients,
} from "../../../redux/clients";

import {
  TextField,
  PhoneField,
  EmailField,
} from "../../../components/Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isNotEmail,
  isNotPhoneNumber,
} from "../../../components/Form/validation";
import { withRouter } from "react-router-dom";

const texts = {
  name: "Iмя клієнта",
  surname: "Прізвище клієнта",
  btnText: "Створити",
  editText: "Зберегти",
  phone: "Телефон",
  email: "Email",
  clientId: "Клієнт Id",
};

class ClientFrom extends Form {
  title = this.props.values.id ? "Редагувати клієнта" : "Додати клієнта";
  onchange = null;

  onValidate = makeFormValidator({
    number: composeValidators({
      [validationTexts.required]: (value) =>
          value === undefined || value.toString().trim().length === 0,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
    firstName: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: (value) =>
        value ? value.length < 3 : false,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
    lastName: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
    phone: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined ||
        value === null ||
        value.toString().trim().length === 0,
      [validationTexts.notPhone()]: isNotPhoneNumber,
    }),
    email: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.notEmail()]: isNotEmail,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
  });

  onSubmit = (props) => {
    const { id } = props;
    const { editClient, createClient, pageNumber, queriesData } = this.props;
    id
      ? editClient(id, props, pageNumber, queriesData)
      : createClient(props, pageNumber, queriesData);
  };

  renderFields = () => {
    const { id } = this.props.values;
    return (
      <div className="c-form__item">
        <Field
          className="c-form__field"
          name="number"
          component={TextField}
          label={texts.clientId}
          required
        />
        <Field
          className="c-form__field"
          name="firstName"
          component={TextField}
          label={texts.name}
          required
        />
        <Field
          className="c-form__field"
          name="lastName"
          component={TextField}
          label={texts.surname}
          required
        />
        <Field
          className="c-form__field"
          name="phone"
          component={PhoneField}
          label={texts.phone}
          required
        />
        <Field
          className="c-form__field"
          name="email"
          label={texts.email}
          component={EmailField}
          required
        />
        <div className="c-form__submit">
          {id ? (
            <button className="c-button" type="submit">
              {texts.editText}
            </button>
          ) : (
            <button className="c-button" type="submit">
              {texts.btnText}
            </button>
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  values: state.clients.initialValues,
  pageNumber: state.pagination.pageNumber,
  queriesData: state.clients.queriesData,
  notification: state.notification,
});

const mapDispatchToProps = {
  createClient,
  editClient,
  getData: getAllClients,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientFrom));
