import http from "../index";
import { generateSortParams } from "../../helpers";
import { tablePageSize } from "../../helpers/constants";

export function fetchBalances({
  clientId = "",
  paydeskId = "",
  cashierId = "",
  startDate = "",
  transferNumber = "",
  Transaction = "",
  orderNumber = "",
  paymentMethodId = "",
  sort = "",
  endDate = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const clientIdQuery = clientId && `clientId=${clientId}`;
  const paydeskIdQuery = paydeskId && `&PaydeskId=${paydeskId}`;
  const сashierIdQuery = cashierId && `&CashierId=${cashierId}`;
  const startDateQuery = startDate && `&StartDate=${startDate}`;
  const paymentMethodIdQuery =
    paymentMethodId && `&PaymentMethodId=${paymentMethodId}`;
  const endDateQuery = endDate && `&EndDate=${endDate}`;
  const transferNumberQuery =
    transferNumber && `&transferNumber=${transferNumber}`;
  const transactionQuery = Transaction && `&Transaction=${Transaction}`;
  const orderNumberQuery = orderNumber && `&OrderNumber=${orderNumber}`;
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const queries = [
    clientIdQuery,
    paydeskIdQuery,
    сashierIdQuery,
    startDateQuery,
    transactionQuery,
    orderNumberQuery,
    transferNumberQuery,
    paymentMethodIdQuery,
    endDateQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);
  return http
    .get(`/ClientsBalanceHistory/balance-grid?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}
export function fetchAllClientsBalanceHistoryForPdf(url,name) {
  return http
      .get(`/ClientsBalanceHistory/${name}${url}&PageSize=999`)
      .then((response) => response)
      .catch((error) => error.response);
}

export function fetchAllClientsOrderHistoryForPdf(url,name,id) {
  return http
      .get(`/OrdersHistory/${name}?&clientId=${id}&PageSize=999&${url}`)
      .then((response) => response)
      .catch((error) => error.response);
}
