import React from 'react';
import RestoreIcon from '../../assets/images/icons/restore.png';
import BlockIcon from '../../assets/images/icons/block.png';
import { roleNames } from '../../roles';

export const blockUnblockFormatter = (id, value, blockItem, unblockItem, pageNumber, queriesData, userRole, isDefault) => {
  if(userRole === roleNames.superAdmin && !isDefault) {
    return  !value ? 
      <div className="action-formatter" onClick={() => blockItem(id, pageNumber, queriesData)}><img src={BlockIcon} alt="block"/></div> : 
      <div className="action-formatter" onClick={() => unblockItem(id, pageNumber, queriesData)}><img src={RestoreIcon} alt="unblock"/></div>
  } else {
    return false
  }
}
   

