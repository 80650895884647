import SelectReact from '..';
import { connect } from 'react-redux';
import { getAllAccountantAbdAdmins } from '../../../../redux/employee';
import { generateSelectValue } from '../../../../helpers';

class AccountantsAndAdminsSelect extends SelectReact {

  state={}
  label = ''
  name = 'recipientId'
  maxHeight = 250

  componentDidMount = () => this.props.getAllAccountantAbdAdmins()

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.id === this.props.value)
    return currentOption ? {value: currentOption.value, label: generateSelectValue({ value: currentOption.fullName })} : null
  }

  renderOptions = () => this.props.data.map(({ id, fullName }) => ({ value: id, label: generateSelectValue({value: fullName})  }))
}

const mapStateToProps = state => ({
  data: state.employees.allAccountantAndAdmins
})

const mapDispatchToProps = {
  getAllAccountantAbdAdmins
}

export default  connect(mapStateToProps, mapDispatchToProps)(AccountantsAndAdminsSelect) ;