import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";
import { withRouter } from "react-router-dom";
import { makeFormValidator } from "../../../../components/Form/validation";
import Form from "../../../../components/Form";
import { RadioField, TextField, PaydeskReactSelect} from "../../../../components/Form/Inputs";
import { deleteSingleOrder } from "../../../../redux/orders";

const texts = {
  onBalance: "На баланс",
  title: "Видалення замовлення",
  btnText: "Видалити",
  returnCashTitle: "Повернення коштів",
  returnMaterialsTitle: "Повернення матеріалів на склад",
  payDesk: "ФОП",
  returnType: "Тип повернення"
};

const options = [
  {
    id: 0,
    name: 'за замовчуванням'
  },
  {
    id: 1,
    name: 'повернення на баланc'
  },
  {
    id: 2,
    name: 'повернення готівкою'
  }
]

class DeleteOrderItemForm extends Form {
  title = texts.title;

  state = {
    value: null,
    cashBackType: 0
  };

  handlePayment = e => {
    this.setState(prevState => ({
      ...prevState,
      cashBackType: e.target.value
    }))
  }

  onValidate = makeFormValidator({
    //  paydeskId:  composeValidators({
    //   [validationTexts.required]: value => value ?  : !(this.state.cashBackType === 0 && !value)
    // }),
  });

  onSubmit = (props) => {
    const { deleteSingleOrder, orderId, currentCashier, history: { goBack } } = this.props;
    const { paydeskId, ...rest } = props;
    deleteSingleOrder({
      orderId,
      cashierId: currentCashier,
      paydeskId: props.paydeskId ? props.paydeskId.value : '',
      cashBackType: this.state.cashBackType,
      materials: { ...rest }
    }, goBack);
  };

  renderFields = () => (
    <>
      <div className="c-form__item">
        <div className="c-form__item-title">{texts.returnCashTitle}</div>
        <div className="c-form__item-group">
          <Field
            className="c-form__field"
            name="cashBackType"
            component={RadioField}
            label={texts.returnType}
            options={options}
            onChange={this.handlePayment}
            value={this.state.cashBackType}
            type="radio"
            required
          />
          {this.state.cashBackType !== 0 && <Field
            className="c-form__field"
            name="paydeskId"
            component={PaydeskReactSelect}
            label={texts.payDesk}
          />}
        </div>
      </div>
      {this.props.materialsData.length ? (
        <>
          <div className="c-form__item-title">{texts.returnMaterialsTitle}</div>
          {this.props.materialsData.map((item) => {
            return (
              <div className="c-form__item">
                <Field
                  className="c-form__field"
                  name={item.materialId}
                  defaultValue={0}
                  component={TextField}
                  label={item.materialName}
                />
              </div>
            );
          })}
        </>
      ) : ''}
      <div className="c-form__submit">
        <button className="c-button" type="submit">
          {texts.btnText}
        </button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  orderId: state.orders.dataForEditSingle.orderId,
  currentCashier: state.login.user.id,
  materialsData: state.orders.materialsData,
  notification: state.notification
});

const mapDispatchToProps = {
  deleteSingleOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteOrderItemForm));
