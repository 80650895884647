import React from "react";
import queryString from "query-string";

import columns from "./columns";
import GeneralPage from "../GeneralPage";
import { connect } from "react-redux";
import { getAllCollections } from "../../redux/collections";
import { getDataForCreateOrder } from "../../redux/createOrder";
import { setPageNumber } from "../../redux/pagination";
import CashierSelect from "../../components/GeneralFields/Select/СashierSelect";
import AccountantsAndAdminsSelect from "../../components/GeneralFields/Select/AccountantsAndAdminsSelect";
import EntrepreneursSelect from "../../components/GeneralFields/Select/EntrepreneursSelect";
import DateRangePicker from "../../components/GeneralFields/RangePicker";
import SortField from "../../components/GeneralFields/SortField";

const constants = {
  format: "YYYY-MM-DD",
  optionStartDate: "startDate",
  optionEndDate: "endDate",
  historyCollectionsFilterRestMoney: "RestMoney",
  historyCollectionsFilterEncashMoney: "EncashMoney",
  historyCollectionsFilterCashierId: "cashierId",
  historyCollectionsFilterRecipientId: "recipientId",
  historyCollectionsFilterPaydeskId: "paydeskId",
};

class HistoryCollections extends GeneralPage {
  pageTitle = "Історія інкасації";
  class = "collection-history";
  columns = columns.general;

  state = {
    name: undefined,
    sort: {},
  };

  componentDidMount = () => {
    const { getData, getDataForCreateOrder, orderData } = this.props;
    const query = queryString.parse(this.props.location.search);
    !orderData.services.length && getDataForCreateOrder();
    let sort = {};
    for (let property in query) {
      if (property === constants.historyCollectionsFilterEncashMoney) {
        sort[property] = query[property];
      } else if (property === constants.historyCollectionsFilterRestMoney) {
        sort[property] = query[property];
      }
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  getDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.optionStartDate] = "";
      changeData[constants.optionEndDate] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionStartDate] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionEndDate] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

    additionalComponentsTops = () => (
        <div className="cash-register-history__paydesk-balance default-btn">
            <h2 className="page-title">
                {this.pageTitle} <span>{this.subTittle}</span>
            </h2>
        </div>
    );

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        <EntrepreneursSelect
          value={query[constants.historyCollectionsFilterPaydeskId]}
          label=""
          getSelectedValue={this.getFilterValue}
          name="paydeskId"
        />
        <DateRangePicker
          defaultValue={[
            query[constants.optionStartDate],
            query[constants.optionEndDate],
          ]}
          onChange={this.getDate}
        />
        <CashierSelect
          value={query[constants.historyCollectionsFilterCashierId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
        />
        <AccountantsAndAdminsSelect
          value={query[constants.historyCollectionsFilterRecipientId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.historyCollectionsFilterEncashMoney}
          value={query[constants.historyCollectionsFilterEncashMoney]}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.historyCollectionsFilterRestMoney}
          value={query[constants.historyCollectionsFilterRestMoney]}
        />
      </>
    );
  };
}

const mapStateToProps = ({ collections, pagination, createOrder }) => ({
  ...collections,
  ...pagination,
  orderData: createOrder.orderData,
});

const mapDispatchToProps = {
  getData: getAllCollections,
  setPageNumber,
  getDataForCreateOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCollections);
