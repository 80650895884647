import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import columns from "./columns";
import * as moment from "moment";
import GeneralPage from "../../GeneralPage";
import { setPageNumber } from "../../../redux/pagination";
import { downloadReportPdf, getReportByProduct } from "../../../redux/reports";
import {getServices} from "../../../redux/services";
import { exportPDF } from "../../../components/ExportToPdf";
import {fetchAllReportForPdf} from "../../../http/reports";

const texts = {
  btn: "Друк",
  totalAmount: "Загальна кількість",
  totalCost: "Загальна вартість",
  reportByService: "Звіт по послузі",
};

const constants = {
  serviceEntityId: "EntityId",
  optionFirstDay: "StartDate",
  optionLastDay: "EndDate",
};

const dataHeaders = [
  ["ДАТА", "РОБІТНИК", "КІЛЬКІСТЬ", "СУМА", "ЦІНА", "КЛІЄНТ"],
];

class ReportByService extends GeneralPage {
  class = "report-by-service";
  columns = columns.general;
  componentDidMount = () => false;
  state = {
    name: undefined,
  };

  componentDidMount = () => {
    const {
      getData,
      history,
      location: { search },
      getAllServices,
    } = this.props;
    const query = queryString.parse(search);
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state, history});
        getAllServices();
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  handleShowServiceName = () => {
    const { allServices, choseData } = this.props;
    const currentService =
      allServices && allServices?.find((item) => item.id === choseData.EntityId);
    return currentService ? currentService.name : null;
  };

  additionalComponentsTops = () => (
    <div className="report-by-materials__top">
      <p>
        <span>{texts.reportByService}</span> {this.handleShowServiceName()}
      </p>
    </div>
  );

  handleDataExportPDF = async () => {



    console.log(this.props.location)
    const filters = this.props.location.search +'&isMaterial=false'
    try {
      const {data} = await fetchAllReportForPdf(filters,'gridbyproduct')
      const finalData = data.items.map((elt) => {
        return [
          moment(elt.date).format("DD.MM.YYYY HH:mm"),
          elt.employeeFullName,
          elt.amount,
          `${elt.cost} грн`,
          `${elt.price} грн`,
          elt.clientFullName,
        ];
      });
      exportPDF({
        tableData: finalData,
        header: dataHeaders,
        format: "landscape",
        contentBefore: `${this.showContentBeforeTablePDF()}`,
      });
    } catch (e) {

    }
  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return location.search ? this.showFilterPDF(location.search.slice(1)) : "";
  };

  showFilterPDF = (query) => {
    const { totalAmount, totalCost } = this.props.details;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.serviceEntityId:
          chosenFilters[texts.reportByService] = this.handleShowServiceName();
          break;
        case constants.optionFirstDay:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("EndDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Період"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Період"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        default:
          return chosenFilters;
      }
    });
    chosenFilters[texts.totalAmount] = totalAmount;
    chosenFilters[texts.totalCost] = `${totalCost} грн`;
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  additionalComponents = () => (
    <div className="report-by-materials__bottom">
      <div className="report-by-materials__details">
        <div className="report-by-materials__details_item">
          <p>
            <span>{texts.totalAmount}</span> {this.props.details.totalAmount}
          </p>
        </div>
        <div className="report-by-materials__details_item">
          <p>
            <span>{texts.totalCost}</span> {this.props.details.totalCost} грн.
          </p>
        </div>
      </div>
      <div className="default-btn">
        <button onClick={() => this.handleDataExportPDF(this.props.data)}>
          {texts.btn}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ reports, pagination, services }) => ({
  data: reports.serviceData,
  choseData: reports.choseServiceData,
  details: reports.detailsService,
  allServices: services.allData,
  ...pagination,
  allData: services.allData
});

const mapDispatchToProps = {
  setPageNumber,
  downloadReportPdf,
  getData: getReportByProduct,
  getAllServices: getServices,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportByService);
