import React from 'react';
import { Input } from 'antd';
import { getInputClass } from '../helpers';

const TextField = ({ input, meta, label = "", placeholder = "Введіть", spanClassName="", required, ...props }) => {
  const requiredMark = required ? <span className="c-form__field--required">*</span> : '';
  const className = getInputClass({ meta, value: input.value })
  return (
    <span className={`${className} ${spanClassName}`}>
      <label>{label}{requiredMark}</label>
      <Input
        maxLength="50"
        {...input}
        {...props}
        mask={[/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/]}
        placeholder={placeholder}
      />
      <div className="ant-form-explain">
        {meta.touched ? meta.error : ''}
      </div>
    </span>
  );
};


export default TextField;
