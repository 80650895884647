import { connect } from "react-redux";
import { generateSelectValue } from "../../../../helpers";
import {getTerminalID} from "../../../../redux/paydesks";
import ReactSelectForPaydesks from "../../../Form/Inputs/ReactSelectForPaydesks";

class AllTerminalSelect extends ReactSelectForPaydesks {
    label = "";
    name = "";
    maxHeight = 250;
    value='';
    state = {
        value: ''
    }

    componentDidMount = () =>  {
      !this.props.data?.length && this.props.getTerminalID();
    }

    getInitialValue = () => {

        const currentOption =
            this.props.data &&
            this.props.data.find((item) => {
                return item.id === parseInt(this.props.initialValues.terminalId) ? item.name : null
            });

        return currentOption
            ? {
                label: generateSelectValue({
                    value: currentOption.name,
                }),
            }
            : ''
    };

    renderOptions = () => {
        if(!this.props.data) return []
        return this.props?.data?.map((element) => ({
            value: element.name,
            terminalId: element.id,
            terminalName:  element.name,
            shopId: element.shopId,
            label: generateSelectValue({
                value: element.name,
                size: this.props.selectValueSize,
            }),
        }));
    }
}

const mapStateToProps = (state) => ({
    data: state.paydesks.terminalData,
    initialValues:state.paydesks.initialValues
});

const mapDispatchToProps = {
    getTerminalID
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTerminalSelect);
