import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import Form from '../../../components/Form';

import { createService, editService } from '../../../redux/services';

import { TextField, NumberMaskField } from '../../../components/Form/Inputs';
import {
  composeValidators,
  makeFormValidator,
  validationTexts
} from "../../../components/Form/validation";
import { withRouter } from 'react-router-dom';
import AllParameterSelectForServices from "../../../components/GeneralFields/Select/AllParameterSelectForServices";

const texts = {
  name: 'Назва',
  btnText: "Створити",
  parameter: "Параметр",
  price: "Ціна",
  editText: "Зберегти"
}

class ServiceForm extends Form {
  title = this.props.values.id ? "Редагувати послугу" : "Додати нову послугу"
  onchange = null
  state = {
    name: '',
    unitTypeId: ''
  }
  componentDidMount() {
    this.setState({unitTypeId: this.props.values.unitTypeId})
  }

  onValidate = makeFormValidator({
    name: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: value => value ? value.length < 3 : false,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50: false,
    }),
    parameter: composeValidators({
      [validationTexts.required]: value => value === undefined || value?.toString()?.trim()?.length === 0,
      [validationTexts.minLength(1)]: value => value ? value.length < 1 : false,
    }),
    price: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: value => value ? value <= 0 : false,
    }),
  });

  onSubmit = props => {
    const { values: {id}, createService, editService, pageNumber, queriesData } = this.props;
    const parameter = props.parameter.value ? props.parameter.value : props.parameter
    const finalData =  {...props, id, parameter, unitTypeId: props.parameter.unitTypeId ? props.parameter.unitTypeId : this.state.unitTypeId }
    id ? editService(id, finalData, pageNumber, queriesData) : createService(finalData, pageNumber, queriesData)
  }

  renderFields = () => {
    const { id } = this.props.values;
    return (
      <div className="c-form__item">
        <Field
          spanClassName="order-form__product"
          className="c-form__field"
          name="name"
          component={TextField}
          label={texts.name}
          required
        />
        <Field
            component={AllParameterSelectForServices}
            className="c-form__field"
            name="parameter"
            label={texts.parameter}
            selectValueSize={45}
            required
        />
        <Field
          className="c-form__field"
          name="price"
          label={texts.price}
          component={NumberMaskField}
          required
        />
        <div className="c-form__submit">
          <button className="c-button" type="submit">{id ? texts.editText : texts.btnText}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  values: state.services.initialValues,
  pageNumber: state.pagination.pageNumber,
  queriesData: state.services.queriesData,
  notification: state.notification,
  parameterData: state.services.parameterData
});

const mapDispatchToProps = {
  createService,
  editService
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceForm));
