import React, { Component } from "react";
import { Form as FinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { connect } from "react-redux";

export default class Form extends Component {
  title = "";
  btnText = "Зберегти";
  candelBtnText = "Відхилити";

  renderForm = (props) => {
    const { handleSubmit } = props;
    return <form onSubmit={handleSubmit}>{this.renderFields(props)}</form>;
  };

  onSubmit = (props) => {
    const {
      editData,
      addData,
      history: { goBack },
      match: {
        params: { id },
      },
    } = this.props;
    id ? editData({ id, data: props }, goBack) : addData(props, goBack);
  };

  getFormValues = () => this.props.values;

  render() {
    return (
      <section className="main-content__form">
        <h3 className="main-content__form-title">{this.title}</h3>
        <h3 className="main-content__form-error">
          {this.props.notification?.text &&
            this.props.notification.text.messages[0]}
        </h3>
        <FinalForm
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          initialValues={this.getFormValues()}
          validate={this.onValidate}
          render={this.renderForm}
          mutators={{
            ...arrayMutators,
          }}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification,
});

connect(mapStateToProps)(Form);
