import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import * as moment from "moment";
import columns from "./columns";
import GeneralPage from "../../GeneralPage";
import { setPageNumber } from "../../../redux/pagination";
import { downloadReportPdf, getReportByClient } from "../../../redux/reports";
import { exportPDF } from "../../../components/ExportToPdf";
import {fetchAllReportForPdf} from "../../../http/reports";
import {getClients} from "../../../redux/clients";


const texts = {
  btn: "Друк",
  totalCostOfMaterials: "Загальна вартість матеріалів",
  totalCostOfServices: "Загальна вартість послуг",
  totalCost: "Загальна вартість",
  reportByClient: "Звіт по клієнту",
};

const constants = {
  clientEntityId: "EntityId",
  optionFirstDay: "StartDate",
  optionLastDay: "EndDate",
};

const dataHeaders = [["ТОВАР/ПОСЛУГА", "КІЛЬКІСТЬ", "ВАРТІСТЬ", "ТИП"]];

class ReportByClient extends GeneralPage {
  class = "report-by-client";
  columns = columns.general;
  componentDidMount = () => false;

  state = {
    name: undefined,
  };

  componentDidMount = () => {
    const {
      getData,
      getClients,
      location: { search }
    } = this.props;
    const query = queryString.parse(search);
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state,});
        getClients()
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  handleShowClientName = () => {
    const { allClients, choseData } = this.props;
    const currentClient =
      allClients && allClients.find((item) => item.id === choseData.EntityId);
    return currentClient ? currentClient.fullName : null;
  };
  additionalComponentsTops = () => (
    <div className="report-by-client__top">
      <p>
        <span>{texts.reportByClient}</span> {this.handleShowClientName()}
      </p>
    </div>
  );

  handleDataExportPDF = async () => {
    const filters = this.props.location.search
    try {
      const {data} = await fetchAllReportForPdf(filters,'gridbyclient')
      const finalData = data.items.map((elt) => {
        return [
          elt.productName,
          elt.amount,
          `${elt.cost} грн`,
          elt.isMaterial ? "Матеріал" : "Послуга",
        ];
      });
      exportPDF({
        tableData: finalData,
        header: dataHeaders,
        format: "landscape",
        contentBefore: `${this.showContentBeforeTablePDF()}`,
      });
    } catch (e) {

    }

  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return location.search ? this.showFilterPDF(location.search.slice(1)) : "";
  };

  showFilterPDF = (query) => {
    const { totalCostOfMaterials, totalCostOfServices, commonCost } =
      this.props.details;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.clientEntityId:
          chosenFilters[texts.reportByClient] = this.handleShowClientName();
          break;
        case constants.optionFirstDay:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("EndDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Період"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Період"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        default:
          return chosenFilters;
      }
    });
    chosenFilters[texts.totalCostOfMaterials] = `${totalCostOfMaterials} грн`;
    chosenFilters[texts.totalCostOfServices] = `${totalCostOfServices} грн`;
    chosenFilters[texts.totalCost] = `${+commonCost} грн`;
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  additionalComponents = () => (
    <div className="report-by-client__bottom">
      <div className="report-by-client__details">
        <div className="report-by-client__details_item">
          <p>
            <span>{texts.totalCostOfMaterials}</span>{" "}
            {this.props.details.totalCostOfMaterials} грн.
          </p>
        </div>
        <div className="report-by-client__details_item">
          <p>
            <span>{texts.totalCostOfServices}</span>{" "}
            {this.props.details.totalCostOfServices} грн.
          </p>
        </div>
        <div className="report-by-client__details_item">
          <p>
            <span>{texts.totalCost}</span> {this.props.details.commonCost} грн.
          </p>
        </div>
      </div>
      <div className="default-btn">
        <button onClick={() => this.handleDataExportPDF(this.props.data)}>
          {texts.btn}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ reports, pagination, clients }) => ({
  data: reports.clientShowData,
  choseData: reports.choseClientData,
  details: reports.detailsClient,
  allClients: clients.allData,
  ...pagination,
});

const mapDispatchToProps = {
  setPageNumber,
  downloadReportPdf,
  getData: getReportByClient,
  getClients
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportByClient);
