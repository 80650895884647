import SelectReact from '../index';
import { connect } from 'react-redux';

import { setCurrentClient } from '../../../../redux/clients'
import { generateSelectValue } from '../../../../helpers';

class ClientsForOrders extends SelectReact {

  handleChange = values => {
    const { data, setCurrentClient } = this.props;
    if(!values) {
      setCurrentClient('');
      return false;
    }
    const currentData = data.find(option => option.id === values.value);
    setCurrentClient(currentData)
  }

  componentWillMount = () => {
    const { data, setCurrentClient } = this.props;
    setCurrentClient(data[0]);
  }

  label = ''
  name = 'clientId'
  maxHeight = 250;
  className='order-clients-select'

  getInitialValue = () =>  {
    const { data, setCurrentClient, currentClient } = this.props;
    const currentOption = data &&  data.find(item => item.id === this.props.value.id);
    data.length && !currentClient.id && setCurrentClient(data[0]);
    return currentOption && data.length ? {value: currentOption.id, label: currentOption.fullName } : {value: data[0].id, label: data[0].fullName}
  }

  renderOptions = () => this.props.data.map(({ id, fullName}) => ({ value: id, label: generateSelectValue({ value: fullName ? fullName : '', size: this.props.selectValueSize  }) }));

}

const mapStateToProps = state => ({
  data: state.createOrder.orderData.clients,
  currentClient: state.clients.currentClient
});

const mapDispatchToProps = {
  setCurrentClient
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsForOrders);