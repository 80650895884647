const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'orderNumber',
      Header: 'Номер накладної',
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: 'orderHistoryLink'
    },
    {
      accessor: 'orderCreationDate',
      Header: 'Дата накладної',
      minWidth: 150,
      filterable: true,
      sortable: false,
      formatter: 'date'
    },
    {
      accessor: 'cashier',
      Header: 'Касир',
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: 'orderEmployeeName'
    },
    {
      accessor: 'status',
      Header: 'Статус',
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: 'orderStatus'
    },
    {
      accessor: 'amount',
      Header: 'Сума',
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: 'currency'
    },
    {
      accessor: 'paidSum',
      Header: 'Оплачено',
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: 'currency'
    },
    {
      accessor: 'debt',
      Header: 'Борг',
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: 'discount'
    },
    {
      accessor: 'client',
      Header: 'Клієнт',
      minWidth: 200,
      sortable: false,
      filterable: true,
      formatter: 'replenishOrder',
    },
    {
      accessor: null,
      Header: null,
      minWidth: 50,
      sortable: false,
      filterable: true,
      formatter: 'replenishOrderAddButton'
    },
    {
      accessor: 'details',
      Header: '',
      minWidth: 50,
      formatter: 'details',
      sortable: false,
      filterable: false,
    },
  ],
  details: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'materialName',
      Header: 'Товар',
      minWidth: 200,
      filterable: false,
      sortable: false,
      formatter: 'productName'
    },
    {
      accessor: 'amount',
      Header: 'Кількість',
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'price',
      Header: 'Ціна',
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'discount',
      Header: 'Знижка',
      minWidth: 150,
      sortable: false,
      edit: true,
      filterable: false,
      formatter: 'discount'
    },
    {
      accessor: 'total',
      Header: 'Сума',
      minWidth: 200,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'employeeFullName',
      Header: 'Відповідальний',
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'details',
      Header: '',
      minWidth: 20,
      formatter: 'detailsOrder',
      sortable: false,
      filterable: false,
    },
  ],
  single: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'materialName',
      Header: 'Товар',
      minWidth: 100,
      filterable: false,
      sortable: false,
      formatter: 'productName'
    },
    {
      accessor: 'amount',
      Header: 'Кількість',
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'price',
      Header: 'Ціна',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'discount',
      Header: 'Знижка',
      minWidth: 100,
      sortable: false,
      edit: true,
      filterable: false,
      formatter: 'discount'
    },
    {
      accessor: 'total',
      Header: 'Сума',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'paymentMethodName',
      Header: 'Оплата',
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'paydeskName',
      Header: 'ФОП',
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'employeeId',
      Header: 'Відповідальний',
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: "orderEmployeeId"
    },
    {
      accessor: 'date',
      Header: 'Дата',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'date'
    },
  ],
};

export default columns;
