const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: "paydeskName",
      Header: "Назва ФОП",
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "cashierName",
      Header: "Касир",
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: "clientName",
      Header: "Клієнт",
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "date",
      Header: "Дата",
      minWidth: 150,
      filterable: false,
      sortable: false,
      formatter: "date",
    },
    {
      accessor: "sum",
      Header: "Сума",
      minWidth: 50,
      sortable: false,
      filterable: false,
      formatter: "currency",
    },
    {
      accessor: "restBalance",
      Header: "Залишок",
      minWidth: 50,
      filterable: false,
      sortable: false,
      formatter: "currency",
    },
    {
      accessor: "paymentMethodName",
      Header: "Спосіб оплати",
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: "transferNumber",
      Header: "Номер перерахунку",
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: "transaction",
      Header: "Транзакція",
      minWidth: 100,
      filterable: false,
      sortable: false,
      formatter: "transactionType",
    },
    {
      accessor: "orderNumber",
      Header: "Номер замовлення",
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
  ],
};

export default columns;
