const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'paydeskName',
      Header: 'Назва ФОП',
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'date',
      Header: 'Дата інкасації',
      minWidth: 200,
      filterable: false,
      sortable: false,
      formatter: 'date',
    },
    {
      accessor: 'cashierFullName',
      Header: 'Віддав',
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'recipientFullName',
      Header: 'Прийняв',
      minWidth: 200,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'encashMoney',
      Header: 'Сума',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'restCashMoney',
      Header: 'Залишок',
      minWidth: 100,
      filterable: false,
      sortable: false,
      formatter: 'currency'
    },
  ],
};

export default columns;
