import { takeEvery, put, call } from "redux-saga/effects";
import { SET_NOTIFICATION } from "../notification";
import { SET_PAGE_COUNT } from "../pagination";
import {
  fetchOrders,
  getOrderById,
  addPayment,
  editOrderSingleEmployee,
  deleteOrderById,
} from "../../http/orders";

const ORDER_LOADING = "order/ORDER_LOADING";
const REPLENISH_BALANCE_MODAL_OPEN = "order/REPLENISH_BALANCE_MODAL_OPEN";
const REPLENISH_BALANCE_MODAL_CLOSE = "order/REPLENISH_BALANCE_MODAL_CLOSE";
const REPLENISH_BALANCE = "order/REPLENISH_BALANCE";
const ORDERS_LOAD = "order/ORDER_LOAD";
const ORDER_GET = "order/ORDER_GET";
const ORDERS_LOAD_SUCCESS = "order/ORDERS_LOAD_SUCCESS";
const ORDERS_LOAD_FAILED = "order/ORDERS_LOAD_FAILED";
const ORDER_LOAD_SUCCESS = "order/ORDER_LOAD_SUCCESS";
const SINGLE_ORDER_MODAL_CLOSE = "order/SINGLE_ORDER_MODAL_CLOSE";
const DELETE_ORDER_MODAL_TOGGLE_VISIBLE =
  "order/DELETE_ORDER_MODAL_TOGGLE_VISIBLE";
const SINGLE_ORDER_MODAL_OPEN = "order/SINGLE_ORDER_MODAL_OPEN";
const ORDER_EDIT = "order/ORDER_EDIT";
const DELETE_ORDER = "order/DELETE_ORDER";
const SET_DATA_FOR_ORDER_EDIT = "order/SET_DATA_FOR_ORDER_EDIT";
const SET_LAST_PAID = "order/SET_LAST_PAID";
const SET_MATERIALS_DATA = "order/SET_MATERIALS_DATA";
const SET_INITIAL_VALUES = "order/SET_INITIAL_VALUES";
const ORDER_DELETE_SUCCESS = "order/ORDER_DELETE_SUCCESS";
const SINGLE_EDIT_SUCCESS = "order/SINGLE_EDIT_SUCCESS";
const NOTES_MODAL_OPEN = "order/NOTES_MODAL_OPEN";
const NOTES_MODAL_CLOSE = "order/NOTES_MODAL_CLOSE";

const initialState = {
  initialValues: {
    material: [
      {
        materialId: "",
        materialName: "",
      },
    ],
  },
  data: [],
  details: [],
  dataForEditSingle: {
    id: null,
    change: false,
  },
  loading: false,
  visible: false,
  notesVisible: false,
  currentId: "",
  deleteModalVisible: false,
  lastPaid: {},
  materialsData: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: initialState.initialValues,
      };
    }
    case SINGLE_EDIT_SUCCESS: {
      return {
        ...state,
        dataForEditSingle: {
          ...state.dataForEditSingle,
          change: false,
        },
      };
    }
    case SET_MATERIALS_DATA: {
      return {
        ...state,
        materialsData: action.data,
        initialValues: {
          material: action.data.length
            ? action.data
            : initialState.initialValues.materials,
        },
      };
    }
    case ORDER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORDERS_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ORDERS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.items,
      };
    }
    case ORDER_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: action.data,
      };
    }
    case REPLENISH_BALANCE_MODAL_OPEN: {
      return {
        ...state,
        visible: true,
        currentId: action.id,
      };
    }
    case REPLENISH_BALANCE_MODAL_CLOSE: {
      return {
        ...state,
        visible: false,
        notesVisible: false,
        currentId: "",
      };
    }
    case NOTES_MODAL_OPEN: {
      return {
        ...state,
        visible: false,
        notesVisible: true,
        currentId: { id: action.id, notes: action.notes },
        currentNotes: action.notes,
      };
    }
    case NOTES_MODAL_CLOSE: {
      return {
        ...state,
        visible: false,
        notesVisible: false,
        currentId: "",
      };
    }
    case SINGLE_ORDER_MODAL_OPEN: {
      return {
        ...state,
        visible: true,
        currentId: action.id,
        dataForEditSingle: {
          ...state.dataForEditSingle,
          employeeId: action.id.employeeId,
        },
        loading: false,
      };
    }
    case SINGLE_ORDER_MODAL_CLOSE: {
      return {
        ...state,
        visible: false,
        currentId: "",
        loading: false,
      };
    }
    case SET_LAST_PAID: {
      return {
        ...state,
        lastPaid: action.data,
      };
    }
    case SET_DATA_FOR_ORDER_EDIT: {
      return {
        ...state,
        dataForEditSingle: {
          ...state.dataForEditSingle,
          ...action.data,
          change:
            action.data.employeeId !== state.currentId.employeeId &&
            action.data.employeeName !== state.currentId.employeeFullName,
        },
        currentId: {
          ...state.currentId,
          ...action.data,
        },
      };
    }
    case DELETE_ORDER_MODAL_TOGGLE_VISIBLE: {
      return {
        ...state,
        deleteModalVisible: action.flag,
      };
    }
    case ORDER_DELETE_SUCCESS: {
      return {
        ...state,
        deleteModalVisible: false,
        loading: false,
      };
    }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getAllOrders = (queries) => ({ type: ORDERS_LOAD, queries });
export const getOrder = (id) => ({ type: ORDER_GET, id });
export const openModal = (id) => ({ type: SINGLE_ORDER_MODAL_OPEN, id });
export const closeModal = () => ({ type: SINGLE_ORDER_MODAL_CLOSE });
export const toggleVisibleDeleteModal = (flag) => ({
  type: DELETE_ORDER_MODAL_TOGGLE_VISIBLE,
  flag,
});
export const openModalReplenishBalance = (id) => ({
  type: REPLENISH_BALANCE_MODAL_OPEN,
  id,
});
export const closeModalReplenishBalance = () => ({
  type: REPLENISH_BALANCE_MODAL_CLOSE,
});
export const openModalNotes = (id, notes) => ({
  type: NOTES_MODAL_OPEN,
  id,
  notes,
});
export const closeModalNotes = () => ({ type: NOTES_MODAL_CLOSE });
export const replenishBalance = (data, pageNumber) => ({
  type: REPLENISH_BALANCE,
  data,
  pageNumber,
});
export const editSingleOrder = (data) => ({ type: ORDER_EDIT, data });
export const setDataForEditSingleOrder = (data) => ({
  type: SET_DATA_FOR_ORDER_EDIT,
  data,
});
export const deleteSingleOrder = (data, goBack) => ({
  type: DELETE_ORDER,
  data,
  goBack,
});
export const setInitialValues = () => ({ type: SET_INITIAL_VALUES });

//<<<WORKERS>>>
function* getOrdersData({ queries = {} }) {
  yield put({ type: ORDER_LOADING });
  try {
    const response = yield call(fetchOrders, queries);
    if (response.status === 200) {
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
      yield put({ type: ORDERS_LOAD_SUCCESS, data: response.data });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: ORDERS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: ORDERS_LOAD_FAILED });
    console.log(error);
  }
}

function* getOrderData({ id }) {
  try {
    yield put({ type: ORDER_LOADING });
    const response = yield call(getOrderById, id);
    if (response.status === 200) {
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
      yield put({ type: SET_LAST_PAID, data: response.data.lastPaidInfoVM });
      yield put({
        type: ORDER_LOAD_SUCCESS,
        data: [...response.data.materials, ...response.data.services],
      });
      yield put({
        type: SET_DATA_FOR_ORDER_EDIT,
        data: {
          change: false,
          orderId: response.data.orderId,
          id: response.data.id,
          orderNumber: response.data.orderNumber,
          notes: response.data.notes,
        },
      });
      const finalMaterialsData = [];
      const materials = new Set();
      response.data.materials.forEach((item) => {
        materials.add(item.materialId);
      });
      const iterator = materials.values();
      for (const entry of iterator) {
        const currentMaterial = response.data.materials.find(
          (item) => item.materialId === entry
        );
        currentMaterial && finalMaterialsData.push(currentMaterial);
      }
      yield put({ type: SET_MATERIALS_DATA, data: finalMaterialsData });
    }
    if (response.status >= 400) {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
      yield put({ type: ORDERS_LOAD_FAILED });
    }
  } catch (error) {
    console.log(error);
  }
}

function* replenishAmount({ data, pageNumber }) {
  try {
    yield put({ type: ORDER_LOADING });
    const response = yield call(addPayment, data);
    if (response.status === 200) {
      yield put({ type: ORDERS_LOAD, queries: { pageNumber } });
      yield put({ type: REPLENISH_BALANCE_MODAL_CLOSE });
    }
    if (response.status >= 400) {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
      yield put({ type: ORDERS_LOAD_FAILED });
    }
  } catch (error) {
    console.log(error);
  }
}

function* editData({ data }) {
  try {
    yield put({ type: ORDER_LOADING });
    const response = yield call(editOrderSingleEmployee, data);
    if (response.status === 200) {
      yield put({ type: SINGLE_ORDER_MODAL_CLOSE });
      yield put({ type: SINGLE_EDIT_SUCCESS });
      yield put({ type: ORDER_GET, id: data.id });
    }
    if (response.status >= 400) {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
      yield put({ type: ORDERS_LOAD_FAILED });
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteData({ data, goBack }) {
  try {
    yield put({ type: ORDER_LOADING });
    const response = yield call(deleteOrderById, data);
    if (response.status === 200 || response.status === 204) {
      yield put({ type: ORDERS_LOAD });
      yield put({ type: ORDER_DELETE_SUCCESS });
      goBack();
    }
    if (response.status >= 400) {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
      yield put({ type: ORDERS_LOAD_FAILED });
    }
  } catch (error) {
    console.log(error);
  }
}

//<<<WATCHERS>>>
export function* watchOrders() {
  yield takeEvery(ORDERS_LOAD, getOrdersData);
  yield takeEvery(ORDER_GET, getOrderData);
  yield takeEvery(REPLENISH_BALANCE, replenishAmount);
  yield takeEvery(ORDER_EDIT, editData);
  yield takeEvery(DELETE_ORDER, deleteData);
}
