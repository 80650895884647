import React from 'react';
import CashierSelectForOrder from '../../components/GeneralFields/Select/Odrer/CashierSelectForOrder';


export const orderEmployee = ({id, data, changeData, currentProduct, dataForCreateOrder, handleChange}) => {
  const onChange = valueData => {
    if(!valueData) return null
    handleChange({id, name:"employeeId", data, value : valueData.value, dataForCreateOrder, currentProduct });
  };

  return (
    <div
      suppressContentEditableWarning
    >
      <CashierSelectForOrder value={changeData} onChange={onChange} />
    </div>
  );
}