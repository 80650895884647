import SelectReact from '..';
import { connect } from 'react-redux';
import { getServices } from '../../../../redux/services';
import { generateSelectValue } from '../../../../helpers';

class ServicesSelect extends SelectReact {

  componentDidMount = () => this.props.getServices();

  label = ''
  name = 'serviceId'
  maxHeight = 250

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.name === this.props.value)
    return currentOption ? {value: currentOption.id, label: generateSelectValue({ value: currentOption.name})} : null
  }

  renderOptions = () => this.props.data.map(({ id, name }) => ({ value: name, label: generateSelectValue({ value: name }) }));

}

const mapStateToProps = state => ({
  data: state.services.allData,
});

const mapDispatchToProps = {
  getServices
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesSelect);
