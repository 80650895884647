import SelectReact from '..';
import { connect } from 'react-redux';
import { generateSelectValue } from '../../../../helpers';

class PaymentMethodsSelect extends SelectReact {

  // componentDidMount() {
  //   this.props.getData();
  // }

  // handleChange = () => null

  label = ''
  name = 'paymentMethodId'
  maxHeight = 150
  className = 'payment-methods'

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.id === this.props.value)
    return currentOption ? {value: currentOption.id, label: generateSelectValue({ value: currentOption.name })} : null
  }

  renderOptions = () => this.props.data.map(({ id, name }) => ({ value: id, label: generateSelectValue({ value: name }) }));

}

const mapStateToProps = state => ({
  data: state.createOrder.orderData.paymentMethods,
});

export default connect(mapStateToProps)(PaymentMethodsSelect);
