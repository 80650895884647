import { Modal } from 'antd';
// import 'antd/dist/antd.css';
const { confirm } = Modal;

const confirmationModal = ( title, onOk , okText ) => {
  return (
      confirm({
        title: title,
        okText: okText,
        okType: 'danger',
        cancelText: 'Ні',
        onOk : () => {onOk()}
      })
  );
};

export default confirmationModal;