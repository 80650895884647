import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Button } from "antd";

import {
  openModal,
  closeModal,
  getAllClients,
  blockUser,
  unlockUser,
  createNewClient,
  setInitialData,
  replenishBalance,
  setQueriesData,
  setCurrentClient,
} from "../../redux/clients";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import Modal from "../../components/Modal";
import ClientForm from "./ClientFrom";
import ReplenishBalance from "../../components/ReplenishBalance";
import ClientsSelect from "../../components/GeneralFields/Select/ClientsSelect";
import ClientsStatusSelect from "../../components/GeneralFields/Select/ClientsStatusSelect";
import SearchField from "../../components/GeneralFields/SearchField";
import SortField from "../../components/GeneralFields/SortField";
import { setPageNumber } from "../../redux/pagination";
import { roleNames } from "../../content/pages";

const constants = {
  clientFilterBalance: "Balance",
  clientFilterClientId: "clientId",
  clientFilterIsPaid: "IsPaid",
  clientFilterClientNumber: "clientNumber",
};

class Clients extends GeneralPage {
  class = "clients";
  pageTitle = "Клієнти";
  columns = columns.general;
  state = {
    clientId: undefined,
    sort: {},
  };

  componentDidMount = () => {
    const { getData, setQueriesData } = this.props;
    const query = queryString.parse(this.props.location.search);

    let sort = {};
    for (let property in query) {
      if (property === constants.clientFilterBalance) {
        sort[property] = query[property];
      }
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        setQueriesData(this.state);
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    const { createNewClient, userRole } = this.props;
    return (
      <>
        {userRole === roleNames.superAdmin && (
            <div className="btn-add">
              <Button onClick={createNewClient}>+</Button>
            </div>
        )
        }
        <SearchField
          defaultValue={query[constants.clientFilterClientNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.clientFilterClientNumber
            )
          }
        />
        <ClientsSelect
          value={query[constants.clientFilterClientId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
          // menuIsOpen={true}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.clientFilterBalance}
          value={query[constants.clientFilterBalance]}
        />
        <ClientsStatusSelect
          value={query[constants.clientFilterIsPaid]}
          getSelectedValue={this.getFilterValue}
        />
      </>
    );
  };

  additionalComponentsTops = () => {
    return (
<>
  <div className="cash-register-history__paydesk-balance default-btn">
    <h2 className="page-title">
      {this.pageTitle} <span>{this.subTittle}</span>
    </h2>
  </div>
</>

    );
  };

  additionalComponents = () => {
    const { visible, closeModal, currentId } = this.props;
    return (
      <>
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="logs-modal"
            width={583}
          >
            {currentId ? <ReplenishBalance /> : <ClientForm />}
          </Modal>
        )}
      </>
    );
  };
}

const mapStateToProps = ({ pagination, clients, login }) => ({
  ...clients,
  ...pagination,
  userRole: login.user.role,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  createNewClient,
  getData: getAllClients,
  blockItem: blockUser,
  unblockItem: unlockUser,
  setInitialData,
  replenishBalance,
  setPageNumber,
  setQueriesData,
  setCurrentClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
