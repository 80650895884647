import http from "../index";
import { generateSortParams } from "../../helpers";
import { tablePageSize } from "../../helpers/constants";

export function fetchClients({
  clientId = "",
  IsPaid = "",
  sort = "",
  clientNumber = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const clientIdQuery = clientId && `ClientId=${clientId}`;
  const statusQuery = `&IsPaid=${IsPaid}`;
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const clientNumberQuery = clientNumber && `&ClientNumber=${clientNumber}`;
  const pageNumberQuery = pageNumber && `&PageNumber=${pageNumber}`;
  const queries = [
    clientIdQuery,
    statusQuery,
    clientNumberQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");
  const sortUrl = generateSortParams(sort);

  return http
    .get(`/Clients/grid?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function getOrderByClientId({
  number = "",
  paymentMethodId = "",
  clientId = "",
  employeeId = "",
  paydeskId = "",
  orderDateStart = "",
  sort = "",
  orderDateEnd = "",
  paidDateStart = "",
  paidDateEnd = "",
  pageSize = 10,
  pageNumber = 1,
}) {
  const clientIdQuery = clientId && `&clientId=${clientId}`;
  const numberQuery = number && `&number=${number}`;
  const paymentMethodIddQuery =
    paymentMethodId && `&paymentMethodId=${paymentMethodId}`;
  const paydeskIdQuery = paydeskId && `&paydeskId=${paydeskId}`;
  const orderDateStartQuery =
    orderDateStart && `&orderDateStart=${orderDateStart}`;
  const employeeIdQuery = employeeId && `&employeeId=${employeeId}`;
  const orderDateEndQuery = orderDateEnd && `&orderDateEnd=${orderDateEnd}`;
  const paidDateStartQuery = paidDateStart && `&paidDateStart=${paidDateStart}`;
  const paidDateEndQuery = paidDateEnd && `&paidDateEnd=${paidDateEnd}`;
  const pageSizeQuery = pageSize && `&pageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const queries = [
    clientIdQuery,
    orderDateStartQuery,
    numberQuery,
    paymentMethodIddQuery,
    employeeIdQuery,
    paidDateEndQuery,
    paydeskIdQuery,
    orderDateEndQuery,
    paidDateStartQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);

  return http
    .get(`/OrdersHistory/gridByClient?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function getPaidHistory(id) {
  return http
    .get(`/OrdersHistory/paidHistoryDetails/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllClients() {
  return http
    .get(`/Clients/getAll`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function editClientById(id, data) {
  return http
    .put(`/Clients/${id}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function addNewClient(data) {
  return http
    .post("/Clients", data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function deleteClient(id) {
  return http
    .delete(`/Clients/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function restoreClient(id) {
  return http
    .put(`/Clients/restore/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function getMethodsClient() {
  return http
    .get(`/Clients/balance`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function replenishBalanceClient(data) {
  return http
    .put(`/Clients/balance`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function getBalanceClient() {
  return http
    .put(`/Clients/balance`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllClientForPdf(url,name,clientId) {
  return http
      .get(`/OrdersHistory/${name}?&clientId=${clientId}${url}&PageSize=999&`)
      .then((response) => response)
      .catch((error) => error.response);
}
