import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import Form from '../../../components/Form';

import { createMaterial, editMaterial } from '../../../redux/materials';

import {
  NumberMaskField,
  TextField,
} from '../../../components/Form/Inputs';
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
} from "../../../components/Form/validation";
import { withRouter } from 'react-router-dom';
import AllParameterSelect from "../../../components/GeneralFields/Select/AllParameterSelect";

const texts = {
  number: 'ID матеріалу',
  name: 'Назва матеріалу',
  btnText: "Створити",
  parameter: "Параметр",
  inputPrice: "Вхідна ціна",
  outputPrice: "Вихідна ціна",
  editText: "Зберегти"
}

class CreateServiceForm extends Form {
  title = this.props.values.id ? "Редагувати матеріал" : "Додати новий матеріал"
  onchange = null
  state = {
    name: '',
    unitTypeId: ''
  }

  componentDidMount() {
    this.setState({unitTypeId: this.props.values.unitTypeId})
  }

  onValidate = makeFormValidator({
    number: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: value => value ? value.length < 3 : false,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
    name: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minLength(3)]: value => value ? value.length < 3 : false,
      [validationTexts.maxLength(50)]: value => value ? value.length >= 50 : false,
    }),
    inputPrice: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: value => value ? value <= 0 : false,
    }),
    parameter: composeValidators({
      [validationTexts.required]: value => value === undefined || value?.toString().trim().length === 0,
      [validationTexts.minLength(3)]: value => value ? value.length < 3 : false,
    }),
    outputPrice: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: value => value ? value <= 0 : false,
    }),
  });


  onSubmit = props => {
    const { id } = props;
    const { queriesData } = this.props;
    const parameter = props.parameter.value ? props.parameter.value : props.parameter
    const modifiedProps = {...props, parameter, unitTypeId: props.parameter.unitTypeId ? props.parameter.unitTypeId : this.state.unitTypeId}
    id ?
        this.props.editMaterial(id, modifiedProps, this.props.pageNumber, queriesData) :
        this.props.createMaterial(modifiedProps, this.props.pageNumber, queriesData);
  }

  renderFields = () => {
    const { id } = this.props.values;
    return (
      <div className="c-form__item">
        <Field
          className="c-form__field"
          name="number"
          component={TextField}
          label={texts.number}
          required
        />
        <Field
          className="c-form__field"
          name="name"
          component={TextField}
          label={texts.name}
          required
        />
        <Field
          component={AllParameterSelect}
          className="c-form__field"
          name="parameter"
          label={texts.parameter}
          selectValueSize={45}
          required
        />
        <Field
          className="c-form__field"
          name="inputPrice"
          label={texts.inputPrice}
          component={NumberMaskField}
          required
        />
         <Field
          className="c-form__field"
          name="outputPrice"
          label={texts.outputPrice}
          component={NumberMaskField}
          required
        />
        <div className="c-form__submit">
          <button className="c-button" type="submit">{id ? texts.editText : texts.btnText}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  values: state.materials.initialValues,
  pageNumber: state.pagination.pageNumber,
  queriesData: state.materials.queriesData,
  notification: state.notification,
  parametersData: state.materials.parametersData
});

const mapDispatchToProps = {
  createMaterial,
  editMaterial
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateServiceForm));
