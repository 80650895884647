import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import Form from '../../../components/Form';
import { OnChange } from 'react-final-form-listeners';
import { withRouter } from 'react-router-dom';

import { addProductToOrder, initialMaterialValues, setMaterialForCreateOrder,
         setOrderPrice, setCurrentMaterial
        } from '../../../redux/createOrder';

import {
  CashierReactSelect,
  NumberMaskField,
  MaterialReactSelect
} from '../../../components/Form/Inputs';
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue
} from "../../../components/Form/validation";

const texts = {
  service: 'Послуга',
  number: 'Кількість',
  price: 'Ціна',
  total: 'Сума',
  btnText: '+ Додати',
  serviceAndMaterial: "Послуга + Матеріал",
  material: "Матеріал",
  employeeId: "Відповідальний"
}

class AddMaterialForm extends Form {
  title = ""
  onchange = null

  onValidate = makeFormValidator({
    materialId: composeValidators({
      [validationTexts.required]: isEmptyValue
    }),
    total: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: value => value ? value <= 0 : false,
    }),
    price: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: value => value ? value <= 0 : false,
    }),
    amount: composeValidators({
      [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(1)]: value => value ? value < 1: false,
    }),
    employeeId: composeValidators({
      [validationTexts.required]: isEmptyValue
    }),
  });

  onSubmit = props => {
    const { amount, total, employeeId } = props;
    const { setMaterialForCreateOrder, addProductToOrder, orderPrice, setOrderPrice, currentMaterial: { outputPrice } } = this.props;
    const updateDiscount = Number.parseFloat(total - amount * outputPrice).toFixed(2);
    const finalData = {
      ...props,
      amount: Number(amount),
      employeeId: employeeId.value ? employeeId.value : employeeId,
      discount: Number(updateDiscount),
      id: Math.random().toString(36).substr(2, 9)
    }
    const finalPrice = orderPrice === "" ? Number(total) : Number(orderPrice) + Number(total);
    setOrderPrice(Number.parseFloat(finalPrice).toFixed(2));
    setMaterialForCreateOrder(finalData);
    addProductToOrder(finalData);
  }

  handleChangeMaterial = ({value}) => {
    if (!value) return false
    const { materialsData, initialMaterialValues, setCurrentMaterial } = this.props;
    const currentData = materialsData.find(item => item.id === value);
    setCurrentMaterial(currentData);
    currentData && initialMaterialValues({
      materialId: currentData.id,
      materialName: currentData.name,
      price: currentData.outputPrice
    });
  }

  handleChangeAmount = value => {
    const { values: { price }, initialMaterialValues } = this.props;
    if (!price) {
      return initialMaterialValues({ amount: value })
    }
    const data = {
      amount: value,
      total: Number.parseFloat(price * value).toFixed(2)
    };
    initialMaterialValues(data);
  }

  handleChangePrice = value => {
    const { values: { amount }, initialMaterialValues, currentMaterial: { outputPrice } } = this.props;
    if (!amount && !outputPrice) {
      return initialMaterialValues({ price: value })
    }
    const updateTotal = Number.parseFloat(amount * value).toFixed(2);
    const currentTotal = Number.parseFloat(amount * outputPrice).toFixed(2);
    const data = {
      price: value,
      total: updateTotal,
      discount: Number.parseFloat(updateTotal - currentTotal).toFixed(2)
    };
    initialMaterialValues(data)
  }

  handleChangeTotal = value => this.props.initialMaterialValues({  total: value });

  handleChangeEmployee = data => {
    if (!data) return false
    const { initialMaterialValues } = this.props;
    initialMaterialValues({
      employeeId: data.value,
      employeeFullName: data.label
    });
  }

  renderFields = () => (
    <>
      <div className="c-form__item order-form__item">
        <FormSpy render={({ form: { change } }) => {
          this.onchange = change
          return (
            <>
              <Field
                className="c-form__field"
                spanClassName="order-form__product"
                name="materialId"
                component={MaterialReactSelect}

                label={texts.material}
                onChange={this.handleChangeMaterial}
                selectValueSize={45}
                required
              />
              <Field
                className="c-form__field"
                name="amount"
                component={NumberMaskField}
                label={texts.number}
                required
              />
              <OnChange name="amount">
                {value => (
                  <>
                    {change('amount', value)}
                    {this.handleChangeAmount(value)}
                  </>
                )}
              </OnChange>
              <Field
                className="c-form__field"
                name="price"
                component={NumberMaskField}
                label={texts.price}
                required
              />
              <OnChange name="price">
                {value => (
                  <>
                    {change('price', value)}
                    {this.handleChangePrice(value)}
                  </>
                )}
              </OnChange>
              <Field
                className="c-form__field"
                name="total"
                component={NumberMaskField}
                label={texts.total}
                required
              />
              <OnChange name="total">
                {value => (
                  <>
                    {change('total', value)}
                    {this.handleChangeTotal(value)}
                  </>
                )}
              </OnChange>
              <Field
                className="c-form__field order-form__employee"
                name="employeeId"
                label={texts.employeeId}
                component={CashierReactSelect}
                onChange={this.handleChangeEmployee}
                required
              />
              <div className="c-form__add order-form__submit">
                <button className="c-button" type="submit">{texts.btnText}</button>
              </div>
            </>
          )
        }} />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  materialsData: state.createOrder.orderData.materials,
  values: state.createOrder.initialValues.material,
  orderPrice: state.createOrder.dataForCreateOrder.orderPrice,
  currentMaterial: state.createOrder.currentMaterial
});

const mapDispatchToProps = {
  addProductToOrder,
  initialMaterialValues,
  setMaterialForCreateOrder,
  setOrderPrice,
  setCurrentMaterial
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddMaterialForm));
