import React, { Component } from "react";
import { ChevronIcon } from "../../../icons";

const defaultButton = (props) => <button {...props}>{props.children}</button>;
const navButton = (props) => (
  <button {...props}>
    <ChevronIcon />
  </button>
);

export default class Pagination extends Component {
  state = {
    visiblePages: [],
  };

  componentDidMount() {
    this.setState({
      visiblePages: this.getVisiblePages(
        this.props.pageNumber,
        this.props.pages
      ),
    });
  }

  filterPages = (visiblePages, totalPages) => {
    totalPages = totalPages === 0 ? 1 : totalPages;

    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage = (page) => {
    const { pages, onPaginationPageChange, onPageChange, pageNumber } =
      this.props;
    if (page !== pageNumber) {
      const visiblePages = this.getVisiblePages(pageNumber, pages);
      this.setState({
        visiblePages: this.filterPages(visiblePages, pages),
      });
      onPaginationPageChange(page);
      onPageChange(page - 1);
    }
  };

  render() {
    const {
      PageButtonComponent = defaultButton,
      NavButtonComponent = navButton,
      pages,
      pageNumber: activePage,
      nextText,
      previousText,
    } = this.props;
    const { visiblePages } = this.state;
    if (pages <= 1) {
      return false;
    }
    return (
      <div className="table__pagination">
        <div className="table__pagination-prevPageWrapper">
          <NavButtonComponent
            className="table__pagination-navButton table__pagination-navButton-prev"
            onClick={() => this.changePage(activePage - 1)}
            disabled={activePage === 1}
          >
            {previousText}
          </NavButtonComponent>
        </div>
        <div className="table__pagination-visiblePagesWrapper">
          {visiblePages.map((page, index, array) => {
            return (
              <PageButtonComponent
                key={page}
                className={
                  activePage === page
                    ? "table__pagination-pageButton table__pagination-pageButton--active"
                    : "table__pagination-pageButton"
                }
                onClick={() => this.changePage(page)}
              >
                {array[index - 1] + 1 < page ? `... ${page}` : page}
              </PageButtonComponent>
            );
          })}
        </div>
        <div className="table__pagination-nextPageWrapper">
          <NavButtonComponent
            className="table__pagination-navButton table__pagination-navButton-next"
            onClick={() => {
              if (activePage === pages) return;
              this.changePage(activePage + 1);
            }}
            disabled={activePage === pages || pages === 0}
          >
            {nextText}
          </NavButtonComponent>
        </div>
      </div>
    );
  }
}
