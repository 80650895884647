import { takeEvery, put, call } from 'redux-saga/effects';
import { SET_NOTIFICATION } from '../notification';
import { SET_PAGE_COUNT } from '../pagination';

import { fetchBalances } from '../../http/historyBalances';

const BALANCES_HISTORY_LOAD = 'balancesHistory/BALANCES_HISTORY_LOAD';
const BALANCES_HISTORY_LOADING = 'balancesHistory/BALANCES_HISTORY_LOADING';
const BALANCES_HISTORY_FAILED = 'balancesHistory/BALANCES_HISTORY_FAILED';
const BALANCES_HISTORY_LOAD_SUCCESS = 'balancesHistory/BALANCES_HISTORY_LOAD_SUCCESS'

const initialState = {
  data: [],
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BALANCES_HISTORY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case BALANCES_HISTORY_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case BALANCES_HISTORY_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.items
      };
    }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getAllHistoryBalances = queries => ({ type: BALANCES_HISTORY_LOAD, queries });

//<<<WORKERS>>>
function* getAllData({queries = {}}) {
  yield put({ type: BALANCES_HISTORY_LOADING })
  try {
    const response = yield call(fetchBalances, queries);
    if (response.status === 200) {
      yield put({ type: BALANCES_HISTORY_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
  } catch (error) {
    yield put({ type: BALANCES_HISTORY_FAILED })
    console.log(error)
  }
};

//<<<WATCHERS>>>
export function* watchBalancesHistory() {
  yield takeEvery(BALANCES_HISTORY_LOAD, getAllData);
}