import SelectReact from '..';
import { connect } from 'react-redux';
import { generateSelectValue } from '../../../../helpers';

class ModalEntrepreneursSelect extends SelectReact {

    label = this.props.label ? this.props.label : ''
    name = this.props.name ? this.props.name : 'entrepreneurId'
    maxHeight = 250


    getInitialValue = value =>  {
        const currentOption = this.props.data &&  this.props.data.find(item => item.id === this.props.value)
        return currentOption ? {value: currentOption.id, label: generateSelectValue({ value: currentOption.name }) } : null
    }

    renderOptions = () => this.props.data.map(({ id, name }) => ({ value: id, label: generateSelectValue({ value: name }) }));

}

const mapStateToProps = state => ({
    data: state.createOrder.orderData.paydesks,
});



export default connect(mapStateToProps)(ModalEntrepreneursSelect);
