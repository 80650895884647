import React from 'react';

export const orderEmployeeName = (employeeFirstName, employeeLastName) => {
  if (!employeeFirstName &&  !employeeLastName) {
    return "-"
  } else {
    return (
      <div className="employee-name">
        <p>{employeeFirstName} {employeeLastName}</p>
      </div>
    )
  }
}  
