import SelectReact from '..';

class ClientsStatusSelect extends SelectReact {


  // handleChange = () => null

  label = ''
  name = 'IsPaid'
  maxHeight = 150;
  className='client-status'

  options = [
    {
      id: 1,
      value: "true",
      name: 'оплачено'
    },
    {
      id: 2,
      value: "false",
      name: 'не оплачено'
    }
  ]

  getInitialValue = value =>  {
    const currentOption = this.options &&  this.options.find(item => item.value === this.props.value)
    return currentOption ? {value: currentOption.value, label: currentOption.name} : null
  }

  renderOptions = () => this.options.map(({ id, name, value }) => ({ value, label: name }));

}

export default ClientsStatusSelect;
