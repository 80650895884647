import SelectReact from '../index.js';
import { connect } from 'react-redux';
import { getDataForCreateOrder } from '../../../../../redux/createOrder';
import { generateSelectValue } from '../../../../../helpers/index.js';

class ServiceSelect extends SelectReact {

  label = ''
  name = 'serviceId'
  maxHeight = 150
  spanClassName = 'service-select'

  componentDidMount = () => !this.props.data.length && this.props.getDataForCreateOrder();

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.id === value)
    return currentOption ? {value: currentOption.id, label: generateSelectValue({ value: currentOption.name, size: this.props.selectValueSize })} : null
  }

  renderOptions = () => this.props.data.map(({ id, name }) => ({ value: id, label: generateSelectValue({ value: name, size: this.props.selectValueSize }) }));

}

const mapStateToProps = state => ({
  data: state.createOrder.orderData.services,
});

const mapDispatchToProps = {
  getDataForCreateOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelect);
