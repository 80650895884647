import React, { Component } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import {
  deleteFormatter,
  discountFormatter,
  nameFormatter,
  blockUnblockFormatter,
  editFormatter,
  serviceActionFormatter,
  clientStatusFormatter,
  allOrdersFormatter,
  detailsOrderFormatter,
  detailsFormatter,
  replenishBalance,
  balanceFormatter,
  productOrderFormatter,
  orderEmployee,
  orderFormatter,
  dateFormatter,
  clientPaymentFormatter,
  orderStatusFormatter,
  productFormatter,
  defaultFormatter,
  orderReplenishBalance,
  orderSingleEmployee,
  serviceStatusFormatter,
  orderEmployeeName,
  collectionActionFormatter,
  deleteComingFormatter,
  editComingFormatter,
  orderLinkFormatter,
  orderHistoryLinkFormatter,
  orderDebtFormatter,
  transactionsFormatter,
  discountCreateOrderFormatter,
  currencyFormatter,
  productNameFormatter,
  productTypeFormatter,
} from "../../helpers/formatters";
import Pagination from "./pagination";
import "react-table/react-table.css";
import {replenishOrderAddButton} from "../../helpers/formatters/replenishOrderAddButton";
const text = {
  noData: "Нічого не знайдено",
  loading: "Завантаження...",
  title: "Фільтрувати",
};

const maxPageSize = 20;
const minRows = 1;

class MainTable extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    this.updateColumns(this.props.columns);
  }

  updateColumns = (columns) => {
    const updated = columns.map((column) => {
      const header = column.Header;

      column = {
        ...column,
        Header: () => <div className="rt-th-cell">{header}</div>,
      };

      column = {
        ...column,
        Cell: (rowData) => {
          const {
            column: { formatter },
            value = null,
            original: {
              id,
              materialId,
              serviceId,
              clientFirstName,
              clientLastName,
              isDeleted,
              employeeFirstName,
              employeeLastName,
              orderId,
              orderNumber,
              debt,
              isDefault,
              amountFocus,
              totalFocus,
              priceFocus,
              isMaterial,
              notes,
            },
            original,
          } = rowData;
          const {
            match,
            openModal,
            openNotes,
            blockItem,
            unblockItem,
            setInitialData,
            deleteItem,
            data,
            handleChange,
            orderData,
            dataForCreateOrder,
            editData,
            pageNumber,
            userRole,
            lastPaid,
            historyId,
            queriesData,
            setCurrentClient,
          } = this.props;
          const currentProduct = original.serviceId ? "service" : "material";
          const currentProductId = original.serviceId
            ? original.serviceId
            : original.materialId;
          const currentProductName = original.serviceName
            ? original.serviceName
            : original.materialName;
          switch (formatter) {
            case "productName":
              return productNameFormatter(currentProductName);
            case "currency":
              return currencyFormatter(value);
            case "discountCreateOrder":
              return discountCreateOrderFormatter(value);
            case "transactionType":
              return transactionsFormatter(value);
            case "orderLinkName":
              return orderLinkFormatter(id, orderNumber);
            case "serviceStatus":
              return serviceStatusFormatter(value);
            case "orderStatus":
              return orderStatusFormatter(value);
            case "orderEmployeeId":
              return orderSingleEmployee({
                id: historyId,
                value,
                currentProductId,
                currentProduct,
                editData,
              });
            case "product":
              return productFormatter(materialId, serviceId, orderData);
            case "collectionAction":
              return collectionActionFormatter(id, openModal, isDeleted);
            case "clientPaymentStatus":
              return clientPaymentFormatter(value);
            case "date":
              return dateFormatter(value);
            case "editComing":
              return editComingFormatter(original, setInitialData, userRole);
            case "employeeIdOrder":
              return orderEmployee({
                id,
                data,
                changeData: value,
                currentProduct,
                dataForCreateOrder,
                handleChange,
              });
            case "productOrder":
              return productOrderFormatter(
                currentProduct,
                currentProductId,
                orderData
              );
            case "editOrderTotal":
              return orderFormatter(
                id,
                value,
                "total",
                data,
                orderData,
                currentProduct,
                currentProductId,
                dataForCreateOrder,
                handleChange,
                totalFocus
              );
            case "editOrderPrice":
              return orderFormatter(
                id,
                value,
                "price",
                data,
                orderData,
                currentProduct,
                currentProductId,
                dataForCreateOrder,
                handleChange,
                priceFocus
              );
            case "editOrderAmount":
              return orderFormatter(
                id,
                value,
                "amount",
                data,
                orderData,
                currentProduct,
                currentProductId,
                dataForCreateOrder,
                handleChange,
                amountFocus
              );
            case "discount":
              return discountFormatter(value);
            case "replenishBalance":
              return replenishBalance(openModal, id, isDeleted, isDefault);
            case "orderEmployeeName":
              return orderEmployeeName(employeeFirstName, employeeLastName);
            case "replenishOrder":
              return orderReplenishBalance(
                clientFirstName,
                clientLastName,
                openModal,
                orderId,
                debt
              );
            case "replenishOrderAddButton":
              return replenishOrderAddButton(
                  clientFirstName,
                  clientLastName,
                  openModal,
                  orderId,
                  debt
              );
            case "detailsOrder":
              return detailsOrderFormatter(
                original,
                openModal,
                userRole,
                lastPaid
              );
            case "deleteItem":
              return deleteFormatter(
                id,
                data,
                currentProduct,
                currentProductId,
                dataForCreateOrder,
                deleteItem
              );
            case "productType":
              return productTypeFormatter(isMaterial);
            case "allOrders":
              return allOrdersFormatter({
                id,
                match,
                original,
                setCurrentClient,
              });
            case "clientStatus":
              return clientStatusFormatter(value, column.accessor);
            case "serviceAction":
              return serviceActionFormatter();
            case "blockUnblock":
              return blockUnblockFormatter(
                id,
                value,
                blockItem,
                unblockItem,
                pageNumber,
                queriesData,
                userRole,
                isDefault
              );
            case "deleteComing":
              return deleteComingFormatter(
                id,
                value,
                blockItem,
                pageNumber,
                queriesData,
                userRole
              );
            case "name":
              return nameFormatter(
                id,
                original.firstName,
                original.lastName,
                isDeleted
              );
            case "edit":
              return editFormatter(
                original,
                setInitialData,
                userRole,
                isDefault
              );
            case "balance":
              return balanceFormatter(value);
            case "details":
              return detailsFormatter({ id, notes, openNotes });
            case "orderHistoryLink":
              return orderHistoryLinkFormatter({ id, match, value });
            case "orderDebt":
              return orderDebtFormatter(value);
            default:
              return defaultFormatter(value);
          }
        },
      };
      return column;
    });

    this.setState((prevState) => ({
      ...prevState,
      columns: updated,
    }));
  };

  render() {
    const {
      data,
      loading,
      className = "",
      subColumns,
      onPaginationPageChange,
      filters,
      pageCount,
      pageSize,
      pageNumber,
    } = this.props;
    let { columns } = this.state;
    return (
      <div className="table-wrap">
        <div className="table__filter">{filters}</div>
        <ReactTable
          key={uniqueId("table_")}
          className={`table ${className}`}
          data={data}
          columns={columns}
          loading={loading}
          ref={(table) => (this.table = table)}
          filterable={true}
          PaginationComponent={Pagination}
          onPaginationPageChange={onPaginationPageChange}
          SubComponent={(row) => {
            if (row.original.materials) {
              return (
                <div>
                  <ReactTable
                    data={row.original.materials.sort((a,b) => a.name.localeCompare(b.name)) || []}
                    columns={subColumns}
                    pageSize={row.original.materials.length}
                    showPagination={false}
                    className="sub-table"
                  />
                </div>
              );
            } else {
              return false;
            }
          }}
          pages={pageCount}
          page={0}
          pageNumber={pageNumber}
          pageSize={pageSize}
          defaultPageSize={maxPageSize}
          minRows={minRows}
          resizable={false}
          noDataText={text.noData}
          loadingText={text.loading}
        />
      </div>
    );
  }
}

MainTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  filter: PropTypes.bool,
};

export default MainTable;
