import SelectReact from "..";
import { connect } from "react-redux";
import { getAllCashiers } from "../../../../redux/employee";
import { generateSelectValue } from "../../../../helpers";

class СashierSelect extends SelectReact {
  state = {};
  label = "";
  name = this.props.name ? this.props.name : "cashierId";
  maxHeight = 250;

  componentDidMount = () => this.props.getAllCashiers();

  getInitialValue = (value) => {
    const currentOption =
      this.props.data &&
      this.props.data.find((item) => item.id === this.props.value);
    return currentOption
      ? {
          value: currentOption.value,
          label: generateSelectValue({
            value: currentOption.fullName,
            size: this.props.selectValueSize,
          }),
        }
      : null;
  };

  renderOptions = () =>
    this.props.data.map(({ id, fullName }) => ({
      value: id,
      label: generateSelectValue({
        value: fullName,
        size: this.props.selectValueSize,
      }),
    }));
}

const mapStateToProps = (state) => ({
  data: state.employees.allCashiers,
});

const mapDispatchToProps = {
  getAllCashiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(СashierSelect);
