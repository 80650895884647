import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Button } from "antd";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import { exportPDF } from "../../components/ExportToPdf";
import {
  openModal,
  closeModal,
  createNewMaterial,
  getAllMaterials,
  blockMaterial,
  unlockMaterial,
  setInitialData,
  setQueriesData,
} from "../../redux/materials";
import { setPageNumber } from "../../redux/pagination";
import Modal from "../../components/Modal";
import MaterialForm from "./MaterialForm";
import AllMaterialSelect from "../../components/GeneralFields/Select/AllMaterialSelect";
import SearchField from "../../components/GeneralFields/SearchField";
import { roleNames } from "../../roles";
import SortField from "../../components/GeneralFields/SortField";
import {fetchAllMaterialsForPdf, fetchMaterials} from "../../http/materials";

const constants = {
  materialFilterNumber: "number",
  materialFilterMaterialId: "materialId",
  materialFilterAmount: "Amount",
  materialFilterParameter: "parameter",
  materialFilterInputPrice: "inputPrice",
  materialFilterOutputPrice: "outputPrice",
  btnPrint: "Друк",
};

const dataHeaders = [
  [
    "ID МАТЕРІАЛУ",
    "НАЗВА МАТЕРІАЛУ",
    "КІЛЬКІСТЬ",
    "ПАРАМЕТР",
    "ВХІДНА ЦІНА",
    "ВИХІДНА ЦІНА",
  ],
];

class Materials extends GeneralPage {
  pageTitle = "Матеріали";
  class = "materials";
  columns = columns.general;

  state = {
    name: undefined,
    sort: {},
  };

  componentDidMount = () => {
    const { getData, setQueriesData } = this.props;
    const query = queryString.parse(this.props.location.search);
    let sort = {};
    for (let property in query) {
      if (property === constants.materialFilterAmount) {
        sort[property] = query[property];
      }
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        setQueriesData(this.state);
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  getSortValue = (option, name) => {
    const {
      history: {
        push,
        location: { pathname },
      },
      getData,
      setPageNumber,
    } = this.props;
    const value = option ? option.value : undefined;
    const query = queryString.parse(this.props.location.search);
    query[name] = value;
    let newQuery;
    if (value === "") {
      delete query[name];
      newQuery = queryString.stringify(query);
    } else {
      newQuery = queryString.stringify(query);
    }
    push(`${pathname}?${newQuery}`);
    this.setState(
      (prevState) => ({
        ...prevState,
        sort: {
          ...query,
        },
      }),
      () => {
        getData({ pageNumber: 1, ...this.state });
        setQueriesData({ pageNumber: 1, ...this.state });
        setPageNumber(constants.firstPage);
      }
    );
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    const {
      createNewMaterial,
      userRole,
    } = this.props;
    return (
      <>
        {userRole === roleNames.superAdmin && (
            <div className="btn-add">
              <Button onClick={createNewMaterial}>+</Button>
            </div>
        )
        }
        <SearchField
          defaultValue={query[constants.materialFilterNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.materialFilterNumber
            )
          }
        />
        <AllMaterialSelect
          selectValueSize={20}
          value={query[constants.materialFilterMaterialId]}
          getSelectedValue={this.getFilterValue}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.materialFilterAmount}
          value={query[constants.materialFilterAmount]}
        />
        <SearchField
          defaultValue={query[constants.materialFilterParameter]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.materialFilterParameter
            )
          }
        />
        <SearchField
          type="number"
          defaultValue={query[constants.materialFilterInputPrice]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.materialFilterInputPrice
            )
          }
        />
        <SearchField
          type="number"
          defaultValue={query[constants.materialFilterOutputPrice]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.materialFilterOutputPrice
            )
          }
        />
      </>
    );
  };

  additionalComponentsTops = () => {
    const {
      visible,
      closeModal,
      allData,
      filteredData
    } = this.props;
    return (
        <>
          <div className="cash-register-history__paydesk-balance default-btn">
            <h2 className="page-title">
              {this.pageTitle} <span>{this.subTittle}</span>
            </h2>
            {filteredData.length ? (
                <div className="printMaterialsButton">
                  <Button onClick={() => this.handleDataExportPDF(allData)}>
                    {constants.btnPrint}
                  </Button>
                </div>
            ) : null}
            {visible && (
                <Modal
                    visible={visible}
                    onCancel={closeModal}
                    footer={null}
                    className=""
                    width={500}
                >
                  <MaterialForm />
                </Modal>
            )}
          </div>
        </>
    );
  };

  handleDataExportPDF = async () => {
    const defaultQuery = this.props.location.search;
    const filters = defaultQuery ? defaultQuery : '?page=1'
    const urlTest = new URLSearchParams(this.props.location.search)
    let data = null
    if (urlTest.get('Amount')) {
      try {
        let sort = {}
        if(urlTest.get('Amount')){
          sort.Amount = urlTest.get('Amount')
        }
        data = (await fetchMaterials({
          ...Object.fromEntries(urlTest.entries()),
          pageSize: 999,
          sort: sort
        })).data
        console.log(data)
      } catch (e) {
        console.error(e)
      }
    } else {
      data = (await fetchAllMaterialsForPdf(filters,'grid')).data
    }


    const finalData = data.items.map((elt) => {
      return [
        elt.number,
        elt.name,
        elt.amount,
        elt.parameter,
        `${elt.inputPrice} грн.`,
        `${elt.outputPrice} грн.`,
      ];
    });
    exportPDF({
      tableData: finalData,
      header: dataHeaders,
      format: "landscape",
      contentBefore: `${
        this.pageTitle
      } \n\n${this.showContentBeforeTablePDF()}`,
    });
  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return `${
      location.search ? this.showFilterPDF(location.search.slice(1)) : ""
    }`;
  };

  showFilterPDF = (query) => {
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.materialFilterNumber:
          chosenFilters["ID МАТЕРІАЛУ"] = arrayWithFilters[indx].split("=")[1];
          break;
        case constants.materialFilterMaterialId:
          const materialTitle = this.props.data.find(
            (el) => el.id === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters["НАЗВА МАТЕРІАЛУ"] = materialTitle.name;
          break;
        case constants.materialFilterAmount:
          chosenFilters["КІЛЬКІСТЬ"] = arrayWithFilters[indx].split("=")[1];
          break;
        case constants.materialFilterInputPrice:
          chosenFilters["ВХІДНА ЦІНА"] = arrayWithFilters[indx].split("=")[1];
          break;
        case constants.materialFilterOutputPrice:
          chosenFilters["ВИХІДНА ЦІНА"] = arrayWithFilters[indx].split("=")[1];
          break;
        default:
          return chosenFilters;
      }
    });
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };
}

const mapStateToProps = ({ materials, pagination, login }) => ({
  ...materials,
  ...pagination,
  userRole: login.user.role,
  allData: materials.allData,
  filteredData: materials.data,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  createNewMaterial,
  getData: getAllMaterials,
  blockItem: blockMaterial,
  unblockItem: unlockMaterial,
  setInitialData,
  setPageNumber,
  setQueriesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
