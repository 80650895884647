import React from "react";
import { connect } from "react-redux";
import { Field, FormSpy } from "react-final-form";
import Form from "../../../components/Form";
import { OnChange } from "react-final-form-listeners";
import {
  addProductToOrder,
  initialServiceValues,
  setServiceForCreateOrder,
  setOrderPrice,
  setCurrentService,
  setCurrentProduct,
} from "../../../redux/createOrder";
import {
  CashierReactSelect,
  ServiceReactSelect,
  NumberMaskField,
} from "../../../components/Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue,
} from "../../../components/Form/validation";
import { withRouter } from "react-router-dom";

const texts = {
  service: "Послуга",
  number: "Кількість",
  price: "Ціна",
  total: "Сума",
  btnText: "+ Додати",
  serviceAndMaterial: "Послуга + Матеріал",
  material: "Матеріал",
  employeeId: "Відповідальний",
};

class AddMaterialFrom extends Form {
  title = "";
  onchange = null;

  onValidate = makeFormValidator({
    serviceId: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
    total: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: (value) => (value ? value <= 0 : null),
    }),
    price: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(0)]: (value) => (value ? value <= 0 : null),
    }),
    amount: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue(1)]: (value) => (value ? value < 1 : null),
    }),
    employeeId: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
  });

  onSubmit = (props) => {
    const { amount, total, employeeId } = props;
    const {
      setServiceForCreateOrder,
      addProductToOrder,
      orderPrice,
      setOrderPrice,
      currentService: { price },
    } = this.props;
    const updateDiscount = Number.parseFloat(total - amount * price).toFixed(2);
    const finalData = {
      ...props,
      amount: Number(amount),
      employeeId: employeeId.value ? employeeId.value : employeeId,
      discount: Number(updateDiscount),
      id: Math.random().toString(36).substr(2, 9),
    };
    const finalPrice =
      orderPrice === "" ? Number(total) : Number(orderPrice) + Number(total);
    setOrderPrice(Number.parseFloat(finalPrice).toFixed(2));
    setServiceForCreateOrder(finalData);
    addProductToOrder(finalData);
  };

  handleChangeService = ({ value }) => {
    const { servicesData, initialServiceValues, setCurrentService } =
      this.props;
    const currentData = servicesData.find((item) => item.id === value);
    setCurrentService(currentData);
    currentData &&
      initialServiceValues({
        serviceId: currentData.id,
        serviceName: currentData.name,
        price: currentData.price,
      });
  };

  handleChangeAmount = (value) => {
    const {
      values: { price },
      initialServiceValues,
    } = this.props;
    if (!price) {
      return initialServiceValues({ amount: value });
    }
    const data = {
      amount: value,
      total: Number.parseFloat(price * value).toFixed(2),
    };
    initialServiceValues(data);
  };

  handleChangePrice = (value) => {
    const {
      values: { amount },
      initialServiceValues,
      currentService: { price },
    } = this.props;
    if (!amount && !price) {
      return initialServiceValues({ price: value });
    }
    const updateTotal = Number.parseFloat(amount * value).toFixed(2);
    const currentTotal = Number.parseFloat(amount * price).toFixed(2);
    const data = {
      price: value,
      total: updateTotal,
      discount: Number.parseFloat(updateTotal - currentTotal).toFixed(2),
    };
    initialServiceValues(data);
  };

  handleChangeEmployee = (data) => {
    if (!data) return false;
    const { initialServiceValues } = this.props;
    initialServiceValues({
      employeeId: data.value,
      employeeFullName: data.label,
    });
  };

  renderFields = () => (
    <>
      <div className="c-form__item order-form__item">
        <FormSpy
          render={({ form: { change } }) => {
            this.onchange = change;
            return (
              <>
                <Field
                  className="c-form__field"
                  spanClassName="order-form__product"
                  name="serviceId"
                  component={ServiceReactSelect}
                  label={texts.service}
                  onChange={this.handleChangeService}
                  selectValueSize={29}
                />
                <Field
                  className="c-form__field"
                  name="amount"
                  component={NumberMaskField}
                  label={texts.number}
                  required
                />
                <OnChange name="amount">
                  {(value) => (
                    <>
                      {change("amount", value)}
                      {this.handleChangeAmount(value)}
                    </>
                  )}
                </OnChange>
                <Field
                  className="c-form__field"
                  name="price"
                  component={NumberMaskField}
                  label={texts.price}
                  required
                />
                <OnChange name="price">
                  {(value) => (
                    <>
                      {change("price", value)}
                      {this.handleChangePrice(value)}
                    </>
                  )}
                </OnChange>
                <Field
                  className="c-form__field"
                  name="total"
                  component={NumberMaskField}
                  type="total"
                  label={texts.total}
                  required
                />
                <Field
                  className="c-form__field"
                  name="employeeId"
                  component={CashierReactSelect}
                  label={texts.employeeId}
                  onChange={this.handleChangeEmployee}
                  required
                />
                <div className="c-form__add  order-form__submit">
                  <button className="c-button" type="submit">
                    {texts.btnText}
                  </button>
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  servicesData: state.createOrder.orderData.services,
  values: state.createOrder.initialValues.service,
  orderPrice: state.createOrder.dataForCreateOrder.orderPrice,
  currentService: state.createOrder.currentService,
});

const mapDispatchToProps = {
  addProductToOrder,
  initialServiceValues,
  setServiceForCreateOrder,
  setOrderPrice,
  setCurrentService,
  setCurrentProduct,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddMaterialFrom));
