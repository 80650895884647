import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { NavLink, withRouter, useHistory } from "react-router-dom";

import Logo from "../../assets/images/logo.png";
import ClientsForOrders from "../../components/GeneralFields/Select/Odrer/ClientsForOrder";
import EntrepreneursForOrder from "../GeneralFields/Select/Odrer/EntrepreneursForOrder";
import confirmationModal from "../../components/ModalConfirm";
import removeUserData from "../../redux//login";
import { deleteItem } from "../../helpers/storage";

const logoutHandler = (logout) => {
  removeUserData();
  deleteItem("token");
  logout();
};

const texts = {
  logoutTitle: "Ви дійсно хочите вийти з програми?",
  okBtnText: "Так, вийти",
};

const confirmLogout = (logout) => {
  const onOk = () => logoutHandler(logout);
  const title = texts.logoutTitle;
  const okText = texts.okBtnText;
  confirmationModal(title, onOk, okText);
};

const generateUserLogo = (str) => {
  const rex = /[A-Я]/g;
  let match;
  let result = "";
  while ((match = rex.exec(str)) !== null) {
    result = result + match[0];
  }
  return result;
};

const Header = (props) => {
  const history = useHistory()
  const {
    location: { pathname },
    orderNumber,
    clientsBalance,
    currentClient,
    paydeskId,
    logout,
    username,
  } = props;

  if (pathname === '/create-order/') {
    history.push('/create-order')
  }

  return (
    <div className="header">
      <div className="header__left">
        <div className="header__logo">
          <NavLink to={`/`}>
            <img src={Logo} alt="Logo" />
          </NavLink>
          {pathname === "/create-order" && (
            <div className="header__order-number">№{orderNumber}</div>
          )}
        </div>
        {pathname === "/create-order" && (
          <div className="header__entrepreneurs">
            <EntrepreneursForOrder value={paydeskId} />
          </div>
        )}
      </div>
      <div className="header__right">
        {pathname === "/create-order" && (
          <div className="header__clients">
            <div className="header__clients_balance">
              {currentClient &&
                !currentClient.isDefault &&
                `Баланс: ${clientsBalance}`}
            </div>
            <div className="header__clients_list">
              <ClientsForOrders value={currentClient} selectValueSize={18} />
            </div>
          </div>
        )}
        <div className="header__user">
          <div className="header__user_name">{username}</div>
          <div className="header__user_logo">
            {username && generateUserLogo(username)}
          </div>
          <div className="header__user_logout">
            <LogoutOutlined onClick={() => confirmLogout(logout)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orderNumber: state.createOrder.orderData.number,
  clientsBalance: state.clients.currentClient.balance,
  currentClient: state.clients.currentClient,
  paydeskId: state.createOrder.dataForCreateOrder.paydeskId,
  username: state.login.user.name,
});

const mapDispatchToProps = {
  removeUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
