import React from 'react';
import { DatePicker } from 'antd';
import { ClearIcon, CalendarIcon } from '../../../../icons';
import { getInputClass } from '../helpers';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/uk_UA';
import 'moment/locale/uk';

const { RangePicker } = DatePicker;
const format = "DD/MM/YYYY";

const DateRangePicker = ({ input, meta, label = "", required, placeholder = "", maxDate='2100-01-01', ...props }) => {
  const className = getInputClass({ meta, value: input.value })
  const requiredMark = required ? <span className="c-form__field--required">*</span> : '';
  let value = input.value;
  if (Array.isArray(value)) {
    value = value.map(item => moment(item));
  }

  const minimumDate = moment('1900-01-01', 'YYYY-MM-DD');
  const maximumDate = moment(maxDate, 'YYYY-MM-DD');

  return (
    <span className={`c-form__item-range date-range ${className}`}>
      <label>{label}{requiredMark}</label>
      <RangePicker
        {...input}
        {...props}
        inputProps={{ ...input }}
        value={value}
        format={format}
        separator="-"
        size="small"
        clearIcon={<ClearIcon />}
        locale={locale}
        suffixIcon={<div className="calendar-icon"><CalendarIcon /></div>}
        placeholder={placeholder}
        disabledDate={d => !d || d.isBefore(minimumDate) || d.isAfter(maximumDate)}
        ranges={{
          'Поточний день': [moment(), moment()],
          'Поточний місяць': [moment().startOf('month'), moment()],
        }}
      />
      <div className="ant-form-explain">
        {meta.touched ? meta.error : ''}
      </div>
    </span>
  );
};

export default DateRangePicker;