import http from "../index";
import { tablePageSize } from "../../helpers/constants";

export function fetchReportByProduct({
  EntityId = "",
  StartDate = "",
  EndDate = "",
  PageSize = 10,
  pageNumber = 1,
  isMaterial = false,
}) {
  const EntityIdQuery = EntityId && `&EntityId=${EntityId}`;
  const StartDateQuery = StartDate && `&StartDate=${StartDate}`;
  const EndDateQuery = EndDate && `&EndDate=${EndDate}`;
  const isMaterialQuery = `&isMaterial=${isMaterial}`;
  const PageSizeQuery = PageSize && `&PageSize=${PageSize}`;
  const pageNumberQuery = pageNumber && `&PageNumber=${pageNumber}`;
  const queries = [
    EntityIdQuery,
    StartDateQuery,
    EndDateQuery,
    isMaterialQuery,
    PageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/reports/gridbyproduct?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchDownloadPdf({
  EntityId = "",
  StartDate = "",
  EndDate = "",
  reportType = "",
  token = "",
}) {
  const EntityIdQuery = EntityId && `&EntityId=${EntityId}`;
  const StartDateQuery = StartDate && `&StartDate=${StartDate}`;
  const EndDateQuery = EndDate && `&EndDate=${EndDate}`;
  const reportTypeQuery = reportType && `&reportType=${reportType}`;
  const tokenQuery = token && `&token=${token}`;
  const queries = [
    EntityIdQuery,
    StartDateQuery,
    reportTypeQuery,
    EndDateQuery,
    tokenQuery,
  ];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/reports/downloadentityreportpdf?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchReportByWorker({
  EntityId = "",
  StartDate = "",
  EndDate = "",
  PageSize = tablePageSize,
  PageNumber = 1,
}) {
  const EntityIdQuery = EntityId && `&EntityId=${EntityId}`;
  const StartDateQuery = StartDate && `&StartDate=${StartDate}`;
  const EndDateQuery = EndDate && `&EndDate=${EndDate}`;
  const pageNumberQuery = PageNumber && `&PageNumber=${PageNumber}`;
  const PageSizeQuery = PageSize && `&PageSize=${PageSize}`;
  const queries = [
    EntityIdQuery,
    StartDateQuery,
    EndDateQuery,
    PageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/reports/gridbyemployee?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchReportByClient({
  EntityId = "",
  StartDate = "",
  EndDate = "",
  PageSize = tablePageSize,
  PageNumber = 1,
}) {
  const EntityIdQuery = EntityId && `&EntityId=${EntityId}`;
  const StartDateQuery = StartDate && `&StartDate=${StartDate}`;
  const EndDateQuery = EndDate && `&EndDate=${EndDate}`;
  const pageNumberQuery = PageNumber && `&PageNumber=${PageNumber}`;
  const PageSizeQuery = PageSize && `&PageSize=${PageSize}`;
  const queries = [
    EntityIdQuery,
    StartDateQuery,
    EndDateQuery,
    PageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/reports/gridbyclient?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchConsolidateReport({
  StartDate = "",
  EndDate = "",
  PageSize = tablePageSize,
  PageNumber = 1,
}) {
  const StartDateQuery = StartDate && `&StartDate=${StartDate}`;
  const EndDateQuery = EndDate && `&EndDate=${EndDate}`;
  const pageNumberQuery = PageNumber && `&PageNumber=${PageNumber}`;
  const PageSizeQuery = PageSize && `&PageSize=${PageSize}`;
  const queries = [
    StartDateQuery,
    EndDateQuery,
    PageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/reports/gridbyperiod?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchDownloadConsolidatePdf({
  StartDate = "",
  EndDate = "",
  token = "",
}) {
  const StartDateQuery = StartDate && `&StartDate=${StartDate}`;
  const EndDateQuery = EndDate && `&EndDate=${EndDate}`;
  const tokenQuery = token && `&token=${token}`;
  const queries = [StartDateQuery, EndDateQuery, tokenQuery];
  const url = queries.filter((query) => query).join("");
  return http
    .get(`/reports/downloadconsolidatedreportpdf?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllReportForPdf(url,name) {
  return http
      .get(`/reports/${name}${url}&PageSize=999&`)
      .then((response) => response)
      .catch((error) => error.response);
}
