import http from "../index";
import { generateSortParams } from "../../helpers";
import { tablePageSize } from "../../helpers/constants";

export function fetchEncashments({
  paydeskId = "",
  cashierId = "",
  recipientId = "",
  startDate = "",
  endDate = "",
  sort = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const paydeskIdQuery = paydeskId && `&PaydeskId=${paydeskId}`;
  const сashierIdQuery = cashierId && `&CashierId=${cashierId}`;
  const startDateQuery = startDate && `&StartDate=${startDate}`;
  const endDateQuery = endDate && `&EndDate=${endDate}`;
  const recipientIdQuery = recipientId && `&RecipientId=${recipientId}`;
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const queries = [
    paydeskIdQuery,
    сashierIdQuery,
    startDateQuery,
    endDateQuery,
    recipientIdQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);

  return http
    .get(`/Encashments/grid?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}

// export function fetchDataForCollection() {
//     return http.get(`/Paydesks/grid`)
//         .then(response => response)
//         .catch(error => error.response);
// };

export function fetchDataForCollection({
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&PageNumber=${pageNumber}`;
  const queries = [pageSizeQuery, pageNumberQuery];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/paydesks/grid-paydesk?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchDataByIdCollection(id) {
  return http
    .get(`/Paydesks/encashment/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function addNewCollection(data) {
  return http
    .post("/Paydesks/encashment", data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchPaydesks({
  orderNumber = "",
  operationStartDate = "",
  paydeskId = "",
  sort = "",
  paymentMethodId = "",
  operationEndDate = "",
  cashierId = "",
  clientId = "",
  transferNumber = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const orderIdQuery = orderNumber && `&OrderNumber=${orderNumber}`;
  const paydeskIdQuery = paydeskId && `&PaydeskId=${paydeskId}`;
  const operationStartDateQuery =
    operationStartDate && `&operationStartDate=${operationStartDate}`;
  const operationEndDateQuery =
    operationEndDate && `&OperationEndDate=${operationEndDate}`;
  const сashierIdQuery = cashierId && `&CashierId=${cashierId}`;
  const transferNumberQuery =
    transferNumber && `&transferNumber=${transferNumber}`;
  const paymentMethodIdQuery =
    paymentMethodId && `&PaymentMethodId=${paymentMethodId}`;
  const clientIdQuery = clientId && `&clientId=${clientId}`;
  const pageSizeQuery = pageSize && `&pageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const queries = [
    orderIdQuery,
    paydeskIdQuery,
    сashierIdQuery,
    operationStartDateQuery,
    paymentMethodIdQuery,
    operationEndDateQuery,
    transferNumberQuery,
    clientIdQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);

  return http
    .get(`/Paydesks/grid-history?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}
