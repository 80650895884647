import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import Modal from "../../components/Modal";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import * as moment from "moment";
import { exportPDF } from "../../components/ExportToPdf";
import {
  openModalReplenishBalance,
  closeModalReplenishBalance,
  openModalNotes,
  closeModalNotes,
} from "../../redux/orders";
import { withRouter } from "react-router-dom";
import { setPageNumber } from "../../redux/pagination";
import { getAllOrders } from "../../redux/orders";
import ClientsSelect from "../../components/GeneralFields/Select/ClientsSelect";
import OrderStatusSelect from "../../components/GeneralFields/Select/OrderStatusSelect";
import SortField from "../../components/GeneralFields/SortField";
import CashierSelect from "../../components/GeneralFields/Select/СashierSelect";
import SearchField from "../../components/GeneralFields/SearchField";
import DateRangePicker from "../../components/GeneralFields/RangePicker";
import ReplenishBalanceForm from "./ReplenishBalanceForm";
import http from "../../../src/http";
import TextArea from "antd/es/input/TextArea";

const constants = {
  format: "YYYY-MM-DD",
  optionFirst: "orderCreationStartDate",
  optionSecond: "orderCreationEndDate",
  orderHistoryFilterOrderNumber: "orderNumber",
  orderHistoryFilterPaid: "PaidSum",
  orderHistoryFilterAmount: "Amount",
  orderHistoryFilterDebt: "Debt",
  orderHistoryFilterStatus: "status",
  orderHistoryFilterClientId: "clientId",
  orderHistoryFilterEmployeeId: "employeeId",
};

const options = [
  {
    id: 1,
    value: "Paid",
    name: "оплачено",
  },
  {
    id: 2,
    value: "PartialPaid",
    name: "частково",
  },
  {
    id: 3,
    value: "NotPaid",
    name: "не оплачено",
  },
];

const texts = {
  btn: "Друк",
};

const dataHeaders = [
  [
    "НОМЕР НАКЛАДНОЇ",
    "ДАТА НАКЛАДНОЇ",
    "КАСИР",
    "СТАТУС",
    "СУМА",
    "ОПЛАЧЕНО",
    "БОРГ",
    "КЛІЄНТ",
  ],
];

class OrderHistory extends GeneralPage {
  pageTitle = "Історія замовлень";
  class = "order-history";
  columns = columns.general;

  state = {
    editNotesInput: false,
    editNotesValue: "",
    sort: {},
  };

  componentDidMount = () => {
    const { getData } = this.props;
    const query = queryString.parse(this.props.location.search);

    let sort = {};
    for (let property in query) {
      if (property === constants.orderHistoryFilterPaid) {
        sort[property] = query[property];
      } else if (property === constants.orderHistoryFilterAmount) {
        sort[property] = query[property];
      } else if (property === constants.orderHistoryFilterDebt) {
        sort[property] = query[property];
      }
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  showPDFButtonFilter = () => {
    if (
      this.props.location.search.includes("orderCreationStartDate") &&
      this.props.location.search.includes("clientId")
    ) {
      let datesUrl = this.props.location.search.slice(1);
      const filteredDatesUrl = datesUrl
        .split("&")
        .filter((e) => !e.startsWith("clientId"));

      const duration = moment
        .duration(
          moment(filteredDatesUrl[0].slice(-10), "YYYY-MM-DD").diff(
            moment(filteredDatesUrl[1].slice(-10), "YYYY-MM-DD")
          )
        )
        .asDays();
      if (duration > 90) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  notesInputValueHandler = (e) => {
    this.setState({ editNotesValue: e.target.value });
  };

  submitNotesHandler = async (id) => {
    const query = queryString.parse(this.props.location.search);
    await http.put(`ordershistory/updatenote`, {
      id: id,
      note: this.state.editNotesValue,
    });
    this.props.closeNotes();
    this.setState({ editNotesInput: false });
    this.props.getData({ pageNumber: query.page, ...this.state });
  };

  additionalComponentsTops = () => {
    return (
        <div className="cash-register-history__paydesk-balance default-btn">
          <h2 className="page-title">
            {this.pageTitle} <span>{this.subTittle}</span>
          </h2>
        </div>
    );
  };
  additionalComponents = () => {
    const { visible, closeModal, notesVisible, closeNotes } = this.props;
    return (
      <>
        <div className="report-by-client__bottom">
          <div className="default-btn">
            <button
              onClick={() => this.handleDataExportPDF(this.props.data)}
              style={
                this.showPDFButtonFilter()
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              {texts.btn}
            </button>
          </div>
        </div>
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className=""
            width={500}
          >
            <ReplenishBalanceForm />
          </Modal>
        )}
        {notesVisible && (
          <Modal
            visible={notesVisible}
            onCancel={() => {
              this.setState({ editNotesInput: false });
              closeNotes();
            }}
            footer={null}
            className=""
            width={500}
          >
            <div className="noteContainer">
              {this.state.editNotesInput ? (
                <>
                  <div className="noteEditContainer">
                    <TextArea
                      type="text"
                      className="noteEditInput"
                      value={this.state.editNotesValue}
                      autoSize={{ minRows: 1, maxRows: 20 }}
                      onChange={this.notesInputValueHandler}
                    />

                    <span
                      className="noteEditButton"
                      onClick={() =>
                        this.setState({
                          editNotesInput: false,
                        })
                      }
                    >
                      Скасувати
                    </span>
                  </div>
                  <div className="noteSubmitButton">
                    <button
                      onClick={() =>
                        this.submitNotesHandler(this.props.orders.currentId.id)
                      }
                    >
                      Зберегти
                    </button>
                  </div>
                </>
              ) : (
                <div className="noteDefaultContainer">
                  <h2>{this.props.orders.currentNotes}</h2>
                  <span
                    className="noteEditButton"
                    onClick={() => {
                      this.setState({
                        editNotesInput: true,
                        editNotesValue: this.props.orders.currentNotes,
                      });
                    }}
                  >
                    Редагувати
                  </span>
                </div>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  };

  handleDataExportPDF = async () => {
    const { data } = await http.get(
      `/OrdersHistory/grid${this.props.location.search}&pageSize=15000`
    );

    const finalData = data.items.map((elt) => {
      const statusMessage = () =>
        options.filter((el) => el.id === elt.status)[0].name;
      return [
        elt.orderNumber,
        moment(elt.orderCreationDate).format("DD.MM.YYYY HH:mm"),
        `${elt.employeeFirstName} ${elt.employeeLastName}`,
        statusMessage(),
        `${elt.amount} грн.`,
        `${elt.paidSum} грн`,
        `${elt.debt} грн`,
        `${elt.clientFirstName} ${elt.clientFirstName}`,
      ];
    });
    exportPDF({
      tableData: finalData,
      header: dataHeaders,
      contentBefore: `${
        this.pageTitle
      } \n\n${this.showContentBeforeTablePDF()}`,
    });
  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return `${
      location.search ? this.showFilterPDF(location.search.slice(1)) : ""
    }`;
  };

  showFilterPDF = (query) => {
    const { clients, employees } = this.props;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.orderHistoryFilterOrderNumber:
          chosenFilters["Номер накладної"] =
            arrayWithFilters[indx].split("=")[1];
          break;
        case constants.optionFirst:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("orderCreationEndDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Дата накладної"] =
              moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Дата накладної"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        case constants.orderHistoryFilterEmployeeId:
          const employeeId = employees.filter(
            (el) => el.id === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters[
            "Касир"
          ] = `${employeeId[0].firstName} ${employeeId[0].lastName}`;
          break;
        case constants.orderHistoryFilterStatus:
          const status = options.filter(
            (el) => el.value === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters["Статус"] = `${status[0].name}`;
          break;
        case constants.orderHistoryFilterClientId:
          const client = clients.filter(
            (el) => el.id === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters["Клієнт"] = `${client[0].fullName}`;
          break;
        default:
          return chosenFilters;
      }
    });
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  getDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.optionFirst] = "";
      changeData[constants.optionSecond] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionFirst] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionSecond] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        <SearchField
          defaultValue={query[constants.orderHistoryFilterOrderNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.orderHistoryFilterOrderNumber
            )
          }
        />
        <DateRangePicker
          defaultValue={[
            query[constants.optionFirst],
            query[constants.optionSecond],
          ]}
          onChange={this.getDate}
        />
        <CashierSelect
          value={query[constants.orderHistoryFilterEmployeeId]}
          label=""
          getSelectedValue={this.getFilterValue}
          name={constants.orderHistoryFilterEmployeeId}
          selectValueSize={10}
        />
        <OrderStatusSelect
          value={query[constants.orderHistoryFilterStatus]}
          getSelectedValue={this.getFilterValue}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.orderHistoryFilterAmount}
          value={query[constants.orderHistoryFilterAmount]}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.orderHistoryFilterPaid}
          value={query[constants.orderHistoryFilterPaid]}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.orderHistoryFilterDebt}
          value={query[constants.orderHistoryFilterDebt]}
        />
        <ClientsSelect
          value={query[constants.orderHistoryFilterClientId]}
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
        />
      </>
    );
  };
}

const mapStateToProps = ({ orders, pagination, employees, clients }) => ({
  ...pagination,
  data: orders.data,
  orders: orders,
  visible: orders.visible,
  notesVisible: orders.notesVisible,
  loading: orders.loading,
  employees: employees.allCashiers,
  clients: clients.allData,
});

const mapDispatchToProps = {
  getData: getAllOrders,
  openModal: openModalReplenishBalance,
  closeModal: closeModalReplenishBalance,
  openNotes: openModalNotes,
  closeNotes: closeModalNotes,
  setPageNumber,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderHistory));
