const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'product',
      Header: 'Товар',
      minWidth: 150,
      filterable: false,
      sortable: false,
      formatter: 'productOrder',
    },
    {
      accessor: 'amount',
      Header: 'Кількість',
      minWidth: 150,
      sortable: false,
      edit: true,
      formatter: 'editOrderAmount',
      filterable: false,
    },
    {
      accessor: 'price',
      Header: 'Ціна',
      minWidth: 150,
      sortable: false,
      formatter: 'editOrderPrice',
      filterable: false,
    },
    {
      accessor: 'discount',
      Header: 'Знижка',
      minWidth: 150,
      sortable: false,
      edit: true,
      formatter: 'discountCreateOrder',
      filterable: false,
    },
    {
      accessor: 'total',
      Header: 'Сума',
      formatter: 'editOrderTotal',
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'employeeId',
      Header: 'Виконавець',
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: 'employeeIdOrder',
    },
    {
      accessor: 'deleteItem',
      Header: '',
      minWidth: 30,
      formatter: 'deleteItem',
      sortable: false,
      filterable: false,
    },
  ],
};

export default columns;