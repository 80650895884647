import React from 'react';

export const productOrderFormatter = (currentProduct, currentProductId, orderData) => {
  if (currentProductId && currentProduct === "service") {
    const currentProductItem = orderData.services.find(item => item.id === currentProductId );
    return (
      <span className="status-label">
        {currentProductItem.name}
      </span>
    )
  } else if (currentProductId && currentProduct === "material") {
    const currentProductItem = orderData.materials.find(item => item.id === currentProductId );
    return (
      <span className="status-label">
        {currentProductItem.name}
      </span>
    )
  }
  return '-'
};