const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: "number",
      Header: "ID матеріалу",
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "name",
      Header: "Назва матеріалу",
      minWidth: 150,
      filterable: false,
      sortable: false,
    },
    {
      accessor: "amount",
      Header: "Кількість",
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "parameter",
      Header: "Параметр",
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "inputPrice",
      Header: "Вхідна ціна",
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: "currency",
    },
    {
      accessor: "outputPrice",
      Header: "Вихідна ціна",
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: "currency",
    },
    {
      accessor: "isDeleted",
      Header: "",
      minWidth: 50,
      formatter: "blockUnblock",
      sortable: false,
      filterable: false,
    },
    {
      accessor: "edit",
      Header: "",
      minWidth: 50,
      formatter: "edit",
      sortable: false,
      filterable: false,
    },
  ],
};

export default columns;
