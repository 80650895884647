import React from "react";
import ReportByMaterialForm from "./Forms/ReportByMaterialForm";
import ConsolidatedReportForm from "./Forms/ConsolidatedReportForm";
import ReportByWorkerForm from "./Forms/ReportByWorkerForm";
import ReportByServiceForm from "./Forms/ReportByServiceForm";
import ReportByClientForm from "./Forms/ReportByClientForm";

const constants = {
  title: "Звіти",
};

const MainReport = () => {
  return (
    <div className="reports-forms">
      <h2 className="page-title">{constants.title}</h2>
      <ReportByMaterialForm />
      <ReportByServiceForm />
      <ReportByWorkerForm />
      <ConsolidatedReportForm />
      <ReportByClientForm />
    </div>
  );
};

export default MainReport;
