import React from "react";
import { Link } from "react-router-dom";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import { connect } from "react-redux";
import {
  openModal,
  closeModal,
  getDataForCollection,
  setInitialData,
} from "../../redux/collections";
import { setPageNumber } from "../../redux/pagination";
import Modal from "../../components/Modal";
import CollectionForm from "./CollectionForm";

const texts = {
  history: "Історія інкасації",
};

class Collection extends GeneralPage {
  pageTitle = "Інкасація";
  class = "collection";
  columns = columns.general;

  state = {
    name: undefined,
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  additionalComponentsTops = () => {
    const { visible, closeModal } = this.props;
    return (
      <>
        <div className="cash-register-history__paydesk-balance default-btn">
          <h2 className="page-title">
            {this.pageTitle} <span>{this.subTittle}</span>
          </h2>
          <div className="collection-link">
            <Link to="/history-collection">{texts.history}</Link>
          </div>
        </div>
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="collection-modal"
            width={500}
          >
            <CollectionForm />
          </Modal>
        )}
      </>
    );
  };
}

const mapStateToProps = ({ collections, pagination }) => ({
  data: collections.activeData,
  loading: collections.loading,
  visible: collections.visible,
  ...pagination,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  getData: getDataForCollection,
  setInitialData,
  setPageNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
