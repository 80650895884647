import React from 'react';
import Select from '../index';
import { Select as SelectAntd } from 'antd';
import { connect } from 'react-redux';
// import { getAllCarriers } from '../../../../../redux/admin/carriers';

const { Option } = SelectAntd;

class ServiceAndMaterialSelect extends Select {

  componentDidMount() {
    // this.props.getData();
  }

  options = [
    {
      id: 1,
      name: "Роздрук A4 + папір"
    },
    {
      id: 2,
      name: "Роздрук A2 + папір"
    },
    {
      id: 3,
      name: "Роздрук A3 + папір"
    }
  ]

  renderOptions = () => {
    // const { options } = this.props;
    return this.options.map(option => <Option key={option.id} value={option.name}>{option.name}</Option>)
  }
}

// const mapStateToProps = state => ({
//   options: state.carriers.data,
// });

// const mapDispatchToProps = {
//   getData: getAllCarriers
// };

export default connect(null, null)(ServiceAndMaterialSelect);