import React from "react";
import MaskedInput from "react-text-mask";
import { getInputClass } from "../helpers";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  allowNegative: false,
  includeThousandsSeparator: false,
  integerLimit: 7,
};

const NumberMaskField = ({
  input,
  meta,
  label = "",
  placeholder = "Введіть",
  spanClassName = "",
  required,
  ...props
}) => {
  const requiredMark = required ? (
    <span className="c-form__field--required">*</span>
  ) : (
    ""
  );
  const className = getInputClass({ meta, value: input.value });
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <span className={`${className} ${spanClassName}`}>
      <label>
        {label}
        {requiredMark}
      </label>
      <MaskedInput
        {...input}
        {...props}
        autoComplete="off"
        showMask
        mask={currencyMask}
        guide={false}
        keepCharPositions={true}
        placeholder={placeholder}
      />
      <div className="ant-form-explain">
        {meta.submitFailed ? meta.error : ""}
      </div>
    </span>
  );
};

export default NumberMaskField;
