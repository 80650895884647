import React from 'react';
import MaskedInput from 'react-text-mask';
import { getInputClass } from '../helpers';

const PhoneField = ({ input, meta, label = "", required, placeholder = "97-083-55-83", ...props }) => {
  const requiredMark = required ? <span className="c-form__field--required">*</span> : '';
  const className = getInputClass({ meta, value: input.value })
  return (
    <span className={`${className} phone-field`}>
      <label>{label}{requiredMark}</label>
      <MaskedInput
        className="ant-input"
        {...input}
        {...props}
        mask={['+', 3, 8, '-', 0, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
        showMask
        guide={false}
        keepCharPositions={true}
        placeholder={placeholder}
        type="phone"
      />
      <div className="ant-form-explain">
        {meta.touched ? meta.error : ''}
      </div>
    </span>
  );
};

export default PhoneField;