const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'name',
      Header: 'Назва ФОП',
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    // {
    //   accessor: 'balance',
    //   Header: 'Баланс',
    //   minWidth: 150,
    //   sortable: false,
    //   filterable: false,
    // },

    {
      accessor: 'fiscalNumber',
      Header: 'Фіскальний номер',
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'registrarFiscalNumber',
      Header: 'Фіскальний номер пРРО',
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'createdAt',
      Header: 'Дата створення',
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: 'date'
    },
    {
      accessor: 'isDeleted',
      Header: '',
      minWidth: 50,
      formatter: 'blockUnblock',
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'edit',
      Header: '',
      minWidth: 50,
      formatter: 'edit',
      sortable: false,
      filterable: false,
    },
  ],
};

export default columns;
