import React from 'react';

const constants = {
  1: "оплачено",
  2: "частково",
  3: "не оплачено"
}

export const orderStatusFormatter = status => (
  <div className={`order-status-formatter ${(status === 3 && 'no-paid') || (status === 2 && 'partial-paid') || (status === 1 && 'paid')}`}>
    <span>{constants[status]}</span>
  </div>
)
