export const generalPages = [
  {
    title: 'Історія каси',
    path: 'cash-register-history'
  },
  {
    title: 'Клієнти',
    path: 'clients'
  },
  {
    title: 'Історія балансу',
    path: 'balance-history'
  },
  {
    title: 'Історія замовлень',
    path: 'order-history',
  },
  {
    title: 'Послуги',
    path: 'services'
  },
  {
    title: 'Прихід матеріалів',
    path: 'comings'
  },
  {
    title: 'Матеріали',
    path: 'materials'
  },
]

const advancePage = [
  {
    title: 'Звіти',
    path: 'reports'
  },
  {
    title: 'Історія інкасації',
    path: 'history-collection'
  },
  {
    title: 'Інкасація',
    path: 'collection'
  },
  {
    title: 'ФОП',
    path: 'paydesks'
  },
]

export const roleNames = {
  superAdmin: 'СУПЕР-АДМІН',
  accountant: 'БУХГАЛТЕР',
  casier: 'КАСИР',
};

const sidebarPages = {
  'СУПЕР-АДМІН': [...advancePage, ...generalPages ],
  'БУХГАЛТЕР': [...advancePage, ...generalPages ],
  'КАСИР': generalPages
};

const pages = role => {
  return sidebarPages[role]
}

export default pages;
