import { takeEvery, put, call } from 'redux-saga/effects';
import { SET_NOTIFICATION } from '../notification';
import { SET_PAGE_COUNT } from '../pagination';
import { GET_DATA_FOR_ORDER } from '../createOrder';

import {
  fetchComings, editComingById, addNewComing,
  deleteComing
} from '../../http/comings';

const COMINGS_LOAD = 'comings/COMINGS_LOAD';
const COMINGS_LOADING = 'comings/COMINGS_LOADING';
const COMINGS_LOAD_FAILED = 'comings/COMINGS_LOAD_FAILED';
const COMING_MODAL_CLOSE = 'comings/COMING_MODAL_CLOSE'
const COMING_MODAL_OPEN = 'comings/COMING_MODAL_OPEN';
const COMING_LOAD_SUCCESS = 'comings/COMING_LOAD_SUCCESS';
const CREATE_NEW_COMING = 'comings/CREATE_NEW_COMING';
const CREATE_COMING = 'comings/CREATE_COMING';
const BLOCK_COMING = 'comings/BLOCK_COMING';
const COMING_ADD_SUCCESS = 'comings/COMING_ADD_SUCCESS';
const COMING_SET_INITIAL = 'comings/COMING_SET_INITIAL';
const COMING_EDIT = 'comings/COMING_EDIT';
const COMING_SET_MATERIAL_DATA = 'comings/COMING_SET_MATERIAL_DATA';
const COMING_SET_QUERIES_DATA = 'comings/COMING_SET_QUERIES_DATA';
const COMING_MODAL_CLOSE_SUCCESS = 'comings/COMING_MODAL_CLOSE_SUCCESS';
const COMING_DELETE_ITEM_MODAL = 'comings/COMING_DELETE_ITEM_MODAL'

const initialState = {
  initialValues: {
    materials: [{
      name: '',
      amount: '',
      inputPrice: '',
      outputPrice: ''
    }],
    number: '',
    provider: '',
  },
  queriesData: {},
  materialData: [],
  loading: false,
  visible: false,
  currentId: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case COMINGS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case COMINGS_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case COMING_MODAL_OPEN: {
      return {
        ...state,
        visible: true,
        currentId: action.id
      };
    }
    case COMING_MODAL_CLOSE_SUCCESS: {
      return {
        ...state,
        currentId: '',
        visible: false,
      };
    }
    case COMING_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        materialData: action.data.items
      };
    }
    case COMING_SET_QUERIES_DATA: {
      return {
        ...state,
        loading: false,
        queriesData: {
          ...state.queriesData,
          ...action.data
        }
      };
    }
    case CREATE_NEW_COMING: {
      return {
        ...state,
        loading: false,
        visible: true,
        initialValues: {
          materials: [{
            name: '',
            amount: '',
            inputPrice: '',
            outputPrice: ''
          }],
          number: '',
          provider: '',
        }
      };
    }
    case COMING_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        visible: false,
        currentId: null
      };
    }
    case COMING_SET_INITIAL: {
      return {
        ...state,
        visible: true,
        loading: false,
        initialValues: {
          ...state.initialValues,
          ...action.data
        }
      };
    }
    case COMING_SET_MATERIAL_DATA: {
      const updateMaterials = state.initialValues.materials
      updateMaterials[action.changeIndex] = {
        ...updateMaterials[action.changeIndex],
        ...action.data
      }
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          materials: [
            ...updateMaterials
          ]
        }
      };
    }

    case COMING_DELETE_ITEM_MODAL:
      const updateMaterials = state.initialValues.materials
      updateMaterials.splice(action.index, 1)
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          materials: [
            ...updateMaterials
          ]
        }
      }

    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getAllComings = queries => ({ type: COMINGS_LOAD, queries });
export const openModal = id => ({ type: COMING_MODAL_OPEN, id });
export const closeModal = queries => ({ type: COMING_MODAL_CLOSE, queries});
export const createNewComing = () => ({ type: CREATE_NEW_COMING });
export const createComing = (data, pageNumber, queriesData) => ({ type: CREATE_COMING, data, pageNumber, queriesData });
export const blockComing = (id, pageNumber, queriesData) => ({ type: BLOCK_COMING, id, pageNumber, queriesData });
export const editComing = (id, data, pageNumber, queriesData) => ({ type: COMING_EDIT, id, data, pageNumber, queriesData });
export const setInitialData = data => ({ type: COMING_SET_INITIAL, data });
export const setQueriesData = data => ({ type: COMING_SET_QUERIES_DATA, data });
export const setCurrentMaterialData = (data, changeIndex) => ({ type: COMING_SET_MATERIAL_DATA, data, changeIndex });
export const deleteComingsFromModal =(index) => ({type: COMING_DELETE_ITEM_MODAL, index})

//<<<WORKERS>>>
function* getAllData({ queries = {} }) {
  yield put({ type: COMINGS_LOADING })
  try {
    const response = yield call(fetchComings, queries);
    if (response.status === 200) {
      yield put({ type: COMING_SET_QUERIES_DATA, queries });
      yield put({ type: COMING_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } });
    }
  } catch (error) {
    yield put({ type: COMINGS_LOAD_FAILED })
    console.log(error)
  }
};

function* addData({ data, pageNumber, queriesData }) {
  yield put({ type: COMINGS_LOADING })
  try {
    const response = yield call(addNewComing, data);
    if (response.status === 200) {
      yield put({ type: COMING_ADD_SUCCESS });
      yield put({ type: COMINGS_LOAD, queries: { pageNumber, ...queriesData } });
      yield put({ type: GET_DATA_FOR_ORDER });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } });
    }
  } catch (error) {
    yield put({ type: COMINGS_LOAD_FAILED })
    console.log(error)
  }
};

function* blockData({ id, pageNumber, queriesData }) {
  yield put({ type: COMINGS_LOADING })
  try {
    const response = yield call(deleteComing, id);
    if (response.status === 200) {
      yield put({ type: COMINGS_LOAD, queries: { pageNumber, ...queriesData } })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
  } catch (error) {
    yield put({ type: COMINGS_LOAD_FAILED })
    console.log(error)
  }
};

function* editData({ id, data, pageNumber, queriesData }) {
  try {
    yield put({ type: COMINGS_LOADING })
    const response = yield call(editComingById, id, data);
    if (response.status === 200) {
      yield put({ type: COMING_ADD_SUCCESS });
      yield put({ type: COMINGS_LOAD, queries: { pageNumber, ...queriesData } });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } });
    }
  } catch (error) {
    yield put({ type: COMINGS_LOAD_FAILED });
    console.log(error)
  }
};

function* close({ queries }) {
  yield put({ type: COMING_MODAL_CLOSE_SUCCESS, queries })
    yield put({ type: COMINGS_LOAD, queries });
};

//<<<WATCHERS>>>
export function* watchComings() {
  yield takeEvery(COMINGS_LOAD, getAllData);
  yield takeEvery(CREATE_COMING, addData);
  yield takeEvery(BLOCK_COMING, blockData);
  yield takeEvery(COMING_MODAL_CLOSE, close);
  yield takeEvery(COMING_EDIT, editData);
}
