import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import * as moment from "moment";
import columns from "./columns";
import GeneralPage from "../../GeneralPage";
import { setPageNumber } from "../../../redux/pagination";
import {
  downloadConsolidateReportPdf,
  getConsolidatedReport,
} from "../../../redux/reports";
import { exportPDF } from "../../../components/ExportToPdf";
import {fetchAllReportForPdf} from "../../../http/reports";

const texts = {
  btn: "Друк",
  commonCost: "Загальна вартість",
  costOfMaterial: "Загальна вартість матеріалів",
  costOfService: "Загальна вартість послуг",
};

const constants = {
  optionFirstDay: "StartDate",
  optionLastDay: "EndDate",
};

const dataHeaders = [["ТОВАР/ПОСЛУГА", "КІЛЬКІСТЬ", "ВАРТІСТЬ", "ТИП"]];

class ConsolidatedReport extends GeneralPage {
  class = "consolidated-report";
  columns = columns.general;
  componentDidMount = () => false;

  state = {
    name: undefined,
  };

  componentDidMount = () => {
    const { getData } = this.props;
    const query = queryString.parse(this.props.location.search);
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  }

  handleDataExportPDF = async () => {

    const filters = this.props.location.search

    try {
      const {data} = await fetchAllReportForPdf(filters,'gridbyperiod')
      const finalData = data.items.map((elt) => {
        return [
          elt.productName,
          elt.amount,
          elt.cost,
          elt.isMaterial ? "Матеріал" : "Послуга",
        ];
      });
      exportPDF({
        tableData: finalData,
        header: dataHeaders,
        format: "landscape",
        contentBefore: `${this.showContentBeforeTablePDF()}`,
      });
    } catch (e) {

    }

  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return location.search ? this.showFilterPDF(location.search.slice(1)) : "";
  };

  showFilterPDF = (query) => {
    const { costOfMaterial, costOfService, commonCost } = this.props.details;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.cashierEntityId:
          chosenFilters[texts.reportByEmployee] = this.handleShowCashierName();
          break;
        case constants.optionFirstDay:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("EndDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Період"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Період"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        default:
          return chosenFilters;
      }
    });
    chosenFilters[texts.costOfMaterial] = `${costOfMaterial} грн`;
    chosenFilters[texts.costOfService] = `${costOfService} грн`;
    chosenFilters[texts.commonCost] = `${+commonCost} грн`;
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  additionalComponents = () => (
    <div className="consolidated-report__bottom">
      <div className="consolidated-report__details">
        <div className="consolidated-report__details_item">
          <p>
            <span>{texts.costOfMaterial}</span>{" "}
            {this.props.details.costOfMaterial}
          </p>
          <p>
            <span>{texts.costOfService}</span>{" "}
            {this.props.details.costOfService}
          </p>
          <p>
            <span>{texts.commonCost}</span> {this.props.details.commonCost}
          </p>
        </div>
      </div>
      <div className="default-btn">
        <button onClick={() => this.handleDataExportPDF(this.props.data)}>
          {texts.btn}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ reports, pagination }) => ({
  data: reports.consolidateData,
  choseData: reports.choseConsolidateData,
  details: reports.detailsConsolidateReport,
  ...pagination,
});

const mapDispatchToProps = {
  setPageNumber,
  downloadConsolidateReportPdf,
  getData: getConsolidatedReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidatedReport);
