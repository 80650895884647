import React from 'react';
import { Button } from 'antd';

export const replenishOrderAddButton = (clientFirstName, clientLastName, openModal, id, debt) => {
    if (!clientFirstName &&  !clientLastName) {
        return "-"
    } else if (clientFirstName === "Фізична" && clientLastName === "Ocoба") {
        return (
            <div className="orders-replenish-formatter">
                <Button disabled={true}>+</Button>
            </div>
        )
    } else if (debt <= 0 && clientFirstName !== "Фізична" && clientLastName !== "Ocoба") {
        return (
            <div className="orders-replenish-formatter">
                <Button disabled={true}>+</Button>
            </div>
        )
    } else {
        return (
            <div className="orders-replenish-formatter">
                <Button onClick={() => openModal(id)}>+</Button>
            </div>
        )
    }
}
