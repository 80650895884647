import React from 'react';
import * as moment from 'moment';

export const dateFormatter = val => {
  const date = moment(val).format('DD.MM.YYYY HH:mm');
  return (
    <span className="status-label">
      {date}
    </span>
  )
};