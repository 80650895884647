// <<ACTION CREATORS>>
const SET_USER = 'SET_USER_LOGIN';
export const REMOVE_USER = 'REMOVE_USER_LOGIN';

const initialState = {
  valid: false,
  user: {
    role: null
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
       user: action.data
      }
    case REMOVE_USER:
      return {
        ...state,
        user: {
          name: ''
        },
        valid: false
      }
    default:
      return state;
  }
};

// <<<ACTIONS>>>
export const setUserData = data => ({ type: SET_USER, data });
export const removeUserData = () =>  ({ type: REMOVE_USER});