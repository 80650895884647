import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import columns from "./columns";
import * as moment from "moment";
import GeneralPage from "../../GeneralPage";
import { setPageNumber } from "../../../redux/pagination";
import { downloadReportPdf, getReportByWorker } from "../../../redux/reports";
import { getAllCashiers } from "../../../redux/employee";
import { exportPDF } from "../../../components/ExportToPdf";
import {fetchAllReportForPdf} from "../../../http/reports";

const texts = {
  btn: "Друк",
  reportByEmployee: "Звіт по касиру",
  costOfService: "Загальна вартість послуг",
  costOfMaterial: "Загальна вартість матеріалів",
  commonCost: "Загальна вартість",
};

const constants = {
  cashierEntityId: "EntityId",
  optionFirstDay: "StartDate",
  optionLastDay: "EndDate",
};

const dataHeaders = [["ТОВАР/ПОСЛУГА", "КІЛЬКІСТЬ", "ВАРТІСТЬ", "ТИП"]];

class ReportByWorker extends GeneralPage {
  class = "report-by-worker";
  columns = columns.general;
  componentDidMount = () => false;

  state = {
    name: undefined,
  };

  componentDidMount = () => {
    const {
      getData,
      history,
      location: { search },
      getAllCashiers,
    } = this.props;
    const query = queryString.parse(search);
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state, history });
        getAllCashiers();
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  handleShowCashierName = () => {
    const { allCashiers, choseData } = this.props;
    const currentMaterial = allCashiers.find(
      (item) => item.id === choseData.EntityId
    );
    return currentMaterial ? currentMaterial.fullName : null;
  };

  additionalComponentsTops = () => (
    <div className="report-by-worker__top">
      <p>
        <span>{texts.reportByEmployee}</span> {this.handleShowCashierName()}
      </p>
    </div>
  );

  handleDataExportPDF = async () => {
    const filters = this.props.location.search
    try {
      const {data} = await fetchAllReportForPdf(filters,'gridbyemployee')
      const finalData = data.items.map((elt) => {
        return [
          elt.productName,
          elt.amount,
          `${elt.cost} грн`,
          elt.isMaterial ? "Матеріал" : "Послуга",
        ];
      });
      exportPDF({
        tableData: finalData,
        header: dataHeaders,
        format: "landscape",
        contentBefore: `${this.showContentBeforeTablePDF()}`,
      });
    } catch (e) {

    }

  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return location.search ? this.showFilterPDF(location.search.slice(1)) : "";
  };

  showFilterPDF = (query) => {
    const { costOfMaterial, costOfService, commonCost } = this.props.details;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.cashierEntityId:
          chosenFilters[texts.reportByEmployee] = this.handleShowCashierName();
          break;
        case constants.optionFirstDay:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("EndDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Період"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Період"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        default:
          return chosenFilters;
      }
    });
    chosenFilters[texts.costOfMaterial] = `${costOfMaterial} грн`;
    chosenFilters[texts.costOfService] = `${costOfService} грн`;
    chosenFilters[texts.commonCost] = `${+commonCost} грн`;
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  additionalComponents = () => (
    <div className="report-by-worker__bottom">
      <div className="report-by-worker__details">
        <div className="report-by-worker__details_item">
          <p>
            {" "}
            <span>{texts.costOfMaterial}</span>{" "}
            {this.props.details.costOfMaterial} грн.
          </p>
          <p>
            <span>{texts.costOfService}</span>{" "}
            {this.props.details.costOfService} грн.
          </p>
          <p>
            {" "}
            <span>{texts.commonCost}</span> {this.props.details.commonCost} грн.
          </p>
        </div>
      </div>
      <div className="default-btn">
        <button onClick={() => this.handleDataExportPDF(this.props.data)}>
          {texts.btn}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ reports, pagination, employees }) => ({
  data: reports.workerShowData,
  choseData: reports.choseEmployeeData,
  details: reports.detailsEmployee,
  allCashiers: employees.allCashiers,
  ...pagination,
});

const mapDispatchToProps = {
  setPageNumber,
  downloadReportPdf,
  getData: getReportByWorker,
  getAllCashiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportByWorker);
