import http from "../index";
import { generateSortParams } from "../../helpers";
import { tablePageSize } from "../../helpers/constants";

export function fetchMaterials({
  number = "",
  materialId = "",
  parameter = "",
  inputPrice = "",
  outputPrice = "",
  status = "",
  sort,
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const numberQuery = number && `number=${number}`;
  const materialIdQuery = materialId && `&materialId=${materialId}`;
  const parameterQuery = parameter && `&Parameter=${parameter}`;
  const inputPricedQuery = inputPrice && `&inputPrice=${inputPrice}`;
  const outputPriceQuery = outputPrice && `&outputPrice=${outputPrice}`;
  const statusQuery = status && `&status=${status}`;
  const pageSizeQuery = pageSize && `&pageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const queries = [
    numberQuery,
    materialIdQuery,
    parameterQuery,
    inputPricedQuery,
    outputPriceQuery,
    statusQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);

  return http
    .get(`/Materials/grid?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllMaterials() {
  return http
    .get(`/Materials/allItems`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllParameters() {
  return http
      .get(`smartkasa/unittypes`)
      .then((response) => response)
      .catch((error) => error.response)
}
export function editMaterialById(id, data) {
  return http
    .put(`/Materials/${id}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function addNewMaterial(data) {
  return http
    .post("/Materials", data)
    .then((response) => response)
    .catch((error) => error.response);
}
export function deleteMaterial(id) {
  return http
    .delete(`/Materials/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}
export function restoreMaterial(id) {
  return http
    .put(`/Materials/restore/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllMaterialsForPdf(url,name) {
  return http
      .get(`/Materials/${name}${url}&PageSize=999&`)
      .then((response) => response)
      .catch((error) => error.response);
}
