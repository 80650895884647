import React from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import columns from "../columns";
import GeneralPage from "../../GeneralPage";
import Modal from "../../../components/Modal";
import {
  openModal,
  closeModal,
  getOrder,
  toggleVisibleDeleteModal,
  openModalNotes,
  closeModalNotes,
} from "../../../redux/orders";
import { getAllCashiers } from "../../../redux/employee";
import { setPageNumber } from "../../../redux/pagination";
import SingleOrderItem from "./SingleOrderItem";
import { exportPDF } from "../../../components/ExportToPdf";
import { roleNames } from "../../../roles";
import DeleteOrderItemForm from "./DeleteOrderItemForm";
import http from "../../../../src/http";

const headers = [
  ["Товар", "Кiлькiсть", "Цiна", "Знижка", "Сума", "Вiдповiдальний"],
];

const constants = {
  btnPrint: "Друк",
  deleteBtn: "Видалити",
  modalTitle: "Редагувати замовлення",
  orderNumberTitle: "Номер замовлення:",
};

class OrderDetails extends GeneralPage {
  state = {
    deleteModalVisible: false,
  };
  pageTitle = "Історія замовлень";
  subTittle = "/ Детальна інформація";
  class = "order-history-details";
  columns = columns.details;

  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
      getData,
      getAllCashiers,
      allCashiers,
    } = this.props;
    !allCashiers.length && getAllCashiers();
    getData(id);
  };

  handleExportPDF = (data) => {
    const { orderNumber } = this.props
    const productName = (serviceName, materialName) => {
      return serviceName ? replace(serviceName) : replace(materialName);
    };
    const replace = (value) => {
      return value.replace("і", "i");
    };
    const finalData = data.map((elt) => [
      productName(elt.serviceName, elt.materialName),
      elt.amount,
      elt.price,
      elt.discount,
      elt.total,
      replace(elt.employeeFullName),
    ]);
    exportPDF({
      tableData: finalData, header: headers,
      contentBefore: `Номер замовлення ${orderNumber}`});
  };



  handleDeleteOrder = () => this.props.toggleVisibleDeleteModal(true);

  closeModal = () => this.props.toggleVisibleDeleteModal(false);

  submitNotesHandler = async () => {
    await http.put(`ordershistory/updatenote`, {
      id: this.props.orderUniqueId.id,
      note: this.state.createNoteValue,
    });
    this.props.closeNotes();
    this.props.getData(this.props.match.params.id);
  };

  additionalComponentsTops = () => {
    const { userRole, orderNumber, deleteModalVisible, notes} = this.props;
    return (
      <div className="order-history-details__btn-container">
        <div className="order-history-details__id">
          {constants.orderNumberTitle} {orderNumber}
        </div>
        <div>
          {!notes ? (
            <div className="btn-addNotes">
              <button
                onClick={() =>
                  this.props.openNotes(this.props.match.params.id, this.props.notes  )
                }
              >
                Додати примітку
              </button>
            </div>
          ) : <div className="btn-addNotes">
            <button
                onClick={() =>{
                  this.setState({createNoteValue: this.props.notes})
                  this.props.openNotes(this.props.match.params.id, this.props.notes )
                }
                }
            >
              Редагувати примітку
            </button>
          </div>}
          <div className="btn-print">
            <button onClick={() => this.handleExportPDF(this.props.data)}>
              {constants.btnPrint}
            </button>
          </div>
          {userRole === roleNames.superAdmin && (
            <div className="btn-delete">
              <Button type="danger" onClick={() => this.handleDeleteOrder()}>
                {constants.deleteBtn}
              </Button>
            </div>
          )}
        </div>
        {deleteModalVisible && (
          <Modal
            visible={deleteModalVisible}
            onCancel={this.closeModal}
            footer={null}
            className="order-history-details__delete-order"
            width={500}
          >
            <DeleteOrderItemForm />
          </Modal>
        )}
      </div>
    );
  };

  additionalComponents = () => {
    const { visible, closeModal, notesVisible } = this.props;
    const pathname = this.props.location.pathname;
    return (
      <>
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="order-history-details"
            width={1400}
          >
            <h3 className="order-history-details__title">
              {constants.modalTitle}
            </h3>
            <SingleOrderItem pathname={pathname} />
          </Modal>
        )}
        {notesVisible && (
          <Modal
            visible={notesVisible}
            onCancel={() => {
              this.props.closeNotes();
            }}
            footer={null}
            className="order-history-details"
            width={700}
          >
            <div className="createNoteContainer">
              <input
                type="text"
                className="createNoteInput"
                value={this.state.createNoteValue}
                onChange={(e) =>
                  this.setState({ createNoteValue: e.target.value })
                }
              ></input>
              <span
                className="createNoteButton"
                onClick={() => this.submitNotesHandler()}
              >
                Зберегти
              </span>
            </div>
          </Modal>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  notes: state.orders.dataForEditSingle.notes,
  orderUniqueId: state.orders.currentId,
  data: state.orders.details,
  visible: state.orders.visible,
  loading: state.orders.loading,
  orderId: state.orders.currentId.orderId,
  userRole: state.login.user.role,
  currentUser: state.login.user.id,
  lastPaid: state.orders.lastPaid,
  orderNumber: state.orders.dataForEditSingle.orderNumber,
  historyId: state.orders.dataForEditSingle.id,
  allCashiers: state.employees.allCashiers,
  notesVisible: state.orders.notesVisible,
  deleteModalVisible: state.orders.deleteModalVisible
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  getData: getOrder,
  setPageNumber,
  getAllCashiers,
  toggleVisibleDeleteModal,
  openNotes: openModalNotes,
  closeNotes: closeModalNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
