import http from '../index';
import { tablePageSize } from '../../helpers/constants';

export function fetchPayDesks({ pageSize = tablePageSize, pageNumber=1 }) {
    const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
    const pageNumberQuery = pageNumber && `&PageNumber=${pageNumber}`;
    const queries = [pageSizeQuery, pageNumberQuery];
    const url = queries.filter(query => query).join('');


    return http.get(`/paydesks/grid-paydesk?${url}`)
        .then(response => response)
        .catch(error => error.response);
};

export function addNewPayDesk(data) {
    const {name, terminalId, terminalName, fiscalNumber, registrarFiscalNumber, shopId} = data;
    const paydeskData = {
        name,
        terminalId,
        terminalName,
        fiscalNumber,
        registrarFiscalNumber,
        shopId
    }

    return http.post('/paydesks', paydeskData , {
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    })
    .then(response => response)
    .catch(error => error.response);
};

export function getAllPayDesk() {
    return http.get('/paydesks')
        .then(response => response)
        .catch(error => error.response);
};

export function fetchAllTerminal() {
    return http
        .get('/smartkasa/terminals')
        .then(response => response)
        .catch(error => error.response);
}

export function deletePayDesk(id) {
    return http.delete(`/Paydesks/${id}`)
        .then(response => response)
        .catch(error => error.response);
};

export function restorePayDesk(id) {
  return http.put(`/Paydesks/restore/${id}`)
      .then(response => response)
      .catch(error => error.response);
};

export function editPayDeskById(id, data) {
    const {name, terminalId, terminalName, fiscalNumber, registrarFiscalNumber, shopId} = data;
    const paydeskData = {
        name,
        terminalId,
        terminalName,
        fiscalNumber,
        registrarFiscalNumber,
        shopId
    }

    return http.put(`/Paydesks/${id}`, paydeskData, {
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
        })
        .then(response => response)
        .catch(error => error.response);
};
