import React, { Component } from "react";
import Select, { components } from "react-select";
import { ClearIcon } from "../../../icons";
import { FixedSizeList as List } from "react-window";
import UpIcon from "../../../assets/images/icons/up-icon.png";

const DropdownIndicator = (props) => {
  return (
      <components.DropdownIndicator {...props}>
        <img src={UpIcon} alt="down-icon" />
      </components.DropdownIndicator>
  );
}
const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <ClearIcon />
  </components.ClearIndicator>
);

// const DopupIndicator = props => (
//   <components.DropdownIndicator {...props}>
//     <img src={DownIcon} alt="down-icon" />
//   </components.DropdownIndicator>
// );

const MenuList = ({ options, children, maxHeight, getValue, selectProps }) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * selectProps.height;
  return (
    <div>
      <List
        height={selectProps.maxHeight ? selectProps.maxHeight : maxHeight}
        itemCount={children.length}
        itemSize={selectProps.height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    </div>
  );
};

class SelectReact extends Component {
  className = "";
  label = "";
  name = "";
  placeholder = "Виберіть";
  maxHeight = 300;
  height = 25;
  state = {
    value: "",
  };

  renderOptions = () => false;
  getInitialValue = () => null;

  handleChange = (data) => {
    data
      ? this.props.getSelectedValue({ value: data.value }, this.name)
      : this.props.getSelectedValue({ value: "" }, this.name);
    this.setState({
      data,
    });
  };


  customFilter = (option, inputValue,) => {
    const errChar = /[\[\]?*+|{}\\()@.\n\r]/g
    const newReg = inputValue.replace(errChar, ' ')
    const reg = new RegExp(`${newReg}`,'gi')
    return reg.test(option.label?.props?.title || option?.label)
  }


  render() {
    const {
      input = [],
      label = "",
      required,
      className,
      ...props
    } = this.props;
    const requiredMark =
      required && label ? (
        <span className="c-form__field--required">*</span>
      ) : (
        ""
      );
    const val = input.value ? input.value : this.getInitialValue(input.value);

    return (
      <span className={`field ${this.className}`}>
        <label>
          {label}
          {requiredMark}
        </label>
        <Select
          className={`select table__filter-item order ${
            this.state.value && "filled"
          }`}
          classNamePrefix="select"
          options={this.renderOptions()}
          {...input}
          {...props}
          isClearable={true}
          placeholder={this.placeholder}
          filterOption={this.customFilter}
          onChange={this.handleChange}
          maxHeight={this.maxHeight}
          value={val}
          height={this.height}
          components={{
            DropdownIndicator,
            ClearIndicator,
            // DopupIndicator,
            MenuList,
          }}
          styles={{
            control: (base) => ({
              border: "none",
              outline: "none",
            }),
          }}
        />
      </span>
    );
  }
}

export default SelectReact;
