import { takeEvery, put, call } from 'redux-saga/effects';
import { SET_NOTIFICATION } from '../notification';
import { fetchAllCashier, fetchAllAccountantAndAdmins } from '../../http/employees';

const EMPLOYEES_LOADING = 'employees/EMPLOYEES_LOADING';
const EMPLOYEES_LOAD_FAILED = 'employees/EMPLOYEES_LOAD_FAILED';
const EMPLOYEES_GET_CASHIERS = 'employees/EMPLOYEES_GET_CASHIERS';
const SUCCESS_GET_CASHIERS = 'employees/SUCCESS_GET_CASHIERS';
const EMPLOYEES_GET_ACCOUNTANT_AND_ADMINS = 'employees/EMPLOYEES_GET_ACCOUNTANT_AND_ADMINS';
const SUCCESS_GET_ACCOUNTANT_AND_ADMINS = 'employees/SUCCESS_GET_ACCOUNTANT_AND_ADMINS';

const initialState = {
  allCashiers: [],
  allAccountantAndAdmins: [],
  loading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EMPLOYEES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case EMPLOYEES_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SUCCESS_GET_CASHIERS: {
      return {
        ...state,
        loading: false,
        allCashiers: action.data,
      };
    }
    case SUCCESS_GET_ACCOUNTANT_AND_ADMINS: {
      return {
        ...state,
        loading: false,
        allAccountantAndAdmins: action.data,
      };
    }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getAllCashiers = () => ({ type: EMPLOYEES_GET_CASHIERS });
export const getAllAccountantAbdAdmins = () => ({ type: EMPLOYEES_GET_ACCOUNTANT_AND_ADMINS });

//<<<WORKERS>>>
function* getAllDataCashiers() {
  yield put({ type: EMPLOYEES_LOADING })
  try {
    const response = yield call(fetchAllCashier);
    if (response.status === 200) {
      yield put({ type: SUCCESS_GET_CASHIERS, data: response.data })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: EMPLOYEES_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: EMPLOYEES_LOAD_FAILED })
    console.log(error)
  }
};

function* getDataAccountantsAndAdmins() {
  yield put({ type: EMPLOYEES_LOADING })
  try {
    const response = yield call(fetchAllAccountantAndAdmins);
    if (response.status === 200) {
      yield put({ type: SUCCESS_GET_ACCOUNTANT_AND_ADMINS, data: response.data })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: EMPLOYEES_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: EMPLOYEES_LOAD_FAILED })
    console.log(error)
  }
};


//<<<WATCHERS>>>
export function* watchEmployees() {
  yield takeEvery(EMPLOYEES_GET_CASHIERS, getAllDataCashiers);
  yield takeEvery(EMPLOYEES_GET_ACCOUNTANT_AND_ADMINS, getDataAccountantsAndAdmins);
}