import React from "react";
import { connect } from "react-redux";
import { Field, FormSpy } from "react-final-form";
import Form from "../../../components/Form";
import { OnChange } from "react-final-form-listeners";
import {
  createOrder,
  initialPaymentValues,
  addPaymentMethod,
  closeModal,
} from "../../../redux/createOrder";
import {
  TextField,
  PaymentMethodsReactSelect,
  CheckboxField,
  NumberMaskField,
} from "../../../components/Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
  isEmptyValue,
} from "../../../components/Form/validation";
import { withRouter } from "react-router-dom";
import { Tooltip, Modal, Input } from "antd";

const texts = {
  paymentMethods: "Спосіб оплати",
  FOP: "ФОП",
  received: "Отримано",
  btnText: "Видати товар",
  rest: "Решта",
  balance: "Баланс",
  conversionNumber: "Номер рахунку",
  errorMessage: "Фізична особа не можете оформити замовлення в борг",
  errorMessageProducts: "Ви не вибрали жодної послуги або матеріалу",
};

const paymentMethodsType = {
  cash: 1,
  cashless: 2,
  balance: 3,
  recalculation: 4,
};

class PaymentForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }
  title = "";
  onchange = null;

  confirmModalHandler = (finalData) => {
    const { createOrder } = this.props;
    const orderTotalDifference =
      this.props.currentClient.balance - +this.props.orderPrice;
    Modal.confirm({
      title: `Cума балансу ${
        this.props.currentClient.balance
      } грн., менше суми замовлення на ${
        orderTotalDifference.toString().startsWith("-")
          ? orderTotalDifference.toString().slice(1)
          : orderTotalDifference
      } грн. Продовжити операцію?`,
      okText: "Продовжити",
      cancelText: "Відхилити",
      onOk: function () {
        createOrder(finalData);
      },
    });
  };

  showModalError = () => {
    Modal.warning({
      title: `Сума замовлення менша за ${this.props.values.paid}`,
      closable: true,
      okButtonProps: { style: { display: "none" } },
      bodyStyle: { height: "10px" },
    });
  };

  onValidate = makeFormValidator({
    paymentMethodId: composeValidators({
      [validationTexts.required]: isEmptyValue,
    }),
    // paid: composeValidators({
    //   [validationTexts.required]: value => value === undefined || value.toString().trim().length === 0,
    //   [validationTexts.minValue('0')]: value => value ? value < 0 : false,
    // }),
    // transferNumber: composeValidators({
    //   [validationTexts.required]: (value) => {
    //     if (
    //       this.props.currentPaymentMethod.paymentMethodType ===
    //       paymentMethodsType.recalculation
    //     ) {
    //       return value === undefined || value.toString().trim().length === 0;
    //     } else {
    //       return false;
    //     }
    //   },
    // }),
  });

  onSubmit = (props) => {
    const {
      dataForCreateOrder,
      orderData: { number, orderNumber },
      currentClient: { id },
      createOrder,
      currentCashier,
      currentPaymentMethod,
    } = this.props;
    const finalData = {
      notes: this.state.value,
      employeeId: currentCashier,
      ...dataForCreateOrder,
      number,
      orderNumber,
      ...props,
      clientId: id,
      paid: props.paid ? props.paid : 0,
      onBalance: !props.onBalance ? false : props.onBalance,
      transferNumber:
        currentPaymentMethod.paymentMethodType ===
        paymentMethodsType.recalculation
          ? props.transferNumber
          : null,
    };

    if (currentPaymentMethod.paymentMethodType === 3) {
      if (+this.props.values.paid <= +this.props.orderPrice) {
        if (+this.props.values.paid > this.props.currentClient.balance) {
          this.confirmModalHandler(finalData);
        } else {
          createOrder(finalData);
        }
      } else {
        this.showModalError();
      }
    } else {
      createOrder(finalData);
    }
  };

  handleChangeReceived = (value) => {
    const {
      initialPaymentValues,
      dataForCreateOrder: { orderPrice },
    } = this.props;

    const data = {
      paid: value,
      rest: Number.parseFloat(value - orderPrice).toFixed(2),
    };
    initialPaymentValues(data);
  };

  handleChangeMethod = ({ value }) => {
    const {
      initialPaymentValues,
      addPaymentMethod,
      orderData: { paymentMethods },
    } = this.props;
    const currentData = paymentMethods.find((item) => item.id === value);
    addPaymentMethod(currentData);
    initialPaymentValues({ paymentMethodId: value });
  };

  handleChangeTransferNumber = (value) => {
    this.props.initialPaymentValues({ transferNumber: value });
  };

  renderFields = () => (
    <>
      <div className="c-form__item order-form__item">
        <FormSpy
          render={({ form: { change } }) => {
            this.onchange = change;
            const {
              values: { rest },
              currentPaymentMethod,
              orderPrice,
              currentClient,
              dataForCreateOrder,
            } = this.props;
            return (
              <>
                <Field
                  className="c-form__field"
                  spanClassName="order-form__product"
                  name="paymentMethodId"
                  component={PaymentMethodsReactSelect}
                  label={texts.paymentMethods}
                  onChange={this.handleChangeMethod}
                  required

                />
                {currentPaymentMethod &&
                  currentPaymentMethod.paymentMethodType ===
                    paymentMethodsType.recalculation && (
                    <>
                      <Field
                        className="c-form__field"
                        name="transferNumber"
                        component={TextField}
                        label={texts.conversionNumber}
                        required
                        spanClassName="transfer-number"
                      />
                      <OnChange name="transferNumber">
                        {(value) => (
                          <>
                            {change("transferNumber", value)}
                            {this.handleChangeTransferNumber(value)}
                          </>
                        )}
                      </OnChange>
                    </>
                  )}
                <Field
                  className="c-form__field"
                  name="paid"
                  component={NumberMaskField}
                  label={texts.received}
                  required
                />
                <OnChange name="paid">
                  {(value) => (
                    <>
                      {change("paid", value)}
                      {this.handleChangeReceived(value)}
                    </>
                  )}
                </OnChange>
                {currentPaymentMethod &&
                  currentPaymentMethod.paymentMethodType !==
                    paymentMethodsType.balance && (
                    <Field
                      className="c-form__field"
                      name="rest"
                      component={TextField}
                      label={texts.rest}
                      required
                      disabled
                    />
                  )}
                {rest > 0 &&
                  currentPaymentMethod &&
                  currentPaymentMethod.paymentMethodType !==
                    paymentMethodsType.balance &&
                  !currentClient.isDefault && (
                    <Field
                      className="c-form__field"
                      name="onBalance"
                      component={CheckboxField}
                      children={texts.balance}
                      type="checkbox"
                    />
                  )}
                <div className="create-order__result">
                  <div className="create-order__result-price">
                    сума: <span>{orderPrice}</span>
                  </div>
                  <div className="c-form__create-order-btn order-form__submit">
                    {(currentClient.isDefault && rest < 0) ||
                    (!dataForCreateOrder.services.length &&
                      !dataForCreateOrder.materials.length) ? (
                      <Tooltip
                        placement="topLeft"
                        title={`${
                          currentClient.isDefault && rest < 0
                            ? texts.errorMessage
                            : texts.errorMessageProducts
                        }`}
                      >
                        <button
                          className="c-button disabled"
                          type="submit"
                          disabled
                        >
                          {texts.btnText}
                        </button>
                      </Tooltip>
                    ) : (
                      <button className="c-button" type="submit">
                        {texts.btnText}{" "}
                      </button>
                    )}
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
      <div className="notes">
        <label htmlFor="notes">Примітки</label>
        <Field
            name="notes"
            component={Input}
            id="notes"
            onChange={(e) => this.setState({ value: e.target.value })}
            value={this.state.value}
            className="textareaInput"
            style={{ border: "2px solid #dde2ee", width: '70%', borderRadius:'30px'}}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  values: state.createOrder.initialValues.payment,
  dataForCreateOrder: state.createOrder.dataForCreateOrder,
  orderData: state.createOrder.orderData,
  currentPaymentMethod: state.createOrder.payment,
  currentClient: state.clients.currentClient,
  orderPrice: state.createOrder.dataForCreateOrder.orderPrice,
  currentCashier: state.login.user.id,
  visible: state.createOrder.visible,
});

const mapDispatchToProps = {
  createOrder,
  initialPaymentValues,
  addPaymentMethod,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentForm));
