import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import columns from './columns';
import GeneralPage from '../GeneralPage';
import { getPayDesksGrid, createNewPayDesk, closeModal, setInitialData, blockPayDesk, unlockPayDesk  } from '../../redux/paydesks';
import { setPageNumber } from '../../redux/pagination';
import PayDeskForm from './PayDeskForm';
import Modal from '../../components/Modal';

class Paydesks extends GeneralPage {

  pageTitle= "ФОП"
  class = "paydesks"
  columns = columns.general


  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  additionalComponentsTops = () => {
    const { createNewPayDesk } = this.props;
    return (
        <>
          <div className="cash-register-history__paydesk-balance default-btn">
            <h2 className="page-title">
              {this.pageTitle} <span>{this.subTittle}</span>
            </h2>
          </div>
          <h3 className="main-content__form-error">
            {this.props.notification?.text &&
            this.props.notification.text.messages?.[0]}
          </h3>
          <div className="btn-add">
            <Button onClick={createNewPayDesk}>+</Button>
          </div>
        </>

    )
  }

  additionalComponents = () => {
    const { visible, closeModal } = this.props;
    return (
      <>
        {visible &&
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className=""
            width={500}
          >
            <PayDeskForm />
          </Modal>
        }
      </>
    )
  }
}

const mapStateToProps = ({notification, paydesks, pagination, login }) => ({
  ...paydesks,
  data: paydesks.gridData,
  ...pagination,
  userRole: login.user.role,
  notification
});

const mapDispatchToProps = {
  getData: getPayDesksGrid,
  setPageNumber,
  createNewPayDesk,
  closeModal,
  setInitialData,
  blockItem: blockPayDesk,
  unblockItem: unlockPayDesk,
};

export default connect(mapStateToProps, mapDispatchToProps)(Paydesks);
