import React from "react";
import { Input } from "antd";

const SearchField = ({
  defaultValue,
  label,
  type = "",
  className,
  spanClass = "",
  onChange,
  placeholder = "Введіть",
}) => {
  // defaultValue && onChange({value: defaultValue }, 'orderNumber')
  return (
    <span className={`filter__search-field field ${spanClass}`}>
      <label>{label}</label>
      <div className="table__filter-item provider">
        <Input
          defaultValue={defaultValue}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
        />
      </div>
    </span>
  );
};

export default SearchField;
