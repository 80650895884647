import React from 'react';
import { Modal as AntModal } from 'antd'

const Modal = props => {
  return (
    <AntModal
      footer={false}
      {...props}
    >
      {props.children}
    </AntModal>
  );
};


export default Modal;