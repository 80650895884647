import SelectReact from '../index';
import { connect } from 'react-redux';
import { getAllPayDesks } from '../../../../../redux/paydesks';
import { generateSelectValue } from '../../../../../helpers';

class PaydeskReactSelect extends SelectReact {

  label = ''
  name = 'paydeskId'
  maxHeight = 150

  componentDidMount = () => this.props.getAllPayDesks();

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.id === value)
    if(currentOption) {
      return {value: currentOption.id, label: generateSelectValue({ value: currentOption.name })}
    } else {
      return false
    }
  }

  renderOptions = () => {
    const activePayDesks = this.props.data.filter(item => !item.isDeleted);
    return activePayDesks.map(({ id, name }) => ({ value: id, label: generateSelectValue({ value: name}) }));
  } 
}

const mapStateToProps = (state) => ({
  data: state.paydesks.data
});

const mapDispatchToProps = {
  getAllPayDesks,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaydeskReactSelect);

