import { takeEvery, put, call } from "redux-saga/effects";
import { SET_NOTIFICATION } from "../notification";
import { SET_PAGE_COUNT } from "../pagination";
import {
  fetchClients,
  editClientById,
  addNewClient,
  deleteClient,
  restoreClient,
  getMethodsClient,
  replenishBalanceClient,
  getOrderByClientId,
  getPaidHistory,
  fetchAllClients,
} from "../../http/clients";

const CLIENTS_LOAD = "clients/CLIENTS_LOAD";
const CLIENTS_LOADING = "clients/CLIENT_LOADING";
const CLIENTS_LOAD_FAILED = "clients/CLIENTS_LOAD_FAILED";
const CLIENT_MODAL_CLOSE = "clients/CLIENT_MODAL_CLOSE";
const CLIENT_MODAL_OPEN = "clients/CLIENT_MODAL_OPEN";
const CLIENTS_LOAD_SUCCESS = "clients/CLIENTS_LOAD_SUCCESS";
const CREATE_NEW_CLIENT = "clients/CREATE_NEW_CLIENT";
const CREATE_CLIENT = "clients/CREATE_CLIENT";
const BLOCK_CLIENT = "clients/BLOCK_CLIENT";
const RESTORE_CLIENT = "clients/RESTORE_CLIENT";
const CLIENT_ADD_SUCCESS = "clients/CLIENT_ADD_SUCCESS";
const CLIENT_EDIT = "clients/CLIENT_EDIT";
const CLIENT_SET_INITIAL = "clients/CLIENT_SET_INITIAL";
const CLIENT_REPLENISH_BALANCE = "clients/CLIENT_REPLENISH_BALANCE";
const CLIENT_PAYMENT_METHODS = "clients/CLIENT_PAYMENT_METHODS";
const METHODS_LOAD_SUCCESS = "clients/METHODS_LOAD_SUCCESS";
const CLIENTS_PAID_HISTORY_LOAD = "clients/CLIENTS_PAID_HISTORY_LOAD";
const CLIENTS_PAID_HISTORY_SUCCESS = "clients/CLIENTS_PAID_HISTORY_SUCCESS";
export const SET_CURRENT_CLIENT = "clients/SET_CURRENT_CLIENT";
const CLIENT_ORDERS_LOAD = "clients/CLIENT_ORDERS_LOAD";
const CLIENT_ORDERS_LOAD_SUCCESS = "clients/CLIENT_ORDERS_LOAD_SUCCESS";
const CLIENTS_ITEMS_LOAD_SUCCESS = "clients/CLIENTS_ITEMS_LOAD_SUCCESS";
const CLIENTS_ITEMS_LOAD = "clients/CLIENTS_ITEMS_LOAD";
const SET_QUERIES_DATA = "clients/SET_QUERIES_DATA";
const SET_CURRENT_DATA_CLIENT = "client/SET_CURRENT_DATA_CLIENT";

const initialState = {
  initialValues: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    id: "",
    clientId: "",
    number: "",
  },
  currentClient: {
    id: null,
  },
  data: [],
  ordersData: [],
  activeData: [],
  orders: [],
  allData: [],
  ordersDetails: [],
  methodsData: {
    paymentMethods: [],
    paydesks: [],
  },
  queriesData: {},
  loading: false,
  visible: false,
  currentId: null,
  create: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case CLIENTS_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case CLIENT_MODAL_OPEN: {
      return {
        ...state,
        visible: true,
        currentId: action.id,
      };
    }
    case SET_QUERIES_DATA: {
      return {
        ...state,
        queriesData: action.data,
      };
    }
    case CLIENT_MODAL_CLOSE: {
      return {
        ...state,
        currentId: "",
        visible: false,
        create: false,
      };
    }
    case CLIENTS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.items,
      };
    }
    case CLIENT_ORDERS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        ordersData: action.data.items,
        totalAmount: action.data.totalAmount,
        totalDebt: action.data.totalDebt,
        totalPaidSum: action.data.totalPaidSum,
        currentClient: {
          ...state.currentClient
        },
      };
    }

    case METHODS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        methodsData: action.data,
      };
    }
    case CLIENTS_ITEMS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        allData: action.data,
      };
    }
    case CREATE_NEW_CLIENT: {
      return {
        ...state,
        loading: false,
        visible: true,
        create: true,
        initialValues: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          id: "",
          clientId: "",
        },
      };
    }
    case CLIENT_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        visible: false,
        currentId: null,
      };
    }
    case CLIENT_SET_INITIAL: {
      return {
        ...state,
        visible: true,
        loading: false,
        initialValues: action.data,
      };
    }
    case CLIENTS_PAID_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        ordersDetails: action.data,
      };
    }
    case SET_CURRENT_CLIENT: {
      return {
        ...state,
        currentClient: action.data,
      };
    }
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getAllClients = (queries, pageNumber) => ({
  type: CLIENTS_LOAD,
  queries,
});
export const getAllOrdersByClientId = (queries) => ({
  type: CLIENT_ORDERS_LOAD,
  queries,
});
export const getAllPaidHistory = (id) => ({
  type: CLIENTS_PAID_HISTORY_LOAD,
  id,
});
export const openModal = (id) => ({ type: CLIENT_MODAL_OPEN, id });
export const closeModal = () => ({ type: CLIENT_MODAL_CLOSE });
export const createNewClient = () => ({ type: CREATE_NEW_CLIENT });
export const createClient = (data, pageNumber, queriesData) => ({
  type: CREATE_CLIENT,
  data,
  pageNumber,
  queriesData,
});
export const blockUser = (id, pageNumber, queriesData) => ({
  type: BLOCK_CLIENT,
  id,
  pageNumber,
  queriesData,
});
export const unlockUser = (id, pageNumber, queriesData) => ({
  type: RESTORE_CLIENT,
  id,
  pageNumber,
  queriesData,
});
export const editClient = (id, data, pageNumber, queriesData) => ({
  type: CLIENT_EDIT,
  id,
  data,
  pageNumber,
  queriesData,
});
export const setInitialData = (data) => ({ type: CLIENT_SET_INITIAL, data });
export const getPaymentMethods = () => ({ type: CLIENT_PAYMENT_METHODS });
export const replenishBalance = (data, pageNumber, queriesData) => ({
  type: CLIENT_REPLENISH_BALANCE,
  data,
  queriesData,
});
export const setCurrentClient = (data) => ({ type: SET_CURRENT_CLIENT, data });
export const getClients = () => ({ type: CLIENTS_ITEMS_LOAD });
export const setQueriesData = (data) => ({ type: SET_QUERIES_DATA, data });
export const setCurrentDataClient = (clientId) => ({
  type: SET_CURRENT_DATA_CLIENT,
  clientId,
});

//<<<WORKERS>>>

function* getALLData({ queries = {} }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(fetchClients, queries);
    if (response.status === 200) {
      yield put({ type: CLIENTS_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* setCurrentData({ clientId }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(fetchClients, { clientId });
    if (response.status === 200) {
      yield put({ type: SET_CURRENT_CLIENT, data: response.data.items[0] });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* getAllItemsData() {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(fetchAllClients);
    if (response.status === 200) {
      yield put({ type: CLIENTS_ITEMS_LOAD_SUCCESS, data: response.data });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* getDataByID({ queries = {} }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(getOrderByClientId, queries);
    if (response.status === 200) {
      yield put({ type: CLIENT_ORDERS_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* getHistory({ id }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(getPaidHistory, id);
    if (response.status === 200) {
      yield put({ type: CLIENTS_PAID_HISTORY_SUCCESS, data: response.data });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* getPaymentMethodsData() {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(getMethodsClient);
    if (response.status === 200) {
      yield put({ type: METHODS_LOAD_SUCCESS, data: response.data });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* addData({ data, pageNumber, queriesData }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(addNewClient, data);
    if (response.status === 200) {
      yield put({ type: CLIENT_ADD_SUCCESS });
      yield put({
        type: CLIENTS_LOAD,
        queries: { pageNumber, ...queriesData },
      });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* addBalance({ data, pageNumber, queriesData }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(replenishBalanceClient, data);
    if (response.status === 200) {
      yield put({ type: CLIENT_ADD_SUCCESS });
      yield put({
        type: CLIENTS_LOAD,
        queries: { pageNumber, ...queriesData },
      });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* blockData({ id, pageNumber, queriesData }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(deleteClient, id);
    if (response.status === 200) {
      yield put({
        type: CLIENTS_LOAD,
        queries: { pageNumber, ...queriesData },
      });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* restoreData({ id, pageNumber, queriesData }) {
  yield put({ type: CLIENTS_LOADING });
  try {
    const response = yield call(restoreClient, id);
    if (response.status === 200) {
      yield put({
        type: CLIENTS_LOAD,
        queries: { pageNumber, ...queriesData },
      });
    } else {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
    }
    if (response.status >= 400) {
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    yield put({ type: CLIENTS_LOAD_FAILED });
    console.log(error);
  }
}

function* editData({ id, data, pageNumber, queriesData }) {
  try {
    yield put({ type: CLIENTS_LOADING });
    const response = yield call(editClientById, id, data);
    if (response.status === 200) {
      yield put({ type: CLIENT_ADD_SUCCESS });
      yield put({
        type: CLIENTS_LOAD,
        queries: { pageNumber, ...queriesData },
      });
    }
    if (response.status >= 400) {
      yield put({
        type: SET_NOTIFICATION,
        notification: { text: response.data, error: true },
      });
      yield put({ type: CLIENTS_LOAD_FAILED });
    }
  } catch (error) {
    console.log(error);
  }
}

//<<<WATCHERS>>>
export function* watchClients() {
  yield takeEvery(CLIENTS_LOAD, getALLData);
  yield takeEvery(CLIENT_ORDERS_LOAD, getDataByID);
  yield takeEvery(CREATE_CLIENT, addData);
  yield takeEvery(BLOCK_CLIENT, blockData);
  yield takeEvery(RESTORE_CLIENT, restoreData);
  yield takeEvery(CLIENT_EDIT, editData);
  yield takeEvery(CLIENT_PAYMENT_METHODS, getPaymentMethodsData);
  yield takeEvery(CLIENT_REPLENISH_BALANCE, addBalance);
  yield takeEvery(CLIENTS_PAID_HISTORY_LOAD, getHistory);
  yield takeEvery(CLIENTS_ITEMS_LOAD, getAllItemsData);
  yield takeEvery(SET_CURRENT_DATA_CLIENT, setCurrentData);
}
