const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'productName',
      Header: 'Товар/послуга',
      minWidth: 200,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'amount',
      Header: 'Кількість',
      minWidth: 100,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'cost',
      Header: 'Вартісь',
      minWidth: 100,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'isMaterial',
      Header: 'Тип',
      minWidth: 200,
      sortable: false,
      filterable: false,
      formatter: 'productType'
    },
  ],
};

export default columns;