import React from 'react';
import { Checkbox  } from 'antd';
import { getInputClass } from '../helpers';

import './style.scss';

const CheckboxField = ({ input, meta, label="", ...props }) => {
  const className = getInputClass({ meta, value: input.value })
  return (
    <span className={`c-form__checkbox ${className}`}>
      <Checkbox 
        {...input}
        {...props}
      />
      <div className="ant-form-explain">
        {meta.touched ? meta.error : ''}
      </div>
    </span>
  );
};


export default CheckboxField;