import SelectReact from '../index';
import { connect } from 'react-redux';
import { getPaymentMethods } from '../../../../../redux/clients';
import { generateSelectValue } from '../../../../../helpers';

class EntrepreneurReactSelect extends SelectReact {

  label = ''
  name = 'paydeskId'
  maxHeight = 150

  componentDidMount = () => this.props.getPaymentMethods();

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.id === value)
    if(currentOption) {
      return {value: currentOption.id, label: generateSelectValue({ value: currentOption.name })}
    } else {
      return false
    }
  }

  renderOptions = () => this.props.data.map(({ id, name }) => ({ value: id, label: generateSelectValue({ value: name}) }));
}

const mapStateToProps = (state) => ({
  data: state.clients.methodsData.paydesks,
});

const mapDispatchToProps = {
  getPaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneurReactSelect);

