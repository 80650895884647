import React from "react";

const transactionsType = {
  order: 1,
  return: 2,
  replenishment: 3,
};

export const handleShowTransactionType = (value) => {
  if (value === transactionsType.order) {
    return "Замовлення";
  } else if (value === transactionsType.return) {
    return "Повернення";
  } else if (value === transactionsType.replenishment) {
    return "Поповнення";
  }
};

export const transactionsFormatter = (value) => (
  <div>{handleShowTransactionType(value)}</div>
);
