const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: "name",
      Header: "Назва ФОП",
      minWidth: 400,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "cashBalance",
      Header: "Баланс",
      minWidth: 400,
      filterable: false,
      sortable: false,
      formatter: "currency",
    },
    {
      accessor: "edit",
      Header: "Інкасація",
      minWidth: 200,
      formatter: "collectionAction",
      sortable: false,
      filterable: false,
    },
  ],
};

export default columns;
