import React from "react";
import { connect } from "react-redux";
import columns from "../../columns";
import GeneralPage from "../../../GeneralPage";
import { setPageNumber } from "../../../../redux/pagination";
import { getDataForCreateOrder } from "../../../../redux/createOrder";
import {
  closeModal,
  setDataForEditSingleOrder,
  editSingleOrder,
} from "../../../../redux/orders";

class SingleOrderItem extends GeneralPage {
  class = "order-history-details-single";
  columns = columns.single;
  componentDidMount = () => null;

  handleEdit = () => {
    const { editSingleData, editSingleOrder, closeModal } = this.props;
    editSingleData.change ? editSingleOrder(editSingleData) : closeModal();
  };

  additionalComponents = () => {
    return (
      <div className="order-history-details-single__save-btn">
        <button onClick={this.handleEdit}>Зберегти</button>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  data: [state.orders.currentId],
  orderData: state.createOrder.orderData,
  editSingleData: state.orders.dataForEditSingle,
  historyId: state.orders.dataForEditSingle.id,
});

const mapDispatchToProps = {
  setPageNumber,
  getDataForCreateOrder,
  closeModal,
  editData: setDataForEditSingleOrder,
  editSingleOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleOrderItem);
