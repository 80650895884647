import SelectReact from '..';

class OrderStatusSelect extends SelectReact {

  // handleChange = () => null

  label = ''
  name = 'status'
  maxHeight = 250;
  className='client-status'

  options = [
    {
      id: 1,
      value: 'Paid',
      name: 'оплачено'
    },
    {
      id: 2,
      value: 'NotPaid',
      name: 'не оплачено'
    },
    {
      id: 3,
      value: 'PartialPaid',
      name: 'частково'
    }
  ]

  getInitialValue = value =>  {
    const currentOption = this.options &&  this.options.find(item => item.value === this.props.value)
    return currentOption ? {value: currentOption.value, label: currentOption.name} : null
  }

  renderOptions = () => this.options.map(({ value, name }) => ({ value: value, label: name }));

}

export default OrderStatusSelect;
