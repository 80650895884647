import http from "../index";
import { tablePageSize } from "../../helpers/constants";

export function fetchServices({
  serviceId = "",
  parameter = "",
  price = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const nameQuery = serviceId && `name=${serviceId}`;
  const parameterQuery = parameter && `&parameter=${parameter}`;
  const priceSizeQuery = price && `&Price=${price}`;
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&PageNumber=${pageNumber}`;
  const queries = [
    nameQuery,
    parameterQuery,
    priceSizeQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  return http
    .get(`/Services/grid?${url}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllServices() {
  return http
    .get(`/Services/allItems`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function editServiceById(id, data) {
  return http
    .put(`/Services/${id}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}
export function fetchAllClientsBalanceHistoryForPdf(url,name) {
  return http
      .get(`/ClientsBalanceHistory/${name}${url}&PageSize=999&`)
      .then((response) => response)
      .catch((error) => error.response);
}

export function addNewService(data) {
  return http
    .post("/Services", data)
    .then((response) => response)
    .catch((error) => error.response);
}
export function deleteService(id) {
  return http
    .delete(`/Services/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}
export function restoreService(id) {
  return http
    .put(`/Services/restore/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}
