import SelectReact from '..';
import { connect } from 'react-redux';
import { getAllCashiers } from '../../../../../redux/employee';
import { generateSelectValue } from '../../../../../helpers';

class CashierReactSelect extends SelectReact {

  label = ''
  name = 'employeeId'
  maxHeight = 150
  spanClassName = 'employee-select'

  componentDidMount = () => !this.props.data.length && this.props.getAllCashiers();

  getInitialValue = value =>  {
    const currentOption = this.props.data.find(item => item.id === value)
    return currentOption ? {value: currentOption.value, label: generateSelectValue({value: currentOption.fullName, size: this.props.selectValueSize })} : null
  }
  
  renderOptions = () => this.props.data.map(({ id, fullName }) => ({ value: id, label: generateSelectValue({value: fullName, size: this.props.selectValueSize }) }));
  
}

const mapStateToProps = state => ({
  data: state.employees.allCashiers
})

const mapDispatchToProps = {
  getAllCashiers
}

export default  connect(mapStateToProps, mapDispatchToProps)(CashierReactSelect) ;