import SelectReact from "../index.js";
import { connect } from "react-redux";
import { getDataForCreateOrder } from "../../../../../redux/createOrder";
import { generateSelectValue } from "../../../../../helpers/index.js";

class MaterialSelect extends SelectReact {
  componentDidMount = () =>
    !this.props.data.length && this.props.getDataForCreateOrder();

  label = "";
  name = "materialId";
  maxHeight = 150;
  spanClassName = "material-select";

  getInitialValue = (value) => {
    const currentOption =
      this.props.data && this.props.data.find((item) => item.id === value);
    return currentOption
      ? {
          value: currentOption.id,
          label: generateSelectValue({
            value: currentOption.name,
            size: this.props.selectValueSize,
          }),
        }
      : null;
  };

  renderOptions = () =>
    this.props.data.map(({ id, name }) => ({
      value: id,
      label: generateSelectValue({
        value: name,
        size: this.props.selectValueSize,
      }),
    }));
}

const mapStateToProps = (state) => ({
  data: state.createOrder.orderData.materials,
});

const mapDispatchToProps = {
  getDataForCreateOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSelect);
