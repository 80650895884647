import React from "react";
import queryString from "query-string";
import { Spin } from "antd";
import { connect } from "react-redux";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import { getDataForCreateOrder } from "../../redux/createOrder";
import {
  getAllPaydesksHistory,
  closeModal,
  openModal,
  getDataByIdForCollection, resetPaydesk,
} from "../../redux/collections";
import { setPageNumber } from "../../redux/pagination";
import CashierSelect from "../../components/GeneralFields/Select/СashierSelect";
import ClientsSelect from "../../components/GeneralFields/Select/ClientsSelect";
import PaymentMethodsSelect from "../../components/GeneralFields/Select/PaymentMethodsSelect";
import EntrepreneursSelect from "../../components/GeneralFields/Select/EntrepreneursSelect";
import SearchField from "../../components/GeneralFields/SearchField";
import DateRangePicker from "../../components/GeneralFields/RangePicker";
import SortField from "../../components/GeneralFields/SortField";
import Modal from "../../components/Modal";
import ModalEntrepreneursSelect from "../../components/GeneralFields/Select/ModalEntrepreneursSelect";

const constants = {
  format: "YYYY-MM-DD",
  optionOperationStartDate: "operationStartDate",
  optionOperationEndDate: "operationEndDate",
  cashHistoryFilterOrderId: "orderNumber",
  cashHistoryFilterSum: "Sum",
  cashHistoryFilterTransferNumber: "transferNumber",
  cashHistoryFilterPaydeskId: "paydeskId",
  cashHistoryPaymentMethodId: "paymentMethodId",
  cashHistoryFilterClientId: "clientId",
  cashHistoryFilterCashierId: "cashierId",
  btnText: "Баланс каси",
  modalTitle: "Баланс в касі",
  totalAmount: "Загальна сума:",
  totalCash: "Готівка",
  totalCashless: "Безготівка:",
};

class CashRegisterHistory extends GeneralPage {
  pageTitle = "Історія каси";
  class = "cash-register-history";
  columns = columns.general;

  state = {
    name: undefined,
    sort: {},
  };

  componentDidMount = () => {
    const { getData, getDataForCreateOrder, orderData } = this.props;
    const query = queryString.parse(this.props.location.search);
    !orderData.services.length && getDataForCreateOrder();
    let sort = {};
    for (let property in query) {
      if (property === constants.cashHistoryFilterSum) {
        sort[property] = query[property];
      }
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  getDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.optionOperationStartDate] = "";
      changeData[constants.optionOperationEndDate] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionOperationStartDate] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionOperationEndDate] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        <SearchField
          defaultValue={query[constants.cashHistoryFilterOrderId]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.cashHistoryFilterOrderId
            )
          }
        />
        <EntrepreneursSelect
          value={query[constants.cashHistoryFilterPaydeskId]}
          label=""
          getSelectedValue={this.getFilterValue}
          name="paydeskId"
        />
        <DateRangePicker
          defaultValue={[
            query[constants.optionOperationStartDate],
            query[constants.optionOperationEndDate],
          ]}
          onChange={this.getDate}
        />
        <CashierSelect
          value={query[constants.cashHistoryFilterCashierId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
        />
        <ClientsSelect
          value={query[constants.cashHistoryFilterClientId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={19}
        />
        <PaymentMethodsSelect
          value={query[constants.cashHistoryPaymentMethodId]}
          label=""
          getSelectedValue={this.getFilterValue}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.cashHistoryFilterSum}
          value={query[constants.cashHistoryFilterSum]}
        />
        <SearchField
          defaultValue={query[constants.cashHistoryFilterTransferNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.cashHistoryFilterTransferNumber
            )
          }
        />
      </>
    );
  };

  additionalComponentsTops = () => (
    <div className="cash-register-history__paydesk-balance default-btn">
      <h2 className="page-title">
        {this.pageTitle} <span>{this.subTittle}</span>
      </h2>
      <button onClick={this.props.openModal}>{constants.btnText}</button>
    </div>
  );

  handleGetBalance = (option, name = "") => {
    const {
      getDataByIdForCollection,
    } = this.props;

      const value = option ? option.value : undefined;

      this.setState(
          (prevState) => ({
            ...prevState,
            [name]: value,
          }),
          () => {
            if (value) {
              getDataByIdForCollection(value);
            } else {
              this.props.resetPaydesk()
            }
          }
      );
  }

  additionalComponents = () => {
    const {
      visible,
      closeModal,
      loading,
      paydeskBalance,
      currentPaydesk,
      totalBalance,
      totalCash,
      totalCashless,
    } = this.props;
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        {query[constants.optionOperationStartDate] &&
          query[constants.optionOperationEndDate] && (
            <div className="cash-register-history__balance-block">
              <p>
                {constants.totalAmount} <span>{totalBalance} uaн.</span>
              </p>
              <p>
                {constants.totalCash} <span>{totalCash} uaн.</span>
              </p>
              <p>
                {constants.totalCashless} <span>{totalCashless} uaн.</span>
              </p>
              <p>
                Період від{" "}
                <span>{query[constants.optionOperationStartDate]}</span> до{" "}
                <span>{query[constants.optionOperationEndDate]}</span>
              </p>
            </div>
          )}
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="cash-register-history__modal"
            width={500}
          >
            <div className="cash-register-history__modal-content">
              {loading ? (
                <Spin />
              ) : (
                <>
                  <h3>
                    {constants.modalTitle}: <span>{paydeskBalance} uaн</span>
                  </h3>
                  <div>
                    <ModalEntrepreneursSelect
                      label="Виберіть ФОП"
                      getSelectedValue={this.handleGetBalance}
                      value={currentPaydesk.id}
                      name="paydeskId"
                    />
                  </div>
                </>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  };
}

const mapStateToProps = ({ collections, pagination, createOrder }) => ({
  data: collections.paydeskData,
  loading: collections.loading,
  visible: collections.visible,
  totalBalance: collections.totalBalance,
  totalCash: collections.totalCash,
  totalCashless: collections.totalCashless,
  ...pagination,
  orderData: createOrder.orderData,
  paydeskBalance: collections.currentPaydesk.paydesk.balance,
  currentPaydesk: collections.currentPaydesk.paydesk,
});

const mapDispatchToProps = {
  getData: getAllPaydesksHistory,
  setPageNumber,
  getDataForCreateOrder,
  closeModal,
  openModal,
  getDataByIdForCollection,
  resetPaydesk
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashRegisterHistory);
