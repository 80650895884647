const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'productName',
      Header: 'Товар',
      minWidth: 250,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'amount',
      Header: 'Кількість',
      minWidth: 250,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'cost',
      Header: 'Сума',
      minWidth: 250,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
  ],
};

export default columns;