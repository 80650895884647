import React from 'react';
import CashierSelectForOrder from '../../components/GeneralFields/Select/Odrer/CashierSelectForOrder'


export const orderSingleEmployee = ({id, value, currentProductId, currentProduct, editData}) => {
  const onChange = changeData => {
    if(!changeData) return null
    editData({
      id,
      employeeId: changeData.value,
      entityId: currentProductId,
      employeeName: changeData.label,
      entityType: currentProduct
    })
  };

  return (
    <div
      contentEditable
      suppressContentEditableWarning
    >
      <CashierSelectForOrder value={value} onChange={onChange} />
    </div>
  );
}