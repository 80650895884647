import http from "../index";
import { generateSortParams } from "../../helpers";
import { tablePageSize } from "../../helpers/constants";

export function fetchComings({
  number = "",
  provider = "",
  materialId = "",
  startDate = "",
  endDate = "",
  amount = "",
  sort,
  inputPrice = "",
  outputPrice = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const numberQuery = number && `number=${number}`;
  const providerQuery = provider && `&provider=${provider}`;
  const materialIdQuery = materialId && `&materialId=${materialId}`;
  const amountQuery = amount && `a&mount=${amount}`;
  const startDateQuery = startDate && `&StartDate=${startDate}`;
  const endDateQuery = endDate && `&EndDate=${endDate}`;
  const inputPriceQuery = inputPrice && `&inputPrice=${inputPrice}`;
  const outputPriceQuery = outputPrice && `&outputPrice=${outputPrice}`;
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const queries = [
    numberQuery,
    providerQuery,
    materialIdQuery,
    startDateQuery,
    endDateQuery,
    amountQuery,
    inputPriceQuery,
    outputPriceQuery,
    pageSizeQuery,
    pageNumberQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);

  return http
    .get(`/Comings/grid?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllComings(queryString) {
  console.log(`/comings/grid-not-page${queryString ? `?${queryString}` : ""}`);
  return http
    .get(`/comings/grid-not-page${queryString ? `?${queryString}` : ""}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function editComingById(id, data) {
  return http
    .put(`/Comings/${id}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function addNewComing(data) {
  return http
    .post("/Comings", data)
    .then((response) => response)
    .catch((error) => error.response);
}
export function deleteMaterialFromComing(id, materialId) {
  return http
    .delete(`/Comings/deleteMaterial?id=${id}&materialId=${materialId}`)
    .then((response) => response)
    .catch((error) => error.response);
}
export function deleteComing(id) {
  return http
    .delete(`/Comings/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}
export function restoreComing(id) {
  return http
    .put(`/Comings/restore/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchAllComingsForPdf(url,name) {
  return http
      .get(`/Comings/${name}${url}&PageSize=999&`)
      .then((response) => response)
      .catch((error) => error.response);
}
