const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: null,
      Header: null,
      minWidth: 15,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "number",
      Header: "Клієнт Id",
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "firsName",
      Header: "Клієнт",
      minWidth: 400,
      sortable: false,
      filterable: false,
      formatter: "name",
      className: "left",
    },
    {
      accessor: "balance",
      Header: "Баланс",
      minWidth: 100,
      filterable: false,
      sortable: false,
      formatter: "balance",
    },
    {
      accessor: "isPaid",
      Header: "Статус",
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: "clientPaymentStatus",
    },
    {
      accessor: "debt",
      Header: "Борг по замовленнях",
      minWidth: 210,
      filterable: false,
      sortable: false,
      formatter: "currency",
    },
    {
      accessor: "replenishBalance",
      Header: "Поповнити баланс",
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: "replenishBalance",
    },
    {
      accessor: "allOrders",
      Header: "Всі замовлення",
      minWidth: 200,
      sortable: false,
      filterable: false,
      formatter: "allOrders",
    },
    {
      accessor: "isDeleted",
      Header: "",
      minWidth: 50,
      formatter: "blockUnblock",
      sortable: false,
      filterable: false,
    },
    {
      accessor: "edit",
      Header: "",
      minWidth: 50,
      formatter: "edit",
      sortable: false,
      filterable: false,
    },
  ],
  orders: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: "orderNumber",
      Header: "Номер",
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: "orderLinkName",
    },
    {
      accessor: "orderCreationDate",
      Header: "Дата замовлення",
      minWidth: 130,
      filterable: true,
      sortable: false,
      formatter: "date",
    },
    {
      accessor: "lastPaidDate",
      Header: "Дата оплати",
      minWidth: 130,
      sortable: false,
      filterable: true,
      formatter: "date",
    },
    {
      accessor: "paymentMethodName",
      Header: "Спосіб оплати",
      minWidth: 100,
      sortable: false,
      filterable: true,
    },
    {
      accessor: "transferNumber",
      Header: "Номер рахунку",
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      accessor: "amount",
      Header: "Сума",
      minWidth: 100,
      sortable: false,
      filterable: true,
      // formatter: 'orderAmount'
      formatter: "currency",
    },
    {
      accessor: "paidSum",
      Header: "Оплачено",
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: "currency",
    },
    {
      accessor: "debt",
      Header: "борг",
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: "discount",
    },
    {
      accessor: "paydeskName",
      Header: "ФОП",
      minWidth: 100,
      sortable: false,
      filterable: true,
    },
    {
      accessor: "employeeFirstName",
      Header: "Касир",
      minWidth: 100,
      sortable: false,
      filterable: true,
      formatter: "orderEmployeeName",
    },
    {
      accessor: "detailsOrder",
      Header: "",
      minWidth: 40,
      formatter: "detailsOrder",
      sortable: false,
      filterable: false,
    },
  ],
  orderDetails: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: "paidDate",
      Header: "Дата оплати",
      minWidth: 150,
      sortable: false,
      filterable: true,
      formatter: "date",
    },
    {
      accessor: "amount",
      Header: "Сума",
      minWidth: 150,
      sortable: false,
      formatter: "currency",
    },
    {
      accessor: "paymentMethodName",
      Header: "Спосіб оплати",
      minWidth: 150,
      sortable: false,
    },
    {
      accessor: "paydeskName",
      Header: "ФОП",
      minWidth: 150,
      sortable: false,
    },
    {
      accessor: "paid",
      Header: "Оплачено",
      minWidth: 150,
      sortable: false,
      formatter: "currency",
    },
    {
      accessor: "debt",
      Header: "борг",
      minWidth: 150,
      sortable: false,
      formatter: "currency",
    },
    {
      accessor: "employeeFirstName",
      Header: "Відповідальний",
      minWidth: 150,
      sortable: false,
      formatter: "orderEmployeeName",
    },
  ],
};

export default columns;
