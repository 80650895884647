const columns = {
  general: [
    {
      expander: true,
      show: false,
    },
    {
      accessor: 'name',
      Header: 'Назва',
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'parameter',
      Header: 'Параметр',
      minWidth: 200,
      filterable: false,
      sortable: false,
    },
    {
      accessor: 'price',
      Header: 'Ціна',
      minWidth: 200,
      sortable: false,
      filterable: false,
      formatter: 'currency'
    },
    {
      accessor: 'isDeleted',
      Header: 'Статус',
      minWidth: 150,
      sortable: false,
      filterable: false,
      formatter: "serviceStatus"
    },
    {
      accessor: 'isDeleted',
      Header: '',
      minWidth: 30,
      formatter: 'blockUnblock',
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'edit',
      Header: '',
      minWidth: 30,
      formatter: 'edit',
      sortable: false,
      filterable: false,
    },
  ],
};

export default columns;