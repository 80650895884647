import { takeEvery, put, call } from 'redux-saga/effects';
import { SET_NOTIFICATION } from '../notification';
import { SET_PAGE_COUNT } from '../pagination';
import {
  fetchMaterials, fetchAllMaterials, editMaterialById,
  addNewMaterial, deleteMaterial, restoreMaterial, fetchAllParameters
} from '../../http/materials';

const MATERIALS_LOAD = 'materials/MATERIALS_LOAD';
const MATERIALS_LOADING = 'materials/MATERIALS_LOADING';
const MATERIALS_LOAD_FAILED = 'materials/MATERIALS_LOAD_FAILED';

const PARAMETER_LOADING = 'materials/PARAMETER_LOADING'
const PARAMETER_LOAD_FAILED = 'materials/PARAMETER_LOAD_FAILED'
const PARAMETER_LOAD_SUCCESS = 'materials/PARAMETER_LOAD_SUCCESS'
const PARAMETER_ITEMS_LOAD_SUCCESS = 'materials/PARAMETER_ITEMS_LOAD_SUCCESS'
const PARAMETER_ITEMS_LOAD = 'materials/PARAMETER_ITEMS_LOAD'

const MATERIAL_MODAL_CLOSE = 'materials/MATERIAL_MODAL_CLOSE'
const MATERIAL_MODAL_OPEN = 'materials/MATERIAL_MODAL_OPEN';
const MATERIALS_LOAD_SUCCESS = 'materials/MATERIALS_LOAD_SUCCESS';
const CREATE_NEW_MATERIAL = 'materials/CREATE_NEW_MATERIAL';
const CREATE_MATERIAL = 'materials/CREATE_MATERIAL';
const BLOCK_MATERIAL = 'materials/BLOCK_MATERIAL';
const RESTORE_MATERIAL = 'materials/RESTORE_MATERIAL';
const MATERIAL_ADD_SUCCESS = 'materials/MATERIAL_ADD_SUCCESS';

const MATERIALS_ITEMS_LOAD = 'materials/MATERIALS_ITEMS_LOAD';


const MATERIALS_ITEMS_LOAD_SUCCESS = 'materials/MATERIALS_ITEMS_LOAD_SUCCESS';

const MATERIAL_SET_INITIAL = 'materials/MATERIAL_SET_INITIAL';
const MATERIAL_EDIT = 'materials/MATERIAL_EDIT';
const SET_QUERIES_DATA = 'materials/SET_QUERIES_DATA';

const initialState = {
  initialValues: {
    number: '',
    name: '',
    parameter: '',
    inputPrice: '',
    outputPrice: '',
    unitTypeId: "",
    id: ''
  },
  queriesData: {},
  data: [],
  allData: [{
    name: ''
  }],
  loading: false,
  visible: false,
  currentId: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MATERIALS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case MATERIALS_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_QUERIES_DATA: {
      return {
        ...state,
        queriesData: {
          ...state.queries,
          ...action.data,
        }
      };
    }
    case MATERIAL_MODAL_OPEN: {
      return {
        ...state,
        visible: true,
        currentId: action.id
      };
    }
    case MATERIAL_MODAL_CLOSE: {
      return {
        ...state,
        currentId: '',
        visible: false,
      };
    }
    case MATERIALS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.items
      };
    }
    case MATERIALS_ITEMS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        allData: action.data
      };
    }
    case CREATE_NEW_MATERIAL: {
      return {
        ...state,
        loading: false,
        visible: true,
        initialValues: {
          number: '',
          name: '',
          inputPrice: '',
          outputPrice: '',
          id: '',
          unitTypeId: '',
        },
      };
    }
    case MATERIAL_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        visible: false,
        currentId: null
      };
    }
    case MATERIAL_SET_INITIAL: {
      return {
        ...state,
        visible: true,
        loading: false,
        initialValues: action.data
      };
    }
    case PARAMETER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PARAMETER_LOAD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case PARAMETER_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data.items
      };
    }
    case PARAMETER_ITEMS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        parametersData: action.data
      }
    }

    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const getAllMaterials = queries => ({ type: MATERIALS_LOAD, queries });
export const getMaterials = () => ({ type: MATERIALS_ITEMS_LOAD });
export const getParameter =() =>({type: PARAMETER_ITEMS_LOAD })
export const openModal = id => ({ type: MATERIAL_MODAL_OPEN, id });
export const closeModal = () => ({ type: MATERIAL_MODAL_CLOSE });
export const createNewMaterial = () => ({ type: CREATE_NEW_MATERIAL });
export const createMaterial = (data, pageNumber, queriesData) => ({ type: CREATE_MATERIAL, data, pageNumber, queriesData });
export const blockMaterial = (id, pageNumber, queriesData) => ({ type: BLOCK_MATERIAL, id, pageNumber, queriesData });
export const unlockMaterial = (id, pageNumber, queriesData) => ({ type: RESTORE_MATERIAL, id, pageNumber, queriesData });
export const setInitialData = data => ({ type: MATERIAL_SET_INITIAL, data });
export const setQueriesData = data => ({ type: SET_QUERIES_DATA, data });
export const editMaterial = (id, data, pageNumber, queriesData) => ({ type: MATERIAL_EDIT, id, data, pageNumber, queriesData });

//<<<WORKERS>>>
function* getAllData({queries = {}}) {
  yield put({ type: MATERIALS_LOADING })
  try {
    const response = yield call(fetchMaterials, queries);
    if (response.status === 200) {
      yield put({ type: MATERIALS_LOAD_SUCCESS, data: response.data });
      yield put({ type: SET_PAGE_COUNT, count: response.data.count });
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: MATERIALS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: MATERIALS_LOAD_FAILED })
    console.log(error)
  }
}

function* getAllItemsData() {
  yield put({ type: MATERIALS_LOADING })
  try {
    const response = yield call(fetchAllMaterials);
    if (response.status === 200) {
      yield put({ type: MATERIALS_ITEMS_LOAD_SUCCESS, data: response.data })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: MATERIALS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: MATERIALS_LOAD_FAILED })
    console.log(error)
  }
}

export function* getAllParameterData() {
  yield put({ type: PARAMETER_LOADING })
  try {
    const response = yield call(fetchAllParameters);

    if (response.status === 200) {
      yield put({ type: PARAMETER_ITEMS_LOAD_SUCCESS, data: response.data,  })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: PARAMETER_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: PARAMETER_LOAD_FAILED })
    console.log(error)
  }
};

function* addData({ data, pageNumber, queriesData }) {
  yield put({ type: MATERIALS_LOADING })
  try {
    const response = yield call(addNewMaterial, data);
    if (response.status === 200) {
      yield put({ type: MATERIAL_ADD_SUCCESS })
      yield put({ type: MATERIALS_LOAD, queries: { pageNumber, ...queriesData } })
      yield put({ type: MATERIALS_ITEMS_LOAD})

    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: MATERIALS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: MATERIALS_LOAD_FAILED })
    console.log(error)
  }
};

function* blockData({ id, pageNumber, queriesData }) {
  yield put({ type: MATERIALS_LOADING })
  try {
    const response = yield call(deleteMaterial, id);
    if (response.status === 200) {
      yield put({ type: MATERIALS_LOAD, queries: {pageNumber, ...queriesData}})
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
  } catch (error) {
    yield put({ type: MATERIALS_LOAD_FAILED })
    console.log(error)
  }
};

function* restoreData({ id, pageNumber, queriesData }) {
  yield put({ type: MATERIALS_LOADING })
  try {
    const response = yield call(restoreMaterial, id);
    if (response.status === 200) {
      yield put({ type: MATERIALS_LOAD, queries: {pageNumber, ...queriesData} })
    } else {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
    }
    if (response.status >= 400) {
      yield put({ type: MATERIALS_LOAD_FAILED })
    }
  } catch (error) {
    yield put({ type: MATERIALS_LOAD_FAILED })
    console.log(error)
  }
};

function* editData({ id, data, pageNumber, queriesData }) {
  console.log(data)
  try {
    yield put({ type: MATERIALS_LOADING })
    const response = yield call(editMaterialById, id, data);
    if (response.status === 200) {
      yield put({ type: MATERIAL_ADD_SUCCESS })
      yield put({ type: MATERIALS_ITEMS_LOAD})
      yield put({ type: MATERIALS_LOAD, queries: {pageNumber, ...queriesData} })
    }
    if (response.status >= 400) {
      yield put({ type: SET_NOTIFICATION, notification: { text: response.data, error: true } })
      yield put({ type: MATERIALS_LOAD_FAILED })
    }
  } catch (error) {
    console.log(error)
  }
};

//<<<WATCHERS>>>
export function* watchMaterials() {
  yield takeEvery(MATERIALS_LOAD, getAllData);
  yield takeEvery(MATERIALS_ITEMS_LOAD, getAllItemsData);
  yield takeEvery(PARAMETER_ITEMS_LOAD, getAllParameterData)
  yield takeEvery(CREATE_MATERIAL, addData);
  yield takeEvery(BLOCK_MATERIAL, blockData);
  yield takeEvery(RESTORE_MATERIAL, restoreData);
  yield takeEvery(MATERIAL_EDIT, editData);
}
