import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Notification from '../components/Notification';
import Main from '../pages/MainPage';
import Clients from '../pages/Clients';
import OrderHistory from '../pages/OrderHistory';
import OrderDetails from '../pages/OrderHistory/OrderDetails';
import ClientOrders from '../pages/Clients/ClientOrders';
import CashRegisterHistory from '../pages/CashRegisterHistory';
import Reports from '../pages/Reports';
import HistoryCollections from '../pages/HistoryCollections';
import CreateOrder from '../pages/CreateOrder';
import Comings from '../pages/Comings';
import Services from '../pages/Services';
import Materials from '../pages/Materials';
import Header from '../components/Header';
import Collection from '../pages/Collections';
import HistoryBalances from '../pages/HistoryBalances';
import ReportByMaterial from '../pages/Reports/ReportByMaterial';
import ReportByWorker from '../pages/Reports/ReportByWorker';
import СonsolidatedReport from '../pages/Reports/СonsolidatedReport';
import { roleNames } from '../roles';
import ReportByService from '../pages/Reports/ReportByService';
import Paydesks from '../pages/Paydesks';
import ReportByClient from '../pages/Reports/ReportByClient';

const GeneralRoutes = () => (
  <>
    <Route path="/" exact component={Main} />
    <Route path="/clients" exact component={Clients} />
    <Route path="/collection" exact component={Collection} />
    <Route path="/clients/:id/orders" exact component={ClientOrders} />
    <Route path="/order-history" exact component={OrderHistory} />
    <Route path="/balance-history" exact component={HistoryBalances} />
    <Route path="/order-history/details/:id" exact component={OrderDetails} />
    <Route path="/cash-register-history" exact component={CashRegisterHistory} />
    <Route path="/services" component={Services} />
    <Route path="/materials" exact component={Materials} />
    <Route path="/comings" exact component={Comings} />
  </>
)

const AdvancedAdminRoutes = () => (
  <>
    <Route path="/create-order" exact component={CreateOrder} />
    <Route path="/reports" exact component={Reports} />
    <Route path="/reports/material" exact component={ReportByMaterial} />
    <Route path="/reports/service" exact component={ReportByService} />
    <Route path="/reports/client" exact component={ReportByClient} />
    <Route path="/reports/worker" exact component={ReportByWorker} />
    <Route path="/reports/consolidated" exact component={СonsolidatedReport} />
    <Route path="/history-collection" exact component={HistoryCollections} />
    <Route path="/paydesks" exact component={Paydesks} />
  </>
)

const AdvancedAccountantRoutes = () => (
  <>
    <Route path="/collection" exact component={Collection} />
    <Route path="/history-collection" exact component={HistoryCollections} />
    <Route path="/reports" exact component={Reports} />
    <Route path="/reports/client" exact component={ReportByClient} />
    <Route path="/reports/material" exact component={ReportByMaterial} />
    <Route path="/reports/service" exact component={ReportByService} />
    <Route path="/reports/worker" exact component={ReportByWorker} />
    <Route path="/reports/consolidated" exact component={СonsolidatedReport} />
  </>
)

const AdvancedCashierRoutes = () => (
  <>
    <Route path="/create-order" exact component={CreateOrder} />
  </>
)

const roleAccessResolver = role => {
  const dependencies = {
    [roleNames.superAdmin]: (
      <>
        <GeneralRoutes />
        <AdvancedAdminRoutes />
      </>
    ),
    [roleNames.accountant]: (
      <>
        <GeneralRoutes />
        <AdvancedAccountantRoutes />
      </>
    ),
    [roleNames.casier]: (
      <>
        <GeneralRoutes />
        <AdvancedCashierRoutes />
      </>
    ),
  }
  return dependencies[role];
}

const Pages = props => {
  const { role, logout } = props;
  return (
    <>
      <Notification />
      <Header logout={logout} />
      <Switch>
        {
          roleAccessResolver(role)
        }
      </Switch>
    </>
  )
};

const AppRouter = ({ loggedIn, role, logout }) => {
  return (
    <Switch>
      <Route
        path="/"
        render={props => <Pages {...props} role={role} logout={logout} />}
      />
    </Switch>
  )
};

export default AppRouter;