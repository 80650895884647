import React from "react";

export const InfoIcon = ({ fill = "#00A09D" }) => {
  return (
    <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="marshruty-copy"
          transform="translate(-1311.000000, -270.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="table" transform="translate(255.000000, 91.000000)">
            <g id="4-row" transform="translate(22.000000, 171.000000)">
              <g id="info" transform="translate(1034.000000, 8.000000)">
                <path
                  d="M6.5,13 C2.91576253,13 0,10.0842375 0,6.5 C0,2.91600857 2.91600857,0 6.5,0 C10.0839914,0 13,2.91600857 13,6.5 C13,10.0842375 10.0842375,13 6.5,13 Z M6.49999527,0.499995268 C3.1914873,0.499995268 0.499995268,3.19173334 0.499995268,6.49999527 C0.499995268,9.80826666 3.19173334,12.4999953 6.49999527,12.4999953 C9.80826666,12.4999953 12.4999953,9.80826666 12.4999953,6.49999527 C12.4999953,3.19173334 9.8082359,0.499995268 6.49999527,0.499995268 Z"
                  id="Shape"
                />
                <path
                  d="M6.62988733,2.6 C6.31932167,2.6 6.06666667,2.852824 6.06666667,3.16358683 C6.06666667,3.474068 6.31932167,3.72666667 6.62988733,3.72666667 C6.940453,3.72666667 7.193108,3.474068 7.193108,3.16358683 C7.193108,2.852824 6.940453,2.6 6.62988733,2.6 Z"
                  id="Path"
                />
                <path
                  d="M6.61818182,5.2 C6.31359838,5.2 6.06666667,5.49102667 6.06666667,5.85 L6.06666667,9.75 C6.06666667,10.1089733 6.31359838,10.4 6.61818182,10.4 C6.92276525,10.4 7.16969697,10.1089733 7.16969697,9.75 L7.16969697,5.85 C7.16969697,5.49102667 6.92276525,5.2 6.61818182,5.2 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7107 0.905619C9.91411 -0.300764 11.8711 -0.300764 13.0745 0.905619C13.6584 1.48647 13.9801 2.26094 13.9801 3.08604C13.9801 3.91115 13.6584 4.68562 13.0745 5.26945L5.07368 13.2673C4.99921 13.3448 4.89496 13.3865 4.7907 13.3865C4.68645 13.3865 4.58219 13.3418 4.50772 13.2673L1.28474 10.0443L0.870701 13.1065L2.99155 12.8205C3.21198 12.7907 3.41453 12.9456 3.44432 13.166C3.47411 13.3865 3.31921 13.589 3.09879 13.6188L0.45368 13.9763C0.435808 13.9792 0.417935 13.9792 0.400063 13.9792C0.295808 13.9792 0.191552 13.9346 0.117084 13.8601C0.0307014 13.7737 -0.0139795 13.6486 0.00389286 13.5235L0.596659 9.13285C0.608574 9.04647 0.647297 8.96604 0.70985 8.90349L8.7107 0.905619ZM10.209 3.232L8.86559 1.8886L1.56474 9.18945L2.90815 10.5329L10.209 3.232ZM4.79368 12.4154L3.48006 11.1018L10.7809 3.80094L12.0945 5.11455L4.79368 12.4154ZM9.44049 1.32562L12.6545 4.53966C12.9911 4.13158 13.1758 3.62519 13.1758 3.08604C13.1758 2.47541 12.9405 1.90051 12.5086 1.47158C12.0767 1.04264 11.5047 0.804342 10.8941 0.804342C10.3579 0.804342 9.84857 0.989023 9.44049 1.32562Z"
        fill="#629AFF"
      />
    </svg>
  );
};
export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53826 1.67533H9.71489C10.5957 1.67533 11.3123 2.39209 11.3123 3.27296V3.35493C11.3123 4.02803 10.8932 4.60417 10.3027 4.83896V12.3859C10.3027 13.2667 9.58617 13.9834 8.70533 13.9834H2.61153C1.73072 13.9834 1.01408 13.2667 1.01408 12.3859V4.83893C0.423647 4.60417 0.0045166 4.02803 0.0045166 3.3549V3.27293C0.0045166 2.39209 0.721067 1.67533 1.60194 1.67533H3.77869C3.88727 0.733768 4.68823 0 5.6584 0C6.6286 0 7.42968 0.733797 7.53826 1.67533ZM6.77269 1.67533C6.67078 1.15316 6.2102 0.757652 5.6584 0.757652C5.10681 0.757652 4.6462 1.15313 4.54431 1.67533H6.77269ZM9.5451 12.3859C9.5451 12.849 9.16828 13.2258 8.7053 13.2258H2.6115C2.14852 13.2258 1.77171 12.849 1.77171 12.3859V4.95249H9.5451V12.3859ZM9.71486 4.19487C10.1778 4.19487 10.5547 3.81803 10.5547 3.3549H10.5547V3.27293C10.5547 2.8098 10.1779 2.43296 9.71489 2.43296H1.60194C1.13895 2.43296 0.76214 2.8098 0.76214 3.27293V3.3549C0.76214 3.81803 1.13895 4.19487 1.60194 4.19487H9.71486Z"
        fill="#E288A9"
      />
      <path
        d="M3.62738 12.2569C3.8366 12.2569 4.0062 12.0872 4.0062 11.8781V7.61278C4.0062 7.40362 3.83657 7.23394 3.62738 7.23394C3.4182 7.23394 3.24857 7.40362 3.24857 7.61278V11.8781C3.24854 12.0873 3.41817 12.2569 3.62738 12.2569Z"
        fill="#E288A9"
      />
      <path
        d="M5.6584 12.2569C5.86762 12.2569 6.03724 12.0872 6.03724 11.8781V7.61278C6.03724 7.40362 5.86756 7.23394 5.6584 7.23394C5.44921 7.23394 5.27959 7.40362 5.27959 7.61278V11.8781C5.27959 12.0873 5.44918 12.2569 5.6584 12.2569Z"
        fill="#E288A9"
      />
      <path
        d="M7.68942 12.2569C7.89861 12.2569 8.0682 12.0872 8.0682 11.8781V7.61278C8.0682 7.40362 7.89858 7.23394 7.68942 7.23394C7.48017 7.23394 7.31058 7.40362 7.31058 7.61278V11.8781C7.31055 12.0873 7.48023 12.2569 7.68942 12.2569Z"
        fill="#E288A9"
      />
    </svg>
  );
};
export const ChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L9.29289 1.70711C9.92286 1.07714 9.47669 0 8.58579 0H1.41421C0.523309 0 0.0771419 1.07714 0.707107 1.70711L4.29289 5.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const ChevronLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="currentColor"
    >
      <path
        d="M6.61584 11L6.854 10.762L2.09199 6L6.854 1.23799L6.61584 1L1.61584 6L6.61584 11Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
export const ClearIcon = () => {
  return (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="close-circle"
      width="22px"
      height="2em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
    </svg>
  );
};
export const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="23"
      viewBox="0 0 16 23"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1371 16.3959C15.1371 17.431 14.4403 18.3056 13.4913 18.5772V22.1371H12.2447L12.2447 18.5773C11.2959 18.3056 10.5989 17.431 10.5989 16.3959C10.5989 15.3609 11.2959 14.4862 12.2447 14.2146V0.862885L13.4913 0.862885V14.2145C14.4403 14.4862 15.1371 15.3609 15.1371 16.3959ZM12.868 15.3734C12.3041 15.3734 11.8454 15.832 11.8454 16.3959C11.8454 16.9598 12.3041 17.4185 12.868 17.4185C13.4319 17.4185 13.8905 16.9598 13.8905 16.3959C13.8905 15.8322 13.4319 15.3734 12.868 15.3734Z"
        fill="#C7CBD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2774 2.67686C9.22626 2.9484 9.92322 3.82309 9.92322 4.85813C9.92322 5.89318 9.22626 6.76803 8.2774 7.03957L8.2774 22.1371H7.03086L7.03086 7.03957C6.08184 6.76803 5.38504 5.89334 5.38504 4.8583C5.38504 3.82325 6.08184 2.9484 7.03086 2.67686V0.862885L8.2774 0.862885V2.67686ZM7.65413 3.83575C7.09027 3.83575 6.63158 4.29443 6.63158 4.85813C6.63158 5.422 7.09027 5.88068 7.65413 5.88068C8.21783 5.88068 8.67668 5.422 8.67668 4.85813C8.67668 4.29443 8.21783 3.83575 7.65413 3.83575Z"
        fill="#C7CBD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06335 9.66802C4.01237 9.93956 4.70917 10.8144 4.70917 11.8495C4.70917 12.8843 4.01237 13.7592 3.06335 14.0307L3.06335 22.1371H1.81681L1.81681 14.0307C0.867949 13.7592 0.170992 12.8843 0.170992 11.8495C0.170992 10.8144 0.867949 9.93956 1.81681 9.66802L1.81681 0.862885L3.06335 0.862885L3.06335 9.66802ZM2.44008 10.8269C1.87622 10.8269 1.41753 11.2856 1.41753 11.8495C1.41753 12.4133 1.87622 12.872 2.44008 12.872C3.00394 12.872 3.46263 12.4132 3.46263 11.8495C3.46263 11.2856 3.00394 10.8269 2.44008 10.8269Z"
        fill="#C7CBD6"
      />
    </svg>
  );
};
export const CheckIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16327 7.89658L1.04082 4.77413L0 5.81495L4.16327 9.97821L13.0845 1.05693L12.0437 0.0161133L4.16327 7.89658Z"
        fill="#00A09D"
      />
    </svg>
  );
};
export const MinusIcon = () => {
  return (
    <svg
      width="6"
      height="2"
      viewBox="0 0 6 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.96 1.676V0.908H5.28V1.676H0.96Z" fill="#0D0D0D" />
    </svg>
  );
};
export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
    >
      <path
        d="M6.80575 0.309312L6.49644 0L3.40287 3.09356L0.309312 0L0 0.309312L3.09356 3.40287L0 6.49644L0.309312 6.80575L3.40287 3.71219L6.49644 6.80575L6.80575 6.49644L3.71219 3.40287L6.80575 0.309312Z"
        fill="black"
      />
    </svg>
  );
};
export const PointIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6Z"
        fill="#E34132"
      />
    </svg>
  );
};
export const VisibilityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
    >
      <g opacity="0.3">
        <path
          d="M8.23789 3.42866C8.12956 3.32033 7.95344 3.32033 7.84511 3.42866C7.73677 3.537 7.73677 3.71366 7.84511 3.82145C8.15956 4.1359 8.33344 4.55423 8.33344 4.99978C8.33344 5.91866 7.58565 6.66645 6.66677 6.66645C6.22123 6.66645 5.80289 6.49312 5.48844 6.17812C5.38011 6.06978 5.20399 6.06978 5.09565 6.17812C4.98732 6.2859 4.98732 6.46257 5.09565 6.5709C5.51453 6.9909 6.07287 7.22202 6.66677 7.22202C7.89232 7.22202 8.88899 6.22535 8.88899 4.99981C8.88899 4.40588 8.65789 3.84754 8.23789 3.42866Z"
          fill="black"
        />
        <path
          d="M7.05401 2.81592C6.92789 2.79371 6.79901 2.77759 6.66679 2.77759C5.44125 2.77759 4.44458 3.77425 4.44458 4.9998C4.44458 5.13201 4.4607 5.26092 4.48346 5.38701C4.50734 5.52147 4.62458 5.61647 4.75625 5.61647C4.77237 5.61647 4.78846 5.61535 4.80513 5.61202C4.95567 5.58535 5.05679 5.4409 5.03013 5.29035C5.01291 5.1959 5.00013 5.0998 5.00013 4.9998C5.00013 4.08092 5.74791 3.33313 6.66679 3.33313C6.76679 3.33313 6.86291 3.34592 6.95734 3.36259C7.10513 3.39259 7.25234 3.28813 7.27901 3.13759C7.30567 2.98704 7.20458 2.84259 7.05401 2.81592Z"
          fill="black"
        />
        <path
          d="M13.2672 4.82032C13.2044 4.74586 11.7017 2.99198 9.62888 1.9181C9.49443 1.84756 9.325 1.90089 9.25443 2.03756C9.18388 2.17368 9.23721 2.34144 9.37388 2.41201C10.9705 3.23868 12.2461 4.52868 12.6822 4.99979C12.02 5.71701 9.41388 8.33313 6.66667 8.33313C5.73445 8.33313 4.795 8.10813 3.87333 7.66368C3.73667 7.59646 3.56945 7.6548 3.50279 7.79313C3.43557 7.93091 3.49391 8.09701 3.63224 8.16368C4.62945 8.64534 5.65057 8.88868 6.66669 8.88868C10.0895 8.88868 13.1395 5.33089 13.2678 5.17922C13.3555 5.07586 13.355 4.9242 13.2672 4.82032Z"
          fill="black"
        />
        <path
          d="M8.48949 1.43084C7.85728 1.21805 7.24394 1.11084 6.6667 1.11084C3.24392 1.11084 0.193918 4.66863 0.0655846 4.82029C-0.0155352 4.91584 -0.0222018 5.05475 0.0500378 5.15863C0.0883711 5.21363 1.0067 6.51751 2.59837 7.57475C2.64558 7.60641 2.69837 7.62141 2.7517 7.62141C2.84116 7.62141 2.92949 7.57808 2.98282 7.49641C3.06782 7.3692 3.03282 7.19641 2.90504 7.11196C1.73616 6.33475 0.936158 5.39641 0.636704 5.01475C1.27616 4.32029 3.89949 1.66641 6.6667 1.66641C7.18392 1.66641 7.73782 1.7642 8.31225 1.95696C8.4578 2.00917 8.61558 1.92863 8.66392 1.78251C8.71282 1.63696 8.63504 1.47975 8.48949 1.43084Z"
          fill="black"
        />
        <path
          d="M11.585 0.0814941C11.4766 -0.0268392 11.3005 -0.0268392 11.1922 0.0814941L1.74775 9.52595C1.63942 9.63428 1.63942 9.8104 1.74775 9.91873C1.80221 9.97261 1.8733 9.99985 1.94442 9.99985C2.01554 9.99985 2.08663 9.97264 2.14054 9.91873L11.585 0.474281C11.6933 0.365947 11.6933 0.189827 11.585 0.0814941Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7188 1.40625H13.7503C14.4408 1.40625 15 1.96547 15 2.65594V13.7498C15 14.4403 14.4408 15 13.7503 15H1.25016C0.559688 15 0 14.4403 0 13.7498V2.65594C0 1.96547 0.559688 1.40625 1.25016 1.40625H3.28125V0.46875C3.28125 0.209531 3.49125 0 3.75 0C4.00875 0 4.21875 0.209531 4.21875 0.46875V1.40625H7.03125V0.46875C7.03125 0.209531 7.24125 0 7.5 0C7.75875 0 7.96875 0.209531 7.96875 0.46875V1.40625H10.7812V0.46875C10.7812 0.209531 10.9908 0 11.25 0C11.5092 0 11.7188 0.209531 11.7188 0.46875V1.40625ZM13.7503 14.0625C13.9223 14.0625 14.0625 13.9223 14.0625 13.7498V2.65594C14.0625 2.48391 13.9223 2.34375 13.7503 2.34375H11.7188V3.28125C11.7188 3.54047 11.5092 3.75 11.25 3.75C10.9908 3.75 10.7812 3.54047 10.7812 3.28125V2.34375H7.96875V3.28125C7.96875 3.54047 7.75875 3.75 7.5 3.75C7.24125 3.75 7.03125 3.54047 7.03125 3.28125V2.34375H4.21875V3.28125C4.21875 3.54047 4.00875 3.75 3.75 3.75C3.49125 3.75 3.28125 3.54047 3.28125 3.28125V2.34375H1.25016C1.07766 2.34375 0.9375 2.48391 0.9375 2.65594V13.7498C0.9375 13.9223 1.07766 14.0625 1.25016 14.0625H13.7503Z"
        fill="#629AFF"
      />
      <rect
        x="3.28125"
        y="5.625"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="3.28125"
        y="7.96875"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="3.28125"
        y="10.3125"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="6.5625"
        y="10.3125"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="6.5625"
        y="7.96875"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="6.5625"
        y="5.625"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="9.84375"
        y="10.3125"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="9.84375"
        y="7.96875"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
      <rect
        x="9.84375"
        y="5.625"
        width="1.875"
        height="1.40625"
        fill="#629AFF"
      />
    </svg>
  );
};

export const SuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="58"
      viewBox="0 0 76 58"
      fill="none"
    >
      <path
        d="M75.1428 6.57142L23.7143 58L0.142822 34.4286L6.18568 28.3857L23.7143 45.8714L69.1 0.528564L75.1428 6.57142Z"
        fill="#629AFF"
      />
    </svg>
  );
};
export const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="58"
      viewBox="0 0 66 58"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6927 2.36868L64.7854 51.0266C65.6178 52.4683 65.6178 54.1898 64.7854 55.6313C63.953 57.073 62.4623 57.9337 60.7975 57.9337H4.61215C2.9474 57.9337 1.45664 57.073 0.62433 55.6313C-0.20811 54.1898 -0.20811 52.4683 0.62433 51.0266L28.717 2.36868C29.5493 0.926989 31.0401 0.0663147 32.7048 0.0663147C34.3696 0.0663147 35.8604 0.926989 36.6927 2.36868ZM60.7977 54.0965C61.183 54.0965 61.3796 53.8561 61.4622 53.7127C61.5449 53.5695 61.655 53.2791 61.4621 52.9453L33.3693 4.28728C33.1766 3.95334 32.8703 3.90351 32.7047 3.90351C32.5393 3.90351 32.2329 3.95346 32.0401 4.28728L3.94758 52.9453C3.7548 53.2791 3.86492 53.5695 3.94758 53.7127C4.03011 53.8562 4.22659 54.0965 4.61215 54.0965H60.7977Z"
        fill="#FF5855"
      />
      <rect
        x="30.7869"
        y="17.5143"
        width="3.83733"
        height="20.4657"
        fill="#FF5855"
      />
      <path
        d="M32.7055 46.9339C34.1184 46.9339 35.2637 45.7885 35.2637 44.3756C35.2637 42.9628 34.1184 41.8174 32.7055 41.8174C31.2926 41.8174 30.1472 42.9628 30.1472 44.3756C30.1472 45.7885 31.2926 46.9339 32.7055 46.9339Z"
        fill="#FF5855"
      />
    </svg>
  );
};
