import React from 'react';
import { Tooltip } from "antd";

export const generateSortParams = sort => {
  let sortQueries = []
  let counter = 0;
  for (const property in sort) {
      if(sort[property]) {
          sortQueries.push(`&sort[${counter}][${property}]=${sort[property]}`)
          counter = counter + 1
      } else {
          sortQueries.push(`&sort[${counter}][${property}]=""`)
          counter = counter + 1;
      }
  }

  return sortQueries.filter(query => query).join('');

}

export const generateSelectValue = ({value, size=20 }) => {
  if(value.length > size){
    return (
      <Tooltip className="select-value" placement="top" title={value}>
        {`${value.slice(0, size)}...`}
      </Tooltip>
    )
  } else {
    return value
  }
}