import React from 'react';
import { Link } from 'react-router-dom';

const texts = {
  linkTitle: "Всі замовлення"
}

export const allOrdersFormatter = ({ id, match, original, setCurrentClient }) => (
    <Link onClick={() => setCurrentClient(original) } to={`${match.url}/${id}/orders`} className="link-formatter">
      {texts.linkTitle}
    </Link>
)