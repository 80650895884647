import React, { useState } from 'react';

import ArrowRight from '../../../assets/images/icons/arrow-right.png';
import ArrowLeft from '../../../assets/images/icons/arrow-left.png';

const SortField = ({ label, getSelectedValue, name, value = '' }) => {
  const [count] = useState(0);

  const sortIn = () => {
    if (value) {
      if (value === "desc") {
        getSelectedValue({
          value: 'asc'
        }, name)
      } else if (value === "asc") {
        getSelectedValue({ value: '' }, name)
      } 
    } else if (count === 0) {
        getSelectedValue({
          value: 'desc'
        }, name)
    }
}

return (
  <span className='field sort'>
    <label>{label}</label>
    <div className="sort__content" onClick={sortIn}>
      <div>
        <img src={ArrowRight} alt="arrow-right" />
        <img src={ArrowLeft} alt="arrow-left" />
      </div>
    </div>
  </span>
);
};

export default SortField;
