import { combineReducers } from 'redux';

import createOrder from './createOrder';
import orders from './orders';
import clients from './clients';
import services from './services';
import materials from './materials';
import comings from './comings';
import pagination from './pagination';
import collections from './collections';
import notification from './notification';
import balancesHistory from './balanceHistory';
import reports from './reports';
import login from './login';
import employees from './employee';
import paydesks from './paydesks';

const rootReducer = combineReducers({
  createOrder,
  orders,
  clients,
  services,
  materials,
  comings,
  pagination,
  collections,
  notification,
  balancesHistory,
  reports,
  login,
  employees,
  paydesks
});

export default rootReducer;