import React from "react";
import { Radio } from "antd";
import { getInputClass } from "../helpers";

const RadioField = ({
  input,
  meta,
  label = "",
  options,
  required,
  ...props
}) => {
  const requiredMark = required ? (
    <span className="c-form__field--required">*</span>
  ) : (
    ""
  );
  const className = getInputClass({ meta, value: input.value });
  const filteredOptions = options.sort((a, b) => b.name.localeCompare(a.name));

  const renderOptions = () =>
    filteredOptions.map((option, index) => (
      <Radio key={index} value={option.id}>
        {option.name}
      </Radio>
    ));
  return (
    <span className={`c-form__checkbox ${className}`}>
      <label>
        {label}
        {requiredMark}
      </label>
      <Radio.Group {...input} {...props}>
        {renderOptions()}
      </Radio.Group>
      <div className="ant-form-explain">{meta.touched ? meta.error : ""}</div>
    </span>
  );
};

export default RadioField;
