import React from 'react';
import Select from '../index';
import { Select as SelectAntd } from 'antd';
import { connect } from 'react-redux';
const { Option } = SelectAntd;

class CollectionСashierSelect extends Select {
  renderOptions = () => this.props.data.map(option => <Option key={option.id} value={option.id}>{option.firstName} {option.lastName}</Option>)
}

const mapStateToProps = state => ({
  data: state.collections.currentPaydesk.cashiers
})

export default connect(mapStateToProps)(CollectionСashierSelect);