import React, { Component } from 'react';
import { UserManager } from 'oidc-client';
import { getItem, setItem } from '../../helpers/storage';

class Authenticate extends Component {
  constructor(props) {
    super(props);
    this.signout = this.signout.bind(this);
    this.onUserLoaded = this.onUserLoaded.bind(this);

    this.state = {
      isAuthenticated: false
    };
  };

  componentDidMount() {
    this.userManager = new UserManager(this.props.OidcSettings);
    this.userManager.events.addUserLoaded(this.onUserLoaded);
    this.userManager.events.addUserUnloaded(this.onUserUnloaded);
    const currentUser = getItem('user');
    this.userManager.getUser().then((user) => {
      if (currentUser || (user !== null && user !== undefined)) {
        currentUser ? this.onUserLoaded(currentUser) : this.onUserLoaded(user);
      } else if (window.location.href.includes("#id_token")) {
        this.userManager.signinRedirectCallback().then(() => {
          window.history.replaceState({}, "", "/");
        }).catch(function (err) {
          console.log("Error signinRedirectCallback: ", err);
        });
      } else {
        this.userManager.signinRedirect().then(function () {
          console.log('signinRedirect ok');
        }).catch(function (err) {
          console.log('signinRedirect error:', err);
        });
      }
    })
  };

  onUserLoaded(user) {
    setItem('user', user);
    this.setState({ isAuthenticated: true });
    if (this.props.userLoaded !== undefined){
      this.props.userLoaded(user);
    }
  };

  onUserUnloaded = () => {
    if (this.props.userUnLoaded !== undefined)
      this.props.userUnLoaded();
  };

  signout() {
    this.userManager.signoutRedirect().then(function () {
      this.setState({ isAuthenticated: false });
      localStorage.clear();
    }).catch(function (err) {
      console.log('signoutRedirect error:', err);
    });
  };

  render() {
    if (this.state.isAuthenticated) {
      return this.props.children
    }
    return (
      <section className="loader-wrapper">
            <div className="loader"></div>
      </section>
    )
  };
}

Authenticate.defaultProps = {
  OidcSettings: {},
  userUnLoaded: null,
  userLoaded: null,
  renderNotAuthenticated: null
};

// Authenticate.propTypes = {
//   OidcSettings: propTypes.shape({
//     /**
//     * @property {string} OidcSettings.authority The URL of the OIDC/OAuth2 provider.
//     */
//     authority: propTypes.string.isRequired,
//     /**
//     * @property {string} OidcSettings.client_id  Your client application's identifier as registered with the OIDC/OAuth2 provider.
//     */
//     client_id: propTypes.string.isRequired,
//     /**
//     * @property {string} OidcSettings.redirect_uri The redirect URI of your client application to receive a response from the OIDC/OAuth2 provider.
//     */
//     redirect_uri: propTypes.string.isRequired,
//     /**
//     * @property {string} OidcSettings.post_logout_redirect_uri The URL of the OIDC/OAuth2 provider.
//     */
//     post_logout_redirect_uri: propTypes.string.isRequired,
//     /**
//     * @property {string} OidcSettings.response_type The type of response desired from the OIDC/OAuth2 provider ( default: 'id_token').
//     */
//     response_type: propTypes.string.isRequired,
//     /**
//     * @property {string} OidcSettings.scope The scope being requested from the OIDC/OAuth2 provider (default: 'openid').
//     */
//     scope: propTypes.string.isRequired
//   }).isRequired,
//   /**
//   * @property {func} userLoaded Raised when a user session has been established (or re-established), accepts one parameter 'user'.
//   */
//   userLoaded: propTypes.func,
//   /**
//   * @property {func} userUnLoaded Raised when a user session has been terminated.
//   */
//   userUnLoaded: propTypes.func,
//   /**
//   * @property {func} renderNotAuthenticated Renderprop used to render output when user is not authenticated
//   */
//   renderNotAuthenticated: propTypes.func.isRequired
// };

export default Authenticate;