import React from "react";
import queryString from "query-string";
import * as moment from "moment";
import columns from "../columns";
import GeneralPage from "../../GeneralPage";
import { connect } from "react-redux";
import {
  openModal,
  closeModal,
  getAllOrdersByClientId,
  setCurrentDataClient,
} from "../../../redux/clients";
import { setPageNumber } from "../../../redux/pagination";
import Modal from "../../../components/Modal";
import ClientOrderDetails from "./OrderDetails";
import { getDataForCreateOrder } from "../../../redux/createOrder";
import PaymentMethodsSelect from "../../../components/GeneralFields/Select/PaymentMethodsSelect";
import EntrepreneursSelect from "../../../components/GeneralFields/Select/EntrepreneursSelect";
import CashierSelect from "../../../components/GeneralFields/Select/СashierSelect";
import SearchField from "../../../components/GeneralFields/SearchField";
import DateRangePicker from "../../../components/GeneralFields/RangePicker";
import SortField from "../../../components/GeneralFields/SortField";
import { exportPDF } from "../../../components/ExportToPdf";
import {fetchAllClientsOrderHistoryForPdf,} from "../../../http/historyBalances";
import { getOrderByClientId } from "../../../http/clients";

const texts = {
  modalTitle: "Інформація про замовлення",
  btn: "Друк",
};

const dataHistoryHeaders = [
  [
    "Дата оплати",
    "Cума",
    "Спосiб оплати",
    "Відповiдальний",
    "ФОП",
    "Оплачено",
    "Борг",
  ],
];
const dataHeaders = [
  [
    "Номер/товар",
    "Дата замовлення",
    "Дата оплати",
    "Сп. оплати",
    "К-ть",
    "Ціна",
    "Номер рахунку",
    "Сума",
    "Оплачено",
    "Борг",
    "ФОП",
    "Касир",
  ],
];

const constants = {
  format: "YYYY-MM-DD",
  optionOrderFirst: "orderDateStart",
  optionOrderSecond: "orderDateEnd",
  optionPaidFirst: "paidDateStart",
  optionPaidSecond: "paidDateEnd",
  clientOrderFilterOrderNumber: "orderNumber",
  clientOrderFilterPaid: "PaidSum",
  clientOrderFilterAmount: "Amount",
  clientOrderFilterDebt: "Debt",
  clientOrderFilterNumber: "number",
  clientOrderFilterPaymentMethodId: "paymentMethodId",
  clientOrderFilterPaydeskId: "paydeskId",
  clientOrderFilterEmployeeId: "employeeId",
  fullName: "ПІБ:",
  sum: "Сума:",
  balance: "Баланс",
  debt: "Борг:",
  paid: "Оплачено:",
};

class ClientOrders extends GeneralPage {
  class = "client-orders-details";
  pageTitle = "Клієнти";
  subTittle = "/ Замовлення";
  columns = columns.orders;
  state = {
    clientId: "",
    sort: {},
  };

  componentDidMount = () => {
    const {
      getData,
      getDataForCreateOrder,
      match: {
        params: { id },
      },
      orderData,
      setCurrentDataClient,
    } = this.props;

    setCurrentDataClient(id);
    !orderData.paydesks.length && getDataForCreateOrder();
    const query = queryString.parse(this.props.location.search);
    let sort = {};
    for (let property in query) {
      if (property === constants.clientOrderFilterPaid) {
        sort[property] = query[property];
      } else if (property === constants.clientOrderFilterAmount) {
        sort[property] = query[property];
      } else if (property === constants.clientOrderFilterNumber) {
        sort[property] = query[property];
      }
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        clientId: id,
        ...query,
        ...sort,
      }),
      () => {
        getData(this.state);
      }
    );
    // this.state.clientId === "" ? getData({ clientId: id }) : getData(this.state);
  };
  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };
  getOrderDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.optionOrderFirst] = "";
      changeData[constants.optionOrderSecond] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionOrderFirst] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionOrderSecond] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

  getPaidDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.optionPaidFirst] = "";
      changeData[constants.optionPaidSecond] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionPaidFirst] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionPaidSecond] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        <SearchField
          defaultValue={query[constants.clientOrderFilterNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.clientOrderFilterNumber
            )
          }
        />
        <DateRangePicker
          defaultValue={[
            query[constants.optionOrderFirst],
            query[constants.optionOrderSecond],
          ]}
          onChange={this.getOrderDate}
        />
        <DateRangePicker
          defaultValue={[
            query[constants.optionPaidFirst],
            query[constants.optionPaidSecond],
          ]}
          onChange={this.getPaidDate}
        />
        <PaymentMethodsSelect
          value={query[constants.clientOrderFilterPaymentMethodId]}
          label=""
          getSelectedValue={this.getFilterValue}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.clientOrderFilterAmount}
          value={query[constants.clientOrderFilterAmount]}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.clientOrderFilterPaid}
          value={query[constants.clientOrderFilterPaid]}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.clientOrderFilterDebt}
          value={query[constants.clientOrderFilterDebt]}
        />
        <EntrepreneursSelect
          value={query[constants.clientOrderFilterPaydeskId]}
          label=""
          name="paydeskId"
          getSelectedValue={this.getFilterValue}
        />
        <CashierSelect
          value={query[constants.clientOrderFilterEmployeeId]}
          label=""
          name={constants.clientOrderFilterEmployeeId}
          getSelectedValue={this.getFilterValue}
        />
      </>
    );
  };

  handleHistoryDataExportPDF = (data) => {
    const finalData = data.map((elt) => {
      const updatePaymentMethodName = elt.paymentMethodName.replace("і", "i");
      const updateFirstName = elt.employeeFirstName
        ? elt.employeeFirstName.replace("і", "i")
        : "-";
      const updateLastName = elt.employeeLastName
        ? elt.employeeLastName.replace("і", "i")
        : "-";
      return [
        moment(elt.paidDate).format("DD.MM.YYYY HH:mm"),
        elt.amount,
        updatePaymentMethodName,
        [`${updateFirstName} ${updateLastName}`],
        elt.paydeskName,
        elt.paid,
        elt.debt,
      ];
    });
    exportPDF({
      tableData: finalData,
      header: dataHistoryHeaders,
      contentBefore: this.showContentBeforeTablePDF(),
    });
  };

  handleDataExportPDF = async () => {
    const defaultQuery = this.props.location.search;
    const filters = defaultQuery ? defaultQuery : 'pageNumber=1'
    const urlTest = new URLSearchParams(this.props.location.search)
    let data = null
    if(urlTest.get('Amount') || urlTest.get('Debt') || urlTest.get('PaidSum')) {
      try {
        let sort = {}
        if(urlTest.get('Amount')){
          sort.Amount = urlTest.get('Amount')
        }
        if(urlTest.get('Debt')) {
          sort.Debt = urlTest.get('Debt')
        }
        if(urlTest.get('PaidSum')) {
          sort.PaidSum = urlTest.get('PaidSum')
        }
        data = (await getOrderByClientId({
          ...Object.fromEntries(urlTest.entries()),
          clientId: this.props.match.params.id,
          pageSize:999,
          sort: sort
        })).data
      } catch (e) {
        console.error(e)
      }
    } else {
      data = (await fetchAllClientsOrderHistoryForPdf(filters,'gridByClient',this.props.match.params.id)).data
    }
    const finalData = data?.items?.map((elt) => {
      const updatePaymentMethodName = elt.paymentMethodName.replace("і", "i");
      const updateFirstName = elt.employeeFirstName
        ? elt.employeeFirstName.replace("і", "i")
        : "-";
      const updateLastName = elt.employeeLastName
        ? elt.employeeLastName.replace("і", "i")
        : "-";
      return [
        [
          `${elt.orderNumber}

${this.showProductsAndServicesPDF(
              elt
)}


        `,
        ],
        moment(elt.orderCreationDate).format("DD.MM.YYYY HH:mm"),
        moment(elt.lastPaidDate).format("DD.MM.YYYY HH:mm"),
        updatePaymentMethodName,
        `  

${this.showProductsAndServicesAmountPDF(
            elt
)}`,
        `
        
${this.showProductsAndServicesPricePDF(
            elt
)}`,
        elt.transferNumber,
        elt.amount,
        elt.paidSum,
        elt.debt,
        elt.paydeskName,
        [`${updateFirstName} ${updateLastName}`],
      ];
    });
    exportPDF({
      tableData: [
        ...finalData,
        [
          {
            content: `Підсумок:                                                                                                                                                                            Сума: ${this.props.totalAmount} грн        Оплачено: ${this.props.totalPaidSum} грн        Борг: ${this.props.totalDebt} грн`,
            colSpan: 12,
            fontType: "bold",
            styles: {
              fillColor: [239, 154, 154],
            },
          },
        ],
      ],
      header: dataHeaders,
      format: "landscape",
      contentBefore: `${this.pageTitle} \n ${this.showContentBeforeTablePDF()}`,
    });
  };

  showProductsAndServicesPDF = (orderData) => {
    const materials = orderData?.materials ?? [];
    const services = orderData?.services ?? [];

    const allData = materials.concat(services);
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("materialName")) {
          result.push(el.materialName);
        }
        if (el.hasOwnProperty("serviceName")) {
          result.push(el.serviceName);
        }
        return result;
      });
    }
    return result.join("\r\n");
  };

  showProductsAndServicesAmountPDF = (orderData) => {
    const materials = orderData?.materials ?? [];
    const services = orderData?.services ?? [];

    const allData = materials.concat(services);
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("amount")) {
          result.push(el.amount);
        }
        return result;
      });
    }
    return result.join("\r\n");
  };

  showProductsAndServicesPricePDF = (orderData) => {
    const materials = orderData?.materials ?? [];
    const services = orderData?.services ?? [];

    const allData = materials.concat(services);
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("price")) {
          result.push(`${el.price}`);
        }
        return result;
      });
    }
    return result.join("\r\n");
  };

  showContentBeforeTablePDF = () => {
    const { currentClient, location } = this.props;
    return `    
${constants.fullName} ${currentClient.firstName} ${currentClient.lastName}
${constants.balance}: ${currentClient.balance}
${constants.debt} ${this.props.totalDebt}
${location.search ? this.showFilterPDF(location.search.slice(1)) : ""}
              `;
  };

  showFilterPDF = (query) => {
    const { orderData, employees } = this.props;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.clientOrderFilterNumber:
          chosenFilters["Номер"] = arrayWithFilters[indx].split("=")[1];
          break;
        case constants.optionOrderFirst:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("orderDateEnd"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Дата замовлення"] =
              moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Дата замовлення"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        case constants.optionPaidFirst:
          const paidStart = arrayWithFilters[indx].split("=")[1];
          const paidEnd = arrayWithFilters
            .find((el) => el.startsWith("paidDateEnd"))
            .split("=")[1];
          if (paidStart === paidEnd)
            chosenFilters["Дата оплати"] =
              moment(paidStart).format("DD.MM.YYYY");
          else
            chosenFilters["Дата оплати"] = `${moment(paidStart).format(
              "DD.MM.YYYY"
            )} - ${moment(paidEnd).format("DD.MM.YYYY")}`;
          break;
        case constants.clientOrderFilterPaymentMethodId:
          const paymentMethod = orderData.paymentMethods.filter(
            (el) => el.id === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters["Спосіб оплати"] = paymentMethod[0].name;
          break;
        case constants.clientOrderFilterPaydeskId:
          const paydeskId = orderData.paydesks.filter(
            (el) => el.id === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters["ФОП"] = paydeskId[0].name;
          break;
        case constants.clientOrderFilterEmployeeId:
          const employeeId = employees.filter(
            (el) => el.id === arrayWithFilters[indx].split("=")[1]
          );
          chosenFilters[
            "Касир"
          ] = `${employeeId[0].firstName} ${employeeId[0].lastName}`;
          break;
        default:
          return chosenFilters;
      }
    });
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  additionalComponentsTops = () => {
    const { visible, closeModal, currentId, currentClient } = this.props;
    return (
      <>
        <div className="clientsHeader">
          <div className="client-orders-details__description">
            <p>
              <span>{constants.fullName}</span> {currentClient.firstName}{" "}
              {currentClient.lastName}
            </p>
            <p>
              <span>{constants.balance}</span> {currentClient.balance}
            </p>
            <p>
              <span>{constants.debt}</span> {currentClient.debt}
            </p>
          </div>

          <div className="order-payment-details__btn-print">
            <button onClick={() => this.handleDataExportPDF(this.props.data)}>
              {texts.btn}
            </button>
          </div>
        </div>
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="order-payment-details"
            width={1100}
          >
            <h3 className="order-payment-details__title">
              {texts.modalTitle}: {currentId && currentId.orderNumber}
            </h3>
            <ClientOrderDetails />
            <div className="order-payment-details__btn-print">
              <button
                onClick={() =>
                  this.handleHistoryDataExportPDF(this.props.historyData)
                }
              >
                {texts.btn}
              </button>
            </div>
          </Modal>
        )}
      </>
    );
  };

 additionalComponents = () => {
  return (
     <>
       {/\/clients\/\w+\/orders/g.test(this.props.location.pathname) &&
       this.props.data.length !== 0 ? (
           <div className="summary">
             <div className="right">
               <h4>Сума: {this.props.totalAmount} грн.</h4>
               <h4>Оплачено: {this.props.totalPaidSum} грн.</h4>
               <h4>
                 Борг:
                 {this.props.totalDebt !== 0
                     ? ` -${this.props.totalDebt}`
                     : 0}{" "}
                 грн.
               </h4>
             </div>
           </div>
       ) : (
           ""
       )}
     </>
  );
 }
}

const mapStateToProps = ({
  clients,
  pagination,
  createOrder,
  login,
  employees,
}) => ({
  ...pagination,
  loading: clients.loading,
  data: clients.ordersData,
  visible: clients.visible,
  currentId: clients.currentId,
  orderData: createOrder.orderData,
  historyData: clients.ordersDetails,
  userRole: login.user.role,
  currentClient: clients.currentClient,
  employees: employees.allCashiers,
  totalAmount: clients.totalAmount,
  totalDebt: clients.totalDebt,
  totalPaidSum: clients.totalPaidSum
});

const mapDispatchToProps = {
  getData: getAllOrdersByClientId,
  getDataForCreateOrder,
  openModal,
  closeModal,
  setPageNumber,
  setCurrentDataClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOrders);
