import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { Button } from "antd";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import {
  openModal,
  closeModal,
  createNewService,
  getAllServices,
  blockService,
  unlockService,
  setInitialData,
  setQueriesData,
} from "../../redux/services";
import { setPageNumber } from "../../redux/pagination";
import Modal from "../../components/Modal";
import ServiceForm from "./ServiceForm";
import ServicesSelect from "../../components/GeneralFields/Select/ServicesSelect";
import SearchField from "../../components/GeneralFields/SearchField";
import { roleNames } from "../../roles";

const constants = {
  serviceFilterId: "serviceId",
  serviceFilterParameter: "parameter",
  serviceFilterPrice: "price",
};

class Service extends GeneralPage {
  class = "services";
  columns = columns.general;

  componentDidMount = () => {
    const { getData, setQueriesData } = this.props;
    const query = queryString.parse(this.props.location.search);
    let sort = {};
    for (let property in query) {
      if (property === constants.serviceFilterPrice) {
        sort[property] = query[property];
      }
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        setQueriesData(this.state);
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  additionalComponentsTops = () => {
    return (
        <>
          <div className="cash-register-history__paydesk-balance default-btn">
            <h2 className="page-title">
              {this.pageTitle} <span>{this.subTittle}</span>
            </h2>
          </div>
        </>
    );
  };

  state = {
    name: undefined,
  };

  addFilters = () => {
    const { createNewService, userRole } = this.props;
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        {userRole === roleNames.superAdmin && (
            <div className="btn-add">
              <Button onClick={createNewService}>+</Button>
            </div>
        )
        }
        <ServicesSelect
          value={query[constants.serviceFilterId]}
          selectValueSize={40}
          getSelectedValue={this.getFilterValue}
        />
        <SearchField
          defaultValue={query[constants.serviceFilterParameter]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.serviceFilterParameter
            )
          }
        />
        <SearchField
          type="number"
          defaultValue={query[constants.serviceFilterPrice]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.serviceFilterPrice
            )
          }
        />
      </>
    );
  };

  additionalComponents = () => {
    const { visible, closeModal } = this.props;
    return (
      <>
        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="logs-modal"
            width={500}
          >
            <ServiceForm />
          </Modal>
        )}
      </>
    );
  };
}

const mapStateToProps = ({ services, pagination, login }) => ({
  ...services,
  ...pagination,
  userRole: login.user.role,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  createNewService,
  getData: getAllServices,
  blockItem: blockService,
  unblockItem: unlockService,
  setInitialData,
  setPageNumber,
  setQueriesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Service);
