import React, { Component } from "react";
import MainTable from "../../components/Table";
import queryString from "query-string";

const constants = {
  firstPage: 1,
};

Object.size = function (dataRangeQuery) {
  var size = 0;
  for (let key in dataRangeQuery) {
    if (dataRangeQuery.hasOwnProperty(key)) size++;
  }
  return size;
};

export default class GeneralPage extends Component {
  columns = [];
  subColumns = [];
  class = "";
  pageTitle = "";
  subTittle = "";

  componentDidMount = () => {
    const {
      setQueriesData,
      getData,
      location: { search },
    } = this.props;
    const query = queryString.parse(search);

    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
      }),
      () => {
        setQueriesData && setQueriesData(this.state);
        getData(this.state);
      }
    );
  };

  componentWillUnmount = () => this.props.setPageNumber(constants.firstPage);

  additionalComponents = () => null;

  additionalComponentsTops = () => null;

  onPaginationPageChange = (page) => {
    const { getData, history } = this.props;
    getData({ pageNumber: page, ...this.state }, history);
    this.addQuery("page", page);
  };

  addQuery = (key, value) => {
    let pathname = this.props.location.pathname;
    let searchParams = new URLSearchParams(this.props.location.search);

    searchParams.set(key, value);
    this.props.history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  getSortValue = (option, name) => {
    const {
      history: {
        push,
        location: { pathname },
      },
      getData,
      setPageNumber,
      setQueriesData,
    } = this.props;
    const value = option ? option.value : undefined;
    const query = queryString.parse(this.props.location.search);
    query[name] = value;
    let newQuery;
    if (value === "") {
      delete query[name];
      newQuery = queryString.stringify(query);
    } else {
      newQuery = queryString.stringify(query);
    }
    push(`${pathname}?${newQuery}`);
    this.setState(
      (prevState) => ({
        ...prevState,
        sort: {
          ...query,
        },
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state });
        setQueriesData &&
          setQueriesData({ pageNumber: query.page, ...this.state });
        setPageNumber(query.page);
      }
    );
  };

  getFilterValue = (option, name = "") => {
    const {
      history: {
        push,
        location: { pathname },
      },
      getData,
      setPageNumber,
      setQueriesData,
    } = this.props;

    const query = queryString.parse(this.props.location.search);

    if (Object.size(option) > 1) {
      let finalQuery = { ...query };
      for (let property in option) {
        if (option[property] === "") {
          delete finalQuery[property];
        } else {
          finalQuery = { ...finalQuery, [property]: option[property] };
        }
      }
      const newQuery = queryString.stringify(finalQuery);
      push(`${pathname}?${newQuery}`);
      this.setState(
        (prevState) => ({
          ...prevState,
          ...option,
        }),
        () => {
          getData({ pageNumber: constants.firstPage, ...this.state });
          setQueriesData &&
            setQueriesData({ pageNumber: constants.firstPage, ...this.state });
          setPageNumber(constants.firstPage);
        }
      );
    } else {
      const value = option ? option.value : undefined;
      query[name] = value;
      let newQuery;
      if (value === "") {
        delete query[name];
        newQuery = queryString.stringify(query);
      } else {
        newQuery = queryString.stringify(query);
      }
      push(`${pathname}?${newQuery}`);
      this.setState(
        (prevState) => ({
          ...prevState,
          [name]: value,
        }),
        () => {
          getData({ pageNumber: constants.firstPage, ...this.state });
          setQueriesData &&
            setQueriesData({ pageNumber: constants.firstPage, ...this.state });
          setPageNumber(constants.firstPage);
        }
      );
    }
  };

  addFilters = () => null;

  render() {
    return (
      <div className={`main-content ${this.class}`}>
        {this.additionalComponentsTops()}
        <MainTable
          data={this.props.data}
          columns={this.columns}
          subColumns={this.subColumns}
          filters={this.addFilters()}
          onPaginationPageChange={this.onPaginationPageChange}
          {...this.props}
        />
        {this.additionalComponents()}
      </div>
    );
  }
}
