const columns = {
  general: [
    {
      expander: true,
      show: true,
      minWidth: 20,
    },
    {
      accessor: "number",
      Header: "ID",
      minWidth: 120,
      sortable: false,
      filterable: false,
      filter: "text",
    },
    {
      accessor: "createdAt",
      Header: "Дата",
      minWidth: 200,
      sortable: false,
      filterable: false,
      formatter: "date",
    },
    {
      accessor: "provider",
      Header: "Постачальник",
      minWidth: 120,
      sortable: false,
      filterable: false,
      filter: "text",
    },
    {
      accessor: "name",
      Header: "Матеріал",
      minWidth: 120,
      filterable: false,
      sortable: false,
      filter: "materialSelect",
    },
    {
      accessor: "amount",
      Header: "Кількість",
      minWidth: 120,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "inputPrice",
      Header: "Вхідна ціна",
      minWidth: 120,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "outputPrice",
      Header: "Вихідна ціна",
      minWidth: 120,
      sortable: false,
      filterable: false,
    },
    {
      accessor: "isDeleted",
      Header: "",
      minWidth: 30,
      formatter: "deleteComing",
      sortable: false,
      filterable: false,
    },
    {
      accessor: "edit",
      Header: "",
      minWidth: 30,
      formatter: "editComing",
      sortable: false,
      filterable: false,
    },
  ],
  subColumns: [
    {
      expander: true,
      show: false,
      minWidth: 20,
    },
    {
      accessor: "number",
      Header: "ID",
      minWidth: 120,
    },
    {
      accessor: "createdAt",
      Header: "Дата",
      minWidth: 200,
    },
    {
      accessor: "provider",
      Header: "Постачальник",
      minWidth: 120,
    },
    {
      accessor: "name",
      minWidth: 120,
      sortable: false,
    },
    {
      accessor: "amount",
      minWidth: 120,
      sortable: false,
    },
    {
      accessor: "inputPrice",
      minWidth: 120,
      sortable: false,
    },
    {
      accessor: "outputPrice",
      minWidth: 120,
      sortable: false,
    },
    {
      accessor: "isDeleted",
      Header: "",
      minWidth: 30,
    },
    {
      accessor: "edit",
      Header: "",
      minWidth: 30,
    },
  ],
};

export default columns;
