const { NODE_ENV } = process.env;
let redirectURI = process.env.REACT_APP_REDIRECT_URI;
let logoutRedirectURI = process.env.REACT_APP_LOGOUT_REDIRECT_URI;

if (NODE_ENV === 'development') {
  redirectURI = 'http://localhost:3000';
  logoutRedirectURI = 'http://localhost:3000';
}

const server = process.env.REACT_APP_IDENTITY_SERVER_URI;
const clientId = 'bigdruk';
const scope = 'openid bigdruk.api identity.api';
const pass = 'secret';

const OidcSettings = {
  authority: server,
  client_id: clientId,
  redirect_uri: redirectURI,
  scope: scope,
  secret: pass,
  response_type: 'id_token token',
  post_logout_redirect_uri: logoutRedirectURI
};

export default OidcSettings;
