import React from 'react';

export const orderReplenishBalance = (clientFirstName, clientLastName, openModal, id, debt) => {
  if (!clientFirstName &&  !clientLastName) {
    return "-"
  } else if (clientFirstName === "Фізична" && clientLastName === "Ocoба") {
    return (
      <div className="orders-replenish-formatter">
        <p>{clientFirstName} {clientLastName}</p>
      </div>
    )
  } else if (debt <= 0 && clientFirstName !== "Фізична" && clientLastName !== "Ocoба") {
    return (
      <div className="orders-replenish-formatter">
        <p>{clientFirstName} {clientLastName}</p>
      </div>
    )
  } else {
    return (
      <div className="orders-replenish-formatter">
        <p>{clientFirstName} {clientLastName}</p>
      </div>
    )
  }
}
