import React from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form";
import Form from "../../../components/Form";
import { getPaymentMethods } from "../../../redux/clients";
import { replenishBalance } from "../../../redux/orders";
import {
  NumberMaskField,
  EntrepreneurReactSelect,
  RadioField,
  TextField,
} from "../../../components/Form/Inputs";
import {
  composeValidators,
  makeFormValidator,
  validationTexts,
} from "../../../components/Form/validation";

const paymentMethodsType = {
  cash: 1,
  cashless: 2,
  balance: 3,
  recalculation: 4,
};

const texts = {
  amount: "Сума",
  btnText: "Зберегти",
  title: "Додати кошти до замовлення",
  received: "Отримано",
  FOP: "ФОП",
  replenishBalance: "Виберіть спосіб оплати",
  conversionNumber: "Номер рахунку",
};

class ReplenishBalance extends Form {
  title = texts.title;
  state = {
    value: null,
  };

  componentDidMount = () => this.props.getPaymentMethods();

  onChange = (e) => {
    const currentPaymentMethod = this.props.data.paymentMethods.find(
      (item) => item.id === e.target.value
    );

    this.setState((prevState) => {
      return {
        ...prevState,
        value: e.target.value,
        currentType: currentPaymentMethod.paymentMethodType,
      };
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.data.paymentMethods.length !==
      this.props.data.paymentMethods.length
    ) {
      const { id, paymentMethodType } = this.props.data.paymentMethods[3];
      this.setState((prevState) => {
        return {
          ...prevState,
          value: id,
          currentType: paymentMethodType,
        };
      });
    } else if (
      prevProps.data.paymentMethods &&
      prevProps.data.paymentMethods.length &&
      !this.state.value
    ) {
      const { id, paymentMethodType } = prevProps.data.paymentMethods[3];
      this.setState((prevState) => {
        return {
          ...prevState,
          value: id,
          currentType: paymentMethodType,
        };
      });
    }
  };

  onValidate = makeFormValidator({
    amount: composeValidators({
      [validationTexts.required]: (value) =>
        value === undefined || value.toString().trim().length === 0,
      [validationTexts.minValue()]: (value) => (value ? value < 0 : false),
    }),
    paydeskId: composeValidators({
      [validationTexts.required]: (value) => {
        if (this.state.currentType !== paymentMethodsType.balance) {
          return value === undefined || value.toString().trim().length === 0;
        } else {
          return false;
        }
      },
    }),
  });

  onSubmit = (props) => {
    const { currentId, pageNumber, replenishBalance, currentCashier } =
      this.props;
    const { value, currentType } = this.state;

    const finalData = {
      ...props,
      paymentMethodId: value,
      paydeskId:
        currentType !== paymentMethodsType.balance
          ? props.paydeskId?.value
          : null,
      orderId: currentId,
      employeeId: currentCashier,
      transferNumber:
        currentType === paymentMethodsType.recalculation
          ? props.transferNumber
          : null,
    };
    replenishBalance(finalData, pageNumber);
  };

  renderFields = () => (
    <>
      <div className="c-form__item">
        <div className="c-form__item-group">
          <Field
            className="c-form__field"
            name="amount"
            component={NumberMaskField}
            label={texts.amount}
            required
          />
          {this.state.currentType !== paymentMethodsType.balance && (
            <Field
              className="c-form__field"
              name="paydeskId"
              component={EntrepreneurReactSelect}
              label={texts.FOP}
              required
            />
          )}
        </div>
        <Field
          className="c-form__field"
          name="paymentMethod"
          options={this.props.data.paymentMethods}
          component={RadioField}
          onChange={this.onChange}
          value={this.state.value}
          label={texts.replenishBalance}
          type="radio"
        />

        {this.state.currentType === paymentMethodsType.recalculation && (
          <Field
            className="c-form__field"
            name="transferNumber"
            component={TextField}
            label={texts.conversionNumber}
            required
            spanClassName="transfer-number"
          />
        )}
        <div className="c-form__submit">
          <button className="c-button" type="submit">
            {texts.btnText}
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  data: state.clients.methodsData,
  currentId: state.orders.currentId,
  pageNumber: state.pagination.pageNumber,
  currentCashier: state.login.user.id,
  notification: state.notification,
});

const mapDispatchToProps = {
  getPaymentMethods,
  replenishBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplenishBalance);
