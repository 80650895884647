import React from "react";
import queryString from "query-string";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import { connect } from "react-redux";
import * as moment from "moment";
import { getAllHistoryBalances } from "../../redux/balanceHistory";
import { getDataForCreateOrder } from "../../redux/createOrder";
import { setPageNumber } from "../../redux/pagination";
import CashierSelect from "../../components/GeneralFields/Select/СashierSelect";
import ClientsSelect from "../../components/GeneralFields/Select/ClientsSelect";
import TransactionSelect from "../../components/GeneralFields/Select/TransactionSelect";
import PaymentMethodsSelect from "../../components/GeneralFields/Select/PaymentMethodsSelect";
import SearchField from "../../components/GeneralFields/SearchField";
import EntrepreneursSelect from "../../components/GeneralFields/Select/EntrepreneursSelect";
import DateRangePicker from "../../components/GeneralFields/RangePicker";
import SortField from "../../components/GeneralFields/SortField";
import { handleShowTransactionType } from "../../helpers/formatters/transactions";
import { exportPDF } from "../../components/ExportToPdf";
import {fetchAllClientsBalanceHistoryForPdf, fetchBalances,} from "../../http/historyBalances";

const dataHeaders = [
  [
    "НАЗВА ФОП",
    "КАСИР",
    "КЛІЄНТ",
    "ДАТА",
    "СУМА",
    "ЗАЛИШОК",
    "СПОСІБ ОПЛАТИ",
    "НОМЕР ПЕРЕРАХУНКУ",
    "ТРАНЗАКЦІЯ",
    "НОМЕР ЗАМОВЛЕННЯ",
  ],
];

const constants = {
  format: "YYYY-MM-DD",
  optionStartDate: "startDate",
  optionEndDate: "endDate",
  historyBalancesFilterPaydeskId: "paydeskId",
  historyBalancesFilterClientId: "clientId",
  historyBalancesFilterPaymentMethodId: "paymentMethodId",
  historyBalancesFilterPaymentTransaction: "Transaction",
  historyBalancesFilterSum: "Sum",
  historyBalancesFilterRestBalance: "RestBalance",
  historyBalancesFilterTransferNumber: "transferNumber",
  historyBalancesFilterOrderNumber: "orderNumber ",
  historyBalancesFilterCashierId: "cashierId",
};

const texts = {
  modalTitle: "Історія балансу",
  btn: "Друк",
};

class HistoryBalances extends GeneralPage {
  pageTitle = "Iсторiя балансу";
  class = "balances-history";
  columns = columns.general;

  state = {
    name: undefined,
    sort: {},
  };

componentDidMount = () => {
  const { getData, getDataForCreateOrder, orderData } = this.props;
    const query = queryString.parse(this.props.location.search);
    !orderData.services.length && getDataForCreateOrder();
    let sort = {};
    for (let property in query) {
      if (property === constants.historyBalancesFilterSum) {
        sort[property] = query[property];
      } else if (property === constants.historyBalancesFilterRestBalance) {
        sort[property] = query[property];
      }
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  getDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.optionStartDate] = "";
      changeData[constants.optionEndDate] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionStartDate] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.optionEndDate] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

  handleDataExportPDF = async () => {
    const defaultQuery = this.props.location.search;
    const filters = defaultQuery ? defaultQuery : '?page=1'

    const urlTest = new URLSearchParams(this.props.location.search)
    let data = null
    if(urlTest.get('Sum') || urlTest.get('RestBalance')) {
      try {
        let sort = {}
        if(urlTest.get('Sum')){
          sort.Sum = urlTest.get('Sum')
        }
        if(urlTest.get('RestBalance')) {
          sort.RestBalance = urlTest.get('RestBalance')
        }
        data = (await fetchBalances({
          ...Object.fromEntries(urlTest.entries()),
          pageSize: 999,
          sort: sort
        })).data

      } catch (e) {
        console.error(e)
      }
    } else {
      data = (await fetchAllClientsBalanceHistoryForPdf(filters,'balance-grid')).data
    }

    const finalData = data.items.map((elt) => {
      return [
        elt.paydeskName,
        elt.cashierName,
        elt.clientName,
        moment(elt.date).format("DD.MM.YYYY HH:mm"),
        elt.sum,
        elt.restBalance,
        elt.paymentMethodName,
        elt.transferNumber,
        handleShowTransactionType(elt.transaction),
        elt.orderNumber,
      ];
    });
    exportPDF({
      tableData: finalData,
      header: dataHeaders,
      format: "landscape",
      contentBefore: `${this.pageTitle} \n${this.showContentBeforeTablePDF()}`,
    });


  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return `
${
  /clientId/.test(location.search)
    ? this.showDataAboutClientPDF(location.search.slice(1))
    : ""
}    
${location.search ? this.showFilterPDF(location.search.slice(1)) : ""}
              `;
  };

  showDataAboutClientPDF = (query) => {
    const { clients } = this.props;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");

    arrayWithFilters.forEach((el, indx) => {
      if (
        arrayWithFilters[indx].split("=")[0] ===
        constants.historyBalancesFilterClientId
      ) {
        const clientId = arrayWithFilters[indx].split("=")[1];
        const client = clients.allData.filter((el) => el.id === clientId);
        chosenFilters["ПІБ"] = client[0].fullName;
        chosenFilters["Залишок"] = client[0].balance;
      }
    });

    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  showFilterPDF = (query) => {
    const { orderData, employees } = this.props;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.historyBalancesFilterPaydeskId:
          const paydeskId = arrayWithFilters[indx].split("=")[1];
          const paydesk = orderData.paydesks.filter(
            (el) => el.id === paydeskId
          );
          chosenFilters["НАЗВА ФОП"] = paydesk[0].name;
          break;
        case constants.historyBalancesFilterCashierId:
          const cashierId = arrayWithFilters[indx].split("=")[1];
          const cashier = employees.allCashiers.filter(
            (el) => el.id === cashierId
          );
          chosenFilters["Касир"] = cashier[0].fullName;
          break;
        case constants.optionStartDate:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("endDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Дата"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Дата"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        case constants.historyBalancesFilterPaymentMethodId:
          const paymentMethodID = arrayWithFilters[indx].split("=")[1];
          const paymentMethod = orderData.paymentMethods.filter(
            (el) => el.id === paymentMethodID
          );
          chosenFilters["Спосіб оплати"] = paymentMethod[0].name;
          break;
        case constants.historyBalancesFilterTransferNumber:
          const transaferNumber = arrayWithFilters[indx].split("=")[1];
          chosenFilters["Номер перерахунку"] = transaferNumber;
          break;
        case constants.historyBalancesFilterPaymentTransaction:
          const paymentTransaction = arrayWithFilters[indx].split("=")[1];
          if (paymentTransaction === "Order")
            chosenFilters["Транзакція"] = "Замовлення";
          if (paymentTransaction === "CashBack")
            chosenFilters["Транзакція"] = "Повернення";
          if (paymentTransaction === "Recharge")
            chosenFilters["Транзакція"] = "Поповнення";
          break;
        default:
          return chosenFilters;
      }
    });
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        <EntrepreneursSelect
          value={query[constants.historyBalancesFilterPaydeskId]}
          label=""
          getSelectedValue={this.getFilterValue}
          name="paydeskId"
        />
        <CashierSelect
          value={query[constants.historyBalancesFilterCashierId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
        />
        <ClientsSelect
          value={query[constants.historyBalancesFilterClientId]}
          label=""
          getSelectedValue={this.getFilterValue}
          selectValueSize={25}
        />
        <DateRangePicker
          defaultValue={[
            query[constants.optionStartDate],
            query[constants.optionEndDate],
          ]}
          onChange={this.getDate}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.historyBalancesFilterSum}
          value={query[constants.historyBalancesFilterSum]}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.historyBalancesFilterRestBalance}
          value={query[constants.historyBalancesFilterRestBalance]}
        />
        <PaymentMethodsSelect
          value={query[constants.historyBalancesFilterPaymentMethodId]}
          label=""
          getSelectedValue={this.getFilterValue}
        />
        <SearchField
          defaultValue={query[constants.historyBalancesFilterTransferNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.historyBalancesFilterTransferNumber
            )
          }
          spanClass="transfer-number"
        />
        <TransactionSelect
          value={query[constants.historyBalancesFilterPaymentTransaction]}
          label=""
          getSelectedValue={this.getFilterValue}
        />
        {/* <SearchField
          defaultValue={query[constants.historyBalancesFilterOrderNumber]}
          label=""
          onChange={e => this.getFilterValue({ value: e.currentTarget.value }, constants.historyBalancesFilterOrderNumber)}
        /> */}
      </>
    );
  };

  additionalComponentsTops = () => {
    return (
          <div className="cash-register-history__paydesk-balance default-btn">
            <h2 className="page-title">
              {this.pageTitle} <span>{this.subTittle}</span>
            </h2>
            <div className="order-payment-details__btn-print">
              <button onClick={() => this.handleDataExportPDF(this.props.data)}>
                {texts.btn}
              </button>
            </div>
          </div>
    );
  };
}

const mapStateToProps = ({
  balancesHistory,
  pagination,
  createOrder,
  clients,
  employees,
}) => ({
  ...balancesHistory,
  ...pagination,
  clients,
  employees,
  orderData: createOrder.orderData,
});

const mapDispatchToProps = {
  getData: getAllHistoryBalances,
  setPageNumber,
  getDataForCreateOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryBalances);
