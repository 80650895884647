import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import {
  openModal,
  closeModal,
  createNewComing,
  getAllComings,
  setInitialData,
  blockComing,
  setQueriesData,
} from "../../redux/comings";
import { getDataForCreateOrder } from "../../redux/createOrder";
import { Button } from "antd";
import Modal from "../../components/Modal";
import ComingForm from "./ComingForm";
import SearchField from "../../components/GeneralFields/SearchField";
import DateRangePicker from "../../components/GeneralFields/RangePicker";
import { setPageNumber } from "../../redux/pagination";
import { roleNames } from "../../roles";
import SortField from "../../components/GeneralFields/SortField";
import * as moment from "moment";
import {fetchAllComingsForPdf, fetchComings} from "../../http/comings";
import {exportPDF} from "../../components/ExportToPdfForComing";
import AllMaterialSelect from "../../components/GeneralFields/Select/AllMaterialSelect";

const dataHeaders = [
  [
    "ID",
    "ДАТА",
    "ПОСТАЧАЛЬНИК",
    "МАТЕРІАЛ",
    "КІЛЬКІСТЬ",
    "ВХІДНА ЦІНА",
    "ВИХІДНА ЦІНА",
  ],
];

const constants = {
  format: "YYYY-MM-DD",
  comingFilterNumber: "number",
  comingFilterMaterialId: "materialId",
  comingFilterAmount: "TotalMaterialsAmount",
  comingFilterProvider: "provider",
  comingFilterInputPrice: "inputPrice",
  comingFilterOutputPrice: "outputPrice",
  comingsStartDate: "startDate",
  comingsEndDate: "endDate",
};

const texts = {
  btnAdd: "+ Прихід",
  btnPrint: "Друкувати звіт",
};

class Comings extends GeneralPage {
  pageTitle = "Прихід матеріалів";
  class = "comings";
  columns = columns.general;
  subColumns = columns.subColumns;
  state = {
    sort: {},
  };

  componentDidMount = () => {
    const { getData, getDataForCreateOrder, setQueriesData } = this.props;
    const query = queryString.parse(this.props.location.search);
    let sort = {};
    getDataForCreateOrder();
    for (let property in query) {
      if (property === constants.comingFilterAmount) {
        sort[property] = query[property];
      }
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
        sort,
      }),
      () => {
        setQueriesData(this.state);
        getData({ pageNumber: query.page, ...this.state });
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  getSortValue = (option, name) => {
    const {
      history: {
        push,
        location: { pathname },
      },
      getData,
      setPageNumber,
      setQueriesData,
    } = this.props;
    const value = option ? option.value : undefined;
    const query = queryString.parse(this.props.location.search);
    query[name] = value;
    let newQuery;
    if (value === "") {
      delete query[name];
      newQuery = queryString.stringify(query);
    } else {
      newQuery = queryString.stringify(query);
    }
    push(`${pathname}?${newQuery}`);
    this.setState(
      (prevState) => ({
        ...prevState,
        sort: {
          ...query,
        },
      }),
      () => {
        getData({ pageNumber: 1, ...this.state });
        setQueriesData({ pageNumber: 1, ...this.state });
        setPageNumber(constants.firstPage);
      }
    );
  };

  getDate = (data) => {
    let changeData = {};
    if (!data) {
      changeData[constants.comingsStartDate] = "";
      changeData[constants.comingsEndDate] = "";
      return this.getFilterValue(changeData);
    }
    data.map((item, index) => {
      let dateRange;
      if (index === 0) {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.comingsStartDate] = dateRange);
      } else {
        dateRange = item.local().format(constants.format);
        return (changeData[constants.comingsEndDate] = dateRange);
      }
    });
    return this.getFilterValue(changeData);
  };

  cleanEmptyValuesFromData = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };

  handleDataExportPDF = async () => {
    const defaultQuery = this.props.location.search;
    const filters = defaultQuery ? defaultQuery : '?page=1'
    const urlTest = new URLSearchParams(this.props.location.search)

    let data = null;
    if(urlTest.get('TotalMaterialsAmount')) {
      try {
        let sort = {}
        if(urlTest.get('TotalMaterialsAmount')){
          sort.TotalMaterialsAmount = urlTest.get('TotalMaterialsAmount')
        }
        data = (await fetchComings({
          ...Object.fromEntries(urlTest.entries()),
          pageSize:999,
          sort: sort
        })).data
      } catch (e) {
        console.error(e)
      }
    } else {
      data = (await fetchAllComingsForPdf(filters, 'grid')).data
    }
    const finalData = data.items.map((elt) => {
        const { materials } = elt;
      const resultSort = materials.sort((a, b) => a.name.localeCompare(b.name))

        return [
          elt.number,
          moment(elt.createdAt).format("DD.MM.YYYY HH:mm"),
          elt.provider,
          `${this.showMaterialsNamePDF(resultSort)}`,
          `${this.showMaterialsAmountPDF(resultSort)}`,
          `${this.showMaterialsInputPricePDF(resultSort)}`,
          `${this.showMaterialsOutputPricePDF(resultSort)}`,
        ];
      });

    exportPDF({
      tableData: finalData,
      header: dataHeaders,
      format: "landscape",
      contentBefore: `${this.pageTitle} ${this.showContentBeforeTablePDF()}`,
    });
  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return ` 
${location.search ? this.showFilterPDF(location.search.slice(1)) : ""}
              `;
  };

  showFilterPDF = (query) => {
    const { data, queriesData } = this.props;
    const materials = data.length ? data[0].materials : null;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");

    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.comingFilterNumber:
          const filterNumber = arrayWithFilters[indx].split("=")[1];
          chosenFilters["ID"] = filterNumber;
          break;
        case constants.comingsStartDate:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("endDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Дата"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Дата"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        case constants.comingFilterProvider:
          chosenFilters["Постачальник"] = queriesData.provider;
          break;
        case constants.comingFilterMaterialId:
          const materialName = data[0].materials.find(m => m.id === arrayWithFilters[indx].split("=")[1]);
          chosenFilters["Матеріал"] = materials.length > 1 ? materialName.name :  materials[0].name;
          break;
        case constants.comingFilterInputPrice:
          chosenFilters["Вхідна ціна"] = arrayWithFilters[indx].split("=")[1];
          break;
        case constants.comingFilterOutputPrice:
          chosenFilters["Вихідна ціна"] = arrayWithFilters[indx].split("=")[1];
          break;
        default:
          return chosenFilters;
      }
    });
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  showMaterialsNamePDF = (data) => {
    const allData = [...data];
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("name")) {
          result.push(el.name);
        }
        return result;
      });
    }
    return result.sort((a,b) => a.localeCompare(b)).join("\r\n");
  };

  showMaterialsAmountPDF = (data) => {
    const allData = [...data];
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("amount")) {
          result.push(el.amount);
        }
        return result;
      });
    }
    return result.join("\r\n");
  };

  showMaterialsInputPricePDF = (data) => {
    const allData = [...data];
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("inputPrice")) {
          result.push(el.inputPrice);
        }
        return result;
      });
    }
    return result.join("\r\n");
  };

  showMaterialsOutputPricePDF = (data) => {
    const allData = [...data];
    let result = [];
    if (allData.length !== 0) {
      allData.map((el) => {
        if (el.hasOwnProperty("outputPrice")) {
          result.push(el.outputPrice);
        }
        return result;
      });
    }
    return result.join("\r\n");
  };

  addFilters = () => {
    const query = queryString.parse(this.props.location.search);
    return (
      <>
        <SearchField
          defaultValue={query[constants.comingFilterNumber]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.comingFilterNumber
            )
          }
        />
        <DateRangePicker
          defaultValue={[
            query[constants.comingsStartDate],
            query[constants.comingsEndDate],
          ]}
          onChange={this.getDate}
        />
        <SearchField
          defaultValue={query[constants.comingFilterProvider]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.comingFilterProvider
            )
          }
        />
        <AllMaterialSelect
          value={query[constants.comingFilterMaterialId]}
          getSelectedValue={this.getFilterValue}
          selectValueSize={15}
        />
        <SortField
          label=""
          getSelectedValue={this.getSortValue}
          name={constants.comingFilterAmount}
          value={query[constants.comingFilterAmount]}
        />
        <SearchField
          defaultValue={query[constants.comingFilterInputPrice]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.comingFilterInputPrice
            )
          }
        />
        <SearchField
          defaultValue={query[constants.comingFilterOutputPrice]}
          label=""
          onChange={(e) =>
            this.getFilterValue(
              { value: e.currentTarget.value },
              constants.comingFilterOutputPrice
            )
          }
        />
      </>
    );
  };

  additionalComponentsTops = () => {
    const { visible, closeModal, queriesData, createNewComing, userRole } = this.props;
    return (
      <>
        <div className="cash-register-history__paydesk-balance default-btn">
          <h2 className="page-title">
            {this.pageTitle} <span>{this.subTittle}</span>
          </h2>
          <div className="comings__btn">
            {userRole === roleNames.superAdmin && (
                <Button onClick={createNewComing}>{texts.btnAdd}</Button>
            )}
            {this.props.data.length ? (
                <Button
                    onClick={() => this.handleDataExportPDF()}
                    style={{ backgroundColor: "#00CBA6", color: "white" }}
                >
                  {texts.btnPrint}
                </Button>
            ) : null}
          </div>

        </div>
        {visible && (
          <Modal
            visible={visible}
            onCancel={() => closeModal(queriesData)}
            footer={null}
            className="logs-modal"
            width={900}
            title=""
          >
            <ComingForm />
          </Modal>
        )}
      </>
    );
  };

}

const mapStateToProps = ({ pagination, comings, createOrder, login }) => ({
  ...pagination,
  data: comings.materialData,
  visible: comings.visible,
  loading: comings.loading,
  orderData: createOrder.orderData,
  userRole: login.user.role,
  queriesData: comings.queriesData,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  createNewComing,
  getData: getAllComings,
  blockItem: blockComing,
  setInitialData,
  setPageNumber,
  getDataForCreateOrder,
  setQueriesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Comings);
