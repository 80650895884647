import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/uk";
import { CalendarIcon } from "../../../icons";
import locale from "antd/es/date-picker/locale/uk_UA";
const format = "DD-MM-YYYY";

const { RangePicker } = DatePicker;

const DateRangePicker = ({
  label,
  className,
  defaultValue,
  onChange,
  maxDate = moment().add(1, "days").format(),
}) => {
  const minimumDate = moment("2019-01-01", "YYYY-MM-DD");
  const maximumDate = moment(maxDate, "YYYY-MM-DD");
  const placeholder = "";
  const changeDefaultValue =
    defaultValue && defaultValue[0]
      ? [moment(defaultValue[0]), moment(defaultValue[1])]
      : null;

  return (
    <span className="range-picker" key="index">
      <label>{label}</label>
      <RangePicker
        className={className}
        dropdownClassName="range-picker-dropdown"
        defaultValue={changeDefaultValue}
        format={format}
        size="large"
        locale={locale}
        onChange={onChange}
        suffixIcon={
          <div className="calendar-icon">
            <CalendarIcon />
          </div>
        }
        placeholder={placeholder}
        disabledDate={(d) =>
          !d || d.isBefore(minimumDate) || d.isAfter(maximumDate)
        }
        inputReadOnly={true}
        ranges={{
          "Поточний день": [moment(), moment()],
          "Поточний місяць": [moment().startOf("month"), moment()],
        }}
        {...moment.locale("uk")}
      />
    </span>
  );
};

export default DateRangePicker;
