import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import columns from "./columns";
import * as moment from "moment";
import GeneralPage from "../../GeneralPage";
import { setPageNumber } from "../../../redux/pagination";
import { downloadReportPdf, getReportByProduct } from "../../../redux/reports";
import { getMaterials } from "../../../redux/materials";
import { exportPDF } from "../../../components/ExportToPdf";
import {fetchAllReportForPdf} from "../../../http/reports";

const texts = {
  btn: "Друк",
  totalAmount: "Загальна кількість",
  totalCost: "Загальна вартість",
  reportByMaterial: "Звіт по матеріалу",
};

const constants = {
  materialEntityId: "EntityId",
  optionFirstDay: "StartDate",
  optionLastDay: "EndDate",
};

const dataHeaders = [
  ["ДАТА", "РОБІТНИК", "КІЛЬКІСТЬ", "СУМА", "ЦІНА", "КЛІЄНТ"],
];

class ReportByMaterial extends GeneralPage {
  class = "report-by-materials";
  columns = columns.general;
  componentDidMount = () => false;

  state = {
    name: undefined,
  };

  componentDidMount = () => {
    const {
      getData,
      history,
      location: { search },
      getMaterials,
    } = this.props;
    const query = queryString.parse(search);
    this.setState(
      (prevState) => ({
        ...prevState,
        ...query,
      }),
      () => {
        getData({ pageNumber: query.page, ...this.state, history});
        getMaterials();
      }
    );
  };

  componentDidUpdate = () => {
    if (this.props.location.search.includes("page")) {
      let searchParams = new URLSearchParams(this.props.location.search);
      this.props.setPageNumber(+searchParams.get("page"));
    }
  };

  handleShowMaterialName = () => {
    const { allMaterial, choseData } = this.props;
    const currentMaterial =
      allMaterial && allMaterial.find((item) => item.id === choseData.EntityId);
    return currentMaterial ? currentMaterial.name : null;
  };

  additionalComponentsTops = () => (
    <div className="report-by-materials__top">
      <p>
        <span>{texts.reportByMaterial}</span> {this.handleShowMaterialName()}
      </p>
    </div>
  );

  handleDataExportPDF = async () => {
    const filters = this.props.location.search + '&isMaterial=true'
    console.log(this.props)
    try {
      const {data} = await fetchAllReportForPdf(filters,'gridbyproduct')
      const finalData = data.items.map((elt) => {
        return [
          moment(elt.date).format("DD.MM.YYYY HH:mm"),
          elt.employeeFullName,
          elt.amount,
          `${elt.cost} грн`,
          `${elt.price} грн`,
          elt.clientFullName,
        ];
      });
      exportPDF({
        tableData: finalData,
        header: dataHeaders,
        format: "landscape",
        contentBefore: `${this.showContentBeforeTablePDF()}`,
      });
    } catch (e) {

    }

  };

  showContentBeforeTablePDF = () => {
    const { location } = this.props;
    return location.search ? this.showFilterPDF(location.search.slice(1)) : "";
  };

  showFilterPDF = (query) => {
    const { totalAmount, totalCost } = this.props.details;
    const chosenFilters = {};
    const arrayWithFilters = query.split("&");
    arrayWithFilters.forEach((el, indx) => {
      switch (arrayWithFilters[indx].split("=")[0]) {
        case constants.materialEntityId:
          chosenFilters[texts.reportByMaterial] = this.handleShowMaterialName();
          break;
        case constants.optionFirstDay:
          const dateStart = arrayWithFilters[indx].split("=")[1];
          const dateEnd = arrayWithFilters
            .find((el) => el.startsWith("EndDate"))
            .split("=")[1];
          if (dateStart === dateEnd)
            chosenFilters["Період"] = moment(dateStart).format("DD.MM.YYYY");
          else
            chosenFilters["Період"] = `${moment(dateStart).format(
              "DD.MM.YYYY"
            )} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
          break;
        default:
          return chosenFilters;
      }
    });
    chosenFilters[texts.totalAmount] = totalAmount;
    chosenFilters[texts.totalCost] = `${totalCost} грн`;
    return Object.entries(chosenFilters)
      .map((x) => x.join(" : "))
      .join("\r\n");
  };

  additionalComponents = () => (
    <div className="report-by-materials__bottom">
      <div className="report-by-materials__details">
        <div className="report-by-materials__details_item">
          <p>
            <span>{texts.totalAmount}</span> {this.props.details.totalAmount}
          </p>
        </div>
        <div className="report-by-materials__details_item">
          <p>
            <span>{texts.totalCost}</span> {this.props.details.totalCost} грн.
          </p>
        </div>
      </div>
      <div className="default-btn">
        <button onClick={() => this.handleDataExportPDF(this.props.data)}>
          {texts.btn}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ reports, pagination, materials }) => ({
  data: reports.materialData,
  choseData: reports.choseMaterialData,
  details: reports.detailsMaterial,
  allMaterial: materials.allData,
  ...pagination,
});

const mapDispatchToProps = {
  setPageNumber,
  downloadReportPdf,
  getData: getReportByProduct,
  getMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportByMaterial);
