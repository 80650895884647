import SelectReact from "..";
import { connect } from "react-redux";
import { getMaterials } from "../../../../redux/materials";
import { generateSelectValue } from "../../../../helpers";

class AllMaterialSelect extends SelectReact {
  componentDidMount = () => this.props.getMaterials();
  label = "";
  name = "materialId";
  maxHeight = 250;

  getInitialValue = (value) => {
    const currentOption =
      this.props.data &&
      this.props.data.find((item) => item.id === this.props.value);
    return currentOption
      ? {
          value: currentOption.id,
          label: generateSelectValue({ value: currentOption.name }),
        }
      : null;
  };

  renderOptions = () =>
    this.props.data.map(({ id, name }) => ({
      value: id,
      label: generateSelectValue({
        value: name,
        size: this.props.selectValueSize,
      }),
    }));
}

const mapStateToProps = (state) => ({
  data: state.materials.allData,
});

const mapDispatchToProps = {
  getMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllMaterialSelect);
