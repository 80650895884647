import React from 'react';
import { NavLink } from 'react-router-dom';

const SidebarList = ({ list, role }) => {
	return (
		list(role).map((item, index) => (
			<NavLink
				className="sidebar__btn"
				activeClassName="sidebar__btn--active"
				to={`/${item.path}`}
				key={index}
			>
				{item.title}
			</NavLink>
		))
	)
};

export default SidebarList;