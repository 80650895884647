import React from "react";
import columns from "./columns";
import GeneralPage from "../GeneralPage";
import { connect } from "react-redux";
import AddServiceForm from "./AddServiceForm";
import {
  addProductToOrder,
  closeModal,
  getDataForCreateOrder,
  deleteProductFromOrder,
  changeOrderData,
} from "../../redux/createOrder";
import PaymentForm from "./PaymentForm";
import Modal from "../../components/Modal";
import AddMaterialForm from "./AddMaterialForm";
import SuccessIcon from "../../assets/images/icons/ok-icon.png";
import { setPageNumber } from "../../redux/pagination";

const texts = {
  successMessage: "Успішна оплата!",
  modalQuestion: "Друкувати чек",
  modalBtn: "Друк",
};

class CreateOrder extends GeneralPage {
  class = "create-order";
  columns = columns.general;
  createNew = false;
  dateRangeFilter = false;

  additionalComponents = () => {
    const { visible, closeModal } = this.props;
    return (
      <>
        <AddServiceForm />
        <AddMaterialForm />
        <PaymentForm />

        {visible && (
          <Modal
            visible={visible}
            onCancel={closeModal}
            footer={null}
            className="create-order-modal"
            width={300}
          >
            <div className="create-order-modal__content">
              <h3>{texts.successMessage}</h3>
              <img src={SuccessIcon} alt="success-icon" />
              <p>{texts.modalQuestion}</p>
              <a href="/create-order">
                <span>{texts.modalBtn}</span>
              </a>
            </div>
          </Modal>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  data: state.createOrder.data,
  visible: state.createOrder.visible,
  loading: state.createOrder.loading,
  orderData: state.createOrder.orderData,
  dataForCreateOrder: state.createOrder.dataForCreateOrder,
});

const mapDispatchToProps = {
  getData: getDataForCreateOrder,
  addProductToOrder,
  closeModal,
  deleteItem: deleteProductFromOrder,
  handleChange: changeOrderData,
  setPageNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
