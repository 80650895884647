import React, {Component} from "react";
import Select, { components } from "react-select";
import { FixedSizeList as List } from "react-window";
import { ChevronIcon, ClearIcon } from "../../../../icons";


const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <ChevronIcon />
    </components.DropdownIndicator>
);
const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator  {...props} >
            <ClearIcon />
        </components.ClearIndicator  >
    );
}

const MenuList = ({
  options,
  children,
  getValue,
  selectProps: { optionHeight: height, listHeight: maxHeight },
}) => {
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    return (
        <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => {
                return <div style={{ ...style }}>{children[index]}</div>;
            }}
        </List>
    );
};

class ReactSelectForPaydesks extends Component {
    height = 20;
    maxHeight = 300;

    getInitialValue = () => null;

    renderOptions = () => [];

    render() {
        const {
            input = [],
            meta = [],
            label = "",
            placeholder = "Виберіть",
            required,
            ...props
        } = this.props;

        const requiredMark =
            required && label ? (
                <span className="c-form__field--required">*</span>
            ) : (
                ""
            );
        const val = input.value.label
            ? input.value
            : this.getInitialValue(input.value);

        return (
            <span className={`select-content ${this.spanClassName}`}>
        <label>
          {label}
            {requiredMark}
        </label>
        <div className="select">
          <Select
              // menuIsOpen={true}
              className={`select filled`}
              classNamePrefix="select"
              options={this.renderOptions()}
              {...input}
              {...props}
              isClearable={true}
              placeholder={placeholder}
              value={val}
              optionHeight={this.height}
              listHeight={this.maxHeight}
              components={{
                  DropdownIndicator,
                  ClearIndicator,
                  MenuList,
              }}
              styles={{
                  control: (base) => ({
                      border: "none",
                      outline: "none",
                  }),
              }}
          />
          <div className="ant-form-explain">
            {meta.submitFailed ? meta.error : ""}
          </div>
        </div>
      </span>
        );
    }
}

export default ReactSelectForPaydesks;
