import React from "react";
import { InfoIcon } from "../../icons";

export const detailsFormatter = ({ id, notes, openNotes }) => {
  return (
    <div
      style={notes ? { cursor: "pointer" } : { display: "none" }}
      onClick={() => {
        notes && openNotes(id, notes);
      }}
    >
      <InfoIcon />
    </div>
  );
};
