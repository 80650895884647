import http from "../index";
import { generateSortParams } from "../../helpers";
import { tablePageSize } from "../../helpers/constants";

export function fetchForCreateOrder() {
  return http
    .get("/Orders")
    .then((response) => response)
    .catch((error) => error.response);
}

export function addNewOrder(data) {
  return http
    .post("/Orders", data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function fetchOrders({
  orderNumber = "",
  materialId = "",
  parameter = "",
  clientId = "",
  employeeId = "",
  orderCreationEndDate = "",
  sort = "",
  orderCreationStartDate = "",
  status = "",
  pageSize = tablePageSize,
  pageNumber = 1,
}) {
  const orderNumberQuery = orderNumber && `&OrderNumber=${orderNumber}`;
  const materialIdQuery = materialId && `&materialId=${materialId}`;
  const parameterQuery = parameter && `&parameter=${parameter}`;
  const clientIdQuery = clientId && `&ClientId=${clientId}`;
  const employeeIdQuery = employeeId && `&EmployeeId=${employeeId}`;
  const orderCreationEndDateQuery =
    orderCreationEndDate && `&orderCreationEndDate=${orderCreationEndDate}`;
  const orderCreationStartDateQuery =
    orderCreationStartDate &&
    `&orderCreationStartDate=${orderCreationStartDate}`;
  const statusQuery = status && `&status=${status}`;
  const pageNumberQuery = pageNumber && `&pageNumber=${pageNumber}`;
  const pageSizeQuery = pageSize && `&PageSize=${pageSize}`;
  const queries = [
    orderNumberQuery,
    materialIdQuery,
    parameterQuery,
    employeeIdQuery,
    orderCreationEndDateQuery,
    orderCreationStartDateQuery,
    clientIdQuery,
    statusQuery,
    pageNumberQuery,
    pageSizeQuery,
  ];
  const url = queries.filter((query) => query).join("");

  const sortUrl = generateSortParams(sort);

  return http
    .get(`/OrdersHistory/grid?${url}${sortUrl}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function getOrderById(id) {
  return http
    .get(`/OrdersHistory/details/${id}`)
    .then((response) => response)
    .catch((error) => error.response);
}

export function addPayment(data) {
  return http
    .put(`/OrdersHistory/addBalance`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function editOrderSingleEmployee(data) {
  return http
    .put(`/OrdersHistory/changeEmployee`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function deleteOrderById({
  orderId,
  cashierId,
  paydeskId,
  cashBackType,
  materials,
}) {
  const materialsUrl = [];
  if (materials) {
    for (const property in materials) {
      if (materials[property]) {
        materialsUrl.push(`&Materials[${property}]=${materials[property]}`);
      }
    }
  }
  return http
    .delete(
      `/OrdersHistory?OrderId=${orderId}&CashierId=${cashierId}&PaydeskId=${paydeskId}&CashBackType=${cashBackType}${materialsUrl
        .filter((query) => query)
        .join("")}`
    )
    .then((response) => response)
    .catch((error) => error.response);
}
