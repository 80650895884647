import React, { Component } from 'react';
import { Select as SelectAntd } from 'antd';
import { generateSelectValue } from '../../../../helpers';

class Select extends Component {

  renderOptions = () => false

  render() {
    const { input = [], meta = [], label = "", spanClassName="", placeholder = "Виберіть", required, ...props } = this.props;
    const value = input.value.toString().length !== 0 ?  generateSelectValue(input.value) : undefined;
    // const className = getInputClass({ meta, value: input.value })
    return (
      <span className={spanClassName}>
        <label>{label}</label>
        <SelectAntd
          {...input}
          {...props}
          value={value}
          placeholder={placeholder}
        >
          {this.renderOptions(this.options)}
        </SelectAntd>
        <div className="ant-form-explain">
          {meta.touched ? meta.error : ''}
        </div>
      </span>
    )
  }
}

export default Select;
