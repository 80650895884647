import SelectReact from "..";
import { connect } from "react-redux";
import { getClients } from "../../../../redux/clients";
import { generateSelectValue } from "../../../../helpers";

class ClientsSelect extends SelectReact {
  componentDidMount = () => {
    this.props.getData();
  };

  label = "";
  name = "clientId";
  maxHeight = 250;
  width = 250;
  className = "clients-select";

  getInitialValue = (value) => {
    const currentOption =
      this.props.data &&
      this.props.data.find((item) => item.id === this.props.value);
    return currentOption
      ? {
          value: currentOption.id,
          label: generateSelectValue({
            value: currentOption.fullName,
            size: this.props.selectValueSize,
          }),
        }
      : null;
  };

  renderOptions = () => {
    const sortedClients = this.props.data.sort((a, b) =>
      a.fullName.localeCompare(b.fullName)
    );
    return sortedClients.map(({ id, fullName }) => ({
      value: id,
      label: generateSelectValue({
        value: fullName,
        size: this.props.selectValueSize,
      }),
    }));
  };
}

const mapStateToProps = (state) => ({
  data: state.clients.allData,
});

const mapDispatchToProps = {
  getData: getClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsSelect);
