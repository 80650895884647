import SelectReact from '../index';
import { connect } from 'react-redux';

import { getPaymentMethods } from '../../../../redux/clients';
import { setCurrentEntrepreneur } from '../../../../redux/createOrder'
import { generateSelectValue } from '../../../../helpers';

class EntrepreneursForOrder extends SelectReact {

  label = ''
  name = 'entrepreneurs'
  maxHeight = 250;
  className='entrepreneurs-clients-select'
  placeholder = 'Обрати ФОП'

  componentDidMount = () => !this.props.data.length && this.props.getPaymentMethods()

  handleChange = values => {
    const { setCurrentEntrepreneur } = this.props;
    if(!values) {
      setCurrentEntrepreneur('');
      return false;
    }
    setCurrentEntrepreneur(values.value)
  }

  getInitialValue = value =>  {
    const currentOption = this.props.data &&  this.props.data.find(item => item.id === this.props.value)
    if(!currentOption && this.props.data && this.props.data.length){
      this.props.setCurrentEntrepreneur(this.props.data[0].id)
      return {value: this.props.data[0].id, label: this.props.data[0].name} 
    } else if (currentOption) {
      return {value: currentOption.id, label: generateSelectValue({ value: currentOption.name})}
    }
  }

  renderOptions = () => this.props.data.map(({ id, name}) => ({ value: id, label: generateSelectValue({ value: name }) }));

}

const mapStateToProps = state => ({
  data: state.createOrder.orderData.paydesks,
});

const mapDispatchToProps = {
  setCurrentEntrepreneur,
  getPaymentMethods
};

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneursForOrder);