import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { roleNames } from '../../roles';
import Sidebar from '../../components/Sidebar';
import pages from '../../content/pages';

const texts = {
  start_work: 'Почати роботу',
  title: 'Каса'
}

const MainPage = ({userRole}) => {
  return (
    <div className="main-container">
      {userRole !== roleNames.accountant  && <div className="main__content">
        <h3 className="main__content_title">{texts.title}</h3>
        <NavLink className="main__content_btn" to={`/create-order`}>
          {texts.start_work}
        </NavLink>
      </div> }
      <Sidebar list={pages} />
    </div>
  ) 
}
  
const mapStateToProps = state => ({
  userRole: state.login.user.role
})

export default connect(mapStateToProps)(MainPage)