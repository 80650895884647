import http from '../index';

export function fetchAllCashier() {
    return http.get(`/Users/allCashiers`)
        .then(response => response)
        .catch(error => error.response);
};

export function fetchAllAccountantAndAdmins() {
    return http.get(`/Users/allAccountantsAndAdmins`)
        .then(response => response)
        .catch(error => error.response);
};