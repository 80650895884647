import React, { useEffect, useRef, useState } from "react";
import { InputNumber } from "antd";

const constants = {
  minPriceNumber: 0.01,
  minAmountNumber: 1,
  minTotalNumber: 0.01,
};

function OrderFormatter(
  id,
  value,
  name,
  data,
  orderData,
  currentProduct,
  currentProductId,
  dataForCreateOrder,
  handleChange,
  current
) {
  const searchInput = useRef(null);

  const [inputValue, setInputValue] = useState(value);
  const [minValue, setMinValue] = useState(null);

  useEffect(() => {
    handleSetMinValue(name);
    current && searchInput.current.focus();
  }, [name, current]);

  const handleSetMinValue = (name) => {
    if (name === "amount") {
      setMinValue(constants.minAmountNumber);
    } else {
      setMinValue(constants.minPriceNumber);
    }
  };

  const onChange = (e) => {
    let currentProductPrice;

    if (!e || e === null || e === undefined) {
      if (name === "amount") {
        return setInputValue(constants.minAmountNumber);
      } else {
        return setInputValue(constants.minPriceNumber);
      }
    }
    if (currentProduct === "service") {
      const currentProductItem = orderData.services.find(
        (item) => item.id === currentProductId
      );
      currentProductPrice = currentProductItem.price;
    } else if (currentProduct === "material") {
      const currentProductItem = orderData.materials.find(
        (item) => item.id === currentProductId
      );
      currentProductPrice = currentProductItem.outputPrice;
    }
    handleChange({
      id,
      name,
      value: e,
      data,
      currentProductPrice,
      dataForCreateOrder,
      currentProduct,
    });
  };

  return (
    <div className="create-order__formatter">
      <InputNumber
        min={minValue}
        value={inputValue}
        ref={searchInput}
        onChange={onChange}
      />
    </div>
  );
}

export default OrderFormatter;
