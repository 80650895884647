import SelectReact from '..';

class TransactionSelect extends SelectReact {

  // handleChange = () => null

  label = ''
  name = 'Transaction'
  maxHeight = 120;
  className='transaction'

  options = [
    {
      id: 1,
      value: 'Order',
      name: 'Замовлення'
    },
    {
      id: 2,
      value: 'CashBack',
      name: 'Повернення'
    },
    {
      id: 3,
      value: 'Recharge',
      name: 'Поповнення'
    }
  ]

  getInitialValue = value =>  {
    const currentOption = this.options &&  this.options.find(item => item.value === this.props.value)
    return currentOption ? {value: currentOption.value, label: currentOption.name} : null
  }

  renderOptions = () => this.options.map(({ value, name }) => ({ value: value, label: name }));

}

export default TransactionSelect;
