import SelectReact from '../index';
import { connect } from 'react-redux';
import {
  initialPaymentValues,
  addPaymentMethod,
} from "../../../../../redux/createOrder";
import { generateSelectValue } from '../../../../../helpers';

class PaymentMethodsReactSelect extends SelectReact {

  label = ''
  name = 'paymentMethodId'
  maxHeight = 150
  spanClassName = 'payment-methods-select'

  getInitialValue = value =>  {
    const {currentClient, data, initialPaymentValues, addPaymentMethod, initialValue, currentPayment} = this.props
    let currentOption;
    if(!currentClient.isDefault) {
      currentOption = currentPayment ? currentPayment : data[0]
      if(!initialValue.paymentMethodId && currentOption && currentOption.id ){
        initialPaymentValues({ paymentMethodId: currentOption.id })
      } else if (value !== initialValue.paymentMethodId && currentPayment && (currentPayment.name === "Баланс" || currentPayment.name === "Термінал" )) {
        initialPaymentValues({ paymentMethodId: currentPayment.id })
      }
      addPaymentMethod(currentOption);
    } else {
      currentOption = data && data.find(item => item.id === value)
      addPaymentMethod(currentOption);
    }
    return currentOption ? {value: currentOption.id, label: currentOption.name} : null
  }

  renderOptions = () => {
    const { currentClient, data } = this.props;
    if(currentClient && currentClient.isDefault ) {
      return data.map(option => (option.name === "Готівка" || option.name === "Термінал") && ({ value: option.id, label: generateSelectValue({ value: option.name })}))
    } else {
      return data.map(({id, name}) => ({ value: id, label: generateSelectValue({value: name}) }))
    }
  }
}

const mapStateToProps = state => ({
  data: state.createOrder.orderData.paymentMethods,
  currentClient: state.clients.currentClient,
  initialValue: state.createOrder.initialValues.payment,
  currentPayment: state.createOrder.payment
});

const mapDispatchToProps = {
  initialPaymentValues,
  addPaymentMethod
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsReactSelect);
